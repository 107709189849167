import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/elements/Button';
import Grid from '@mui/material/Grid';
import Input from '../../components/elements/Input';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { addClientsAction, editClientsAction, getByIdClientsAction } from '../../redux/actions/clientsAction';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_CLIENTS, EDIT_CLIENTS, GET_BY_ID_CLIENTS } from '../../redux/type';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import countryData from '../../countriesStatesCities.json';

const ClientsManagement = () => {

    const currencysOptions = [
        { label: 'USD to USD', value: 'USD to USD' },
        { label: 'USD to INR', value: 'USD to INR' },
        { label: 'INR to INR', value: 'INR to INR' },
        { label: 'INR', value: 'INR' },
    ]

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const countryRef = useRef()
    const params = useParams()
    const [clientType, setClientType] = useState("EXPORT")
    const [type, setType] = useState("")
    const [department, setdepartment] = useState("")
    const [countryOptions, setCountryOptions] = useState<any[]>([])
    // const [stateOptions, setStateOptions] = useState<any[]>([])
    // const [inputValue, setInputValue] = useState('');
    const [country, setCountry] = useState<any>(null)
    // const [state, setState] = useState<any>(null)
    const [currency, setCurrency] = useState<any>(null)
    const getByIdClientsData = useSelector((state: any) => state.getByIdClients.getByIdClients)
    const addClientsData = useSelector((state: any) => state.addClients.addClients)
    const editClientsData = useSelector((state: any) => state.editClients.editClients)

    useEffect(() => {
        return (() => {
            dispatch({
                type: GET_BY_ID_CLIENTS,
                payload: null,
            });
        })
    }, [])



    const clientSchema = yup.object().shape({
        companyName: yup.string(),
        clientName: yup.string().required('Please enter clientName'),
        GST: yup.string(),
        PAN: yup.string(),
        address: yup.string(),
        address2: yup.string(),
        address3: yup.string(),
        SAC: yup.string(),
        // city: yup.string(),
        // state: yup.string(),
        country: yup.string(),
        // pincode: yup.string(),
        service: yup.string(),
        type: yup.string(),
        clientType: yup.string(),
        department: yup.string(),
        placeOfSupply: yup.string(),
        currency: yup.string(),
    });

    useEffect(() => {
        let options = []
        for (let i = 0; i < countryData.length; i++) {
            const element = countryData[i];
            options.push({ label: element.country, value: element.country })
        }
        setCountryOptions(options)
    }, [])

    const { register, handleSubmit, formState: { errors }, reset, setValue, setError, control } = useForm({
        resolver: yupResolver(clientSchema),
    });

    useEffect(() => {
        if (addClientsData) {
            if (addClientsData?.status === 200) {
                toast.success(addClientsData?.message)
                navigate('/clients')
            } else {
                toast.error(addClientsData?.message)
            }
        }
        dispatch({
            type: ADD_CLIENTS,
            payload: '',
        });
    }, [addClientsData])

    useEffect(() => {
        if (editClientsData) {
            if (editClientsData?.status === 200) {
                toast.success(editClientsData?.message)
                navigate('/clients')
            } else {
                toast.error(editClientsData?.message)
            }
        }
        dispatch({
            type: EDIT_CLIENTS,
            payload: '',
        });
    }, [editClientsData])

    const onAdd = (data: any) => {
        dispatch(addClientsAction(data))
    }

    const onEdit = (data: any) => {
        if (data.GST) {
            // console.log('e.target.value.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)', /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(data.GST))
            if (!(data.GST.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/))) {
                setError("GST", { message: "please enter valid GST number", type: "onChange" });
                return;
            }
        }
        dispatch(editClientsAction(params.id, data))
    }

    useEffect(() => {
        if (params.id) {
            dispatch(getByIdClientsAction(params.id))
        }
    }, [params.id])

    useEffect(() => {
        // console.log('getByIdClientsData', getByIdClientsData)
        if (getByIdClientsData && getByIdClientsData.status === 200) {
            setType(getByIdClientsData?.data?.type ?? '')
            setClientType(getByIdClientsData?.data?.clientType ?? '')
            setCountry(getByIdClientsData?.data?.country ?? '')
            // setState(getByIdClientsData?.data?.state)
            setCurrency(getByIdClientsData?.data?.currency ?? '')
            setdepartment(getByIdClientsData?.data?.department ?? '')
            reset({
                companyName: getByIdClientsData?.data?.companyName ?? '',
                clientName: getByIdClientsData?.data?.clientName ?? '',
                GST: getByIdClientsData?.data?.GST ?? '',
                PAN: getByIdClientsData?.data?.PAN ?? '',
                address: getByIdClientsData?.data?.address ?? '',
                address2: getByIdClientsData?.data?.address2 ?? '',
                address3: getByIdClientsData?.data?.address3 ?? '',
                SAC: getByIdClientsData?.data?.SAC ?? '',
                // city: getByIdClientsData?.data?.city,
                // state: getByIdClientsData?.data?.state,
                country: getByIdClientsData?.data?.country ?? '',
                // pincode: getByIdClientsData?.data?.pincode,
                type: getByIdClientsData?.data?.type ?? '',
                clientType: getByIdClientsData?.data?.clientType ?? '',
                department: getByIdClientsData?.data?.department ?? '',
                service: getByIdClientsData?.data?.service ?? '',
                placeOfSupply: getByIdClientsData?.data?.placeOfSupply ?? '',
                currency: getByIdClientsData.data.currency ?? '',
            })
        }
    }, [getByIdClientsData])


    return (
        <div className=''>
            <div className='card_header'>
                <h1 className='card_header_title'>{params.id ? 'Edit' : 'Add'} Client</h1>
                <div className='card_footer'>
                    <Button
                        variant="outlined"
                        buttonClass='contained_button_cancel'
                        onClick={() => navigate('/clients')}
                        child='Cancel' />
                    <Button
                        onClick={handleSubmit((data) => {
                            // console.log('data', data)
                            {
                                params.id ?
                                    onEdit(data)
                                    :
                                    onAdd(data)
                            }
                        })}
                        variant="outlined"
                        buttonClass='contained_button_add'
                        child={`${params.id ? 'Save' : 'Add'}`} />
                </div>
            </div>
            <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Company name'
                        placeholder="Name"
                        type='text'
                        register={register("companyName")}
                        error={errors?.companyName?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Client name'
                        placeholder="Name"
                        type='text'
                        register={register("clientName")}
                        error={errors?.clientName?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        Client type
                    </InputLabel>
                    <FormControl sx={{ minWidth: '100%' }}>
                        <Select
                            size="small"
                            value={clientType || ''}
                            {...register('clientType')}
                            onChange={(e) => {
                                // console.log('e.target.value', e.target.value)
                                setClientType(e.target.value);
                                setValue("clientType", e.target.value);
                                setCurrency(e.target.value === "EXPORT" ? "USD to USD" : "INR");
                                setValue("currency", e.target.value === "EXPORT" ? "USD to USD" : "INR")
                            }}
                            displayEmpty
                        >
                            {/* <MenuItem value="">
                                <em>None</em>
                            </MenuItem> */}
                            <MenuItem value={'EXPORT'}>Export</MenuItem>
                            <MenuItem value={'GST'}>GST</MenuItem>
                        </Select>
                    </FormControl>
                    <span className='input_error' >{errors?.clientType?.message?.toString()}</span>
                </Grid>
                {(clientType === "GST") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        Select type
                    </InputLabel>
                    <FormControl sx={{ minWidth: '100%' }}>
                        <Select
                            size="small"
                            value={type || ""}
                            {...register('type')}
                            onChange={(e) => setType(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'CENTRAL'}>Central</MenuItem>
                            <MenuItem value={'STATE'}>State</MenuItem>
                        </Select>
                    </FormControl>
                    <span className='input_error' >{errors?.type?.message?.toString()}</span>
                </Grid>}
                {(clientType === "GST") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='GST'
                        placeholder="GST"
                        type='text'
                        register={register("GST")}
                        error={errors?.GST?.message}
                    />
                </Grid>}
                {(clientType === "GST") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='PAN'
                        placeholder="PAN"
                        type='text'
                        register={register("PAN")}
                        error={errors?.PAN?.message}
                    />
                </Grid>}
                {(clientType === "GST" || clientType === "EXPORT") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Address line 1'
                        placeholder="Address line 1"
                        register={register("address")}
                        error={errors?.address?.message}
                    />
                </Grid>}
                {(clientType === "GST" || clientType === "EXPORT") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Address line 2'
                        placeholder="Address line 2"
                        register={register("address2")}
                        error={errors?.address?.message}
                    />
                </Grid>}
                {(clientType === "GST" || clientType === "EXPORT") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Address line 3'
                        placeholder="City, State, Pincode"
                        register={register("address3")}
                        error={errors?.address?.message}
                    />
                </Grid>}
                {(clientType === "GST" || clientType === "EXPORT") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        Country
                    </InputLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={countryOptions}
                        value={country}
                        {...register("country")}
                        onChange={(event: any, newValue: any) => {
                            setCountry(newValue?.value);
                            setValue("country", newValue?.value)
                        }}
                        style={{ padding: 0 }}
                        renderInput={(params) => <TextField
                            {...params}
                            placeholder="Country" />}
                    />
                    {errors?.country?.message && <span className='input_error' >{errors?.country?.message as any}</span>}
                </Grid>}
                {/* {(clientType === "GST") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        State
                    </InputLabel>
                    <Autocomplete
                        disablePortal
                        value={state}
                        id="combo-box-demo"
                        options={stateOptions}
                        {...register("state")}
                        onChange={(event: any, newValue: any) => {
                            setState(newValue?.value)
                            setValue("state", newValue?.value)
                        }}
                        style={{ padding: 0 }}
                        renderInput={(params) => <TextField
                            {...params}
                            placeholder="State" />}
                    />

                    {errors?.state?.message && <span className='input_error' >{errors?.state?.message as any}</span>}
                </Grid>}
                {(clientType === "GST") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='City'
                        placeholder="City"
                        register={register("city")}
                        error={errors?.city?.message}
                    />
                </Grid>}
                {(clientType === "GST") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Pin'
                        placeholder="Pin"
                        register={register("pincode")}
                        error={errors?.pincode?.message}
                    />
                </Grid>} */}

                {(clientType === "GST") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Place of supply'
                        placeholder="Place of supply"
                        register={register("placeOfSupply")}
                        error={errors?.placeOfSupply?.message}
                    />
                </Grid>}
                {(clientType === "GST" || clientType === "EXPORT") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        Department
                    </InputLabel>
                    <FormControl sx={{ minWidth: '100%' }}>
                        <Select
                            size="small"
                            value={department || ""}
                            {...register('department')}
                            onChange={(e) => setdepartment(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'WEB'}>WEB</MenuItem>
                            <MenuItem value={'ANDROID'}>ANDROID</MenuItem>
                        </Select>
                    </FormControl>
                    <span className='input_error' >{errors?.department?.message?.toString()}</span>
                </Grid>}
                {(clientType === "GST" || clientType === "EXPORT") &&
                    <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                        <Input
                            label='SAC'
                            placeholder="SAC"
                            register={register("SAC")}
                            error={errors?.SAC?.message}
                        />
                    </Grid>
                }
                {(clientType === "GST" || clientType === "EXPORT") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Service'
                        placeholder="Service"
                        register={register("service")}
                        error={errors?.service?.message}
                    />
                </Grid>}
                {(clientType === "GST" || clientType === "EXPORT") && <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        Currency
                    </InputLabel>
                    <FormControl sx={{ minWidth: '100%' }}>
                        <Autocomplete
                            // disabled={clientType === "GST"}
                            disablePortal
                            id="combo-box-demo"
                            options={currencysOptions}
                            value={currency}
                            {...register("currency")}
                            onChange={(event: any, newValue: any) => {
                                setCurrency(newValue?.value)
                                setValue("currency", newValue?.value)
                            }}
                            style={{ padding: 0 }}
                            renderInput={(params) => <TextField
                                {...params}
                                placeholder="select currency" />}
                        />
                    </FormControl>
                    <span className='input_error' >{errors?.currency?.message?.toString()}</span>
                </Grid>}

            </Grid>
        </div>
    )

}

export default ClientsManagement