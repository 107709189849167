import React from 'react'
import { Button as MUIButton } from '@mui/material';


interface ButtonProps {
    child: string;
    variant?: 'text' | 'outlined' | 'contained';
    onClick?: () => void;
    buttonClass: string;
    startIcon?: any,
    type?: any,
}

const Button = ({ variant = 'text', child, onClick, buttonClass, startIcon, type }: ButtonProps) => {
    return (
        <>
            <MUIButton
                type={type}
                variant={variant}
                onClick={onClick}
                className={buttonClass}
                startIcon={startIcon && <span className="material-symbols-rounded">{startIcon}</span>}
            >{child}</MUIButton>
        </>
    )
}

export default Button