import React, { useEffect, useState } from "react";
import Button from "../../components/elements/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { getByMonthExpensesAction } from "../../redux/actions/expensesAction";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import NoDataElement from "../../components/elements/NoDataElement";

const ExpensesByMonth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [byMonthExpensesData, setByMonthExpensesData] = useState([]);
  const getByMonthExpensesData = useSelector(
    (state: any) => state?.getByMonthExpenses?.getByMonthExpenses
  );
  const [expanded, setExpanded] = React.useState<string | false>(false);

  useEffect(() => {
    dispatch(getByMonthExpensesAction());
  }, []);
  useEffect(() => {
    if (getByMonthExpensesData && getByMonthExpensesData.data) {
      setByMonthExpensesData(getByMonthExpensesData.data);
    }
  }, [getByMonthExpensesData]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="expenses_month_section">
      {Object.entries(byMonthExpensesData).length ? Object.entries(byMonthExpensesData).map(([key, ele]: any) => {
        return (
          <Accordion
            style={{ backgroundColor: "#f3e9f5", boxShadow: 'none' }}
            expanded={expanded === key}
            onChange={handleChange(key)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              style={{ borderBottom: '1px solid #6c48a3', marginTop: '7px' }}

            >
              <Typography className='month_name' sx={{ width: "33%", flexShrink: 0, fontSize: '19px !important' }}>
                {key?.replace("_", " ")} (
                {ele.reduce((sum: any, obj: any) => +sum + +obj.amount, 0)}){" "}
              </Typography>
            </AccordionSummary>
            {ele.map((item: any) => {
              return (
                <AccordionDetails style={{ padding: '0 16px 0px' }}>
                  <Typography>
                    <Grid item xs={12} lg={6} xl={6} md={6} sm={12} >
                      <div className="group_by_month_card" style={{ backgroundColor: 'transparent', borderBottom: '1px solid #a5a5a5', borderRadius: '0' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={3} xl={3} md={3} sm={12}>
                            <p className="description">{moment(item.date).format("DD/MM/YYYY")}</p>
                          </Grid>
                          <Grid item xs={12} lg={4} xl={4} md={4} sm={12}>
                            <Grid style={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 2,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              wordWrap: 'break-word',
                              wordBreak: 'break-word',
                              lineHeight: '13px'
                            }}>
                              <p className="description">{item.note}</p>
                            </Grid>
                            {/* {item?.note?.length > 50 ? (
                              <Tooltip title={item.note}>
                               
                              </Tooltip>
                            ) : (
                              <Grid style={{ width: '85%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                <p>{item.note}</p>
                              </Grid>
                            )} */}
                          </Grid>
                          <Grid item xs={12} lg={3} xl={3} md={3} sm={12}>
                            <p className="description">({item.paymentType})</p>
                          </Grid>
                          <Grid item xs={12} lg={2} xl={2} md={2} sm={12}>
                            <p className="description">₹{item.amount}</p>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              );
            })}
          </Accordion>
        );
      })
        :
        <NoDataElement />
      }
    </div>
  );
};

export default ExpensesByMonth;
