import React, { useEffect, useState } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import Firm from '../pages/firm';
import Layout from '../layout';
import FirmManagement from './firm/FirmManagement';
import Clients from './clients';
import ClientsManagement from './clients/ClientsManagement';
import Employees from './employees';
import EmployeesManagement from './employees/EmployeesManagement';
import { Provider, useDispatch, useSelector } from 'react-redux'
import store from '../redux/store';
import AuthStorage from '../helper/AuthStorage';
import Error from '../pages/error'
import { ApiGet } from '../helper/API/ApiData';
import { toast } from 'react-toastify';
import Invoice from './invoice';
import InvoiceManagement from './invoice/InvoiceManagement';
import Loader from '../components/elements/Loader';
import { IS_LOADING } from '../redux/type';
import Assets from './assets'
import Expenses from './expenses'
import AssetsManagement from './assets/AssetsManagement';
import ExpensesManagement from './expenses/ExpensesManagement';
import ExpensesByMonth from './expenses/ExpensesByMonth';
import AssetsByMonth from './assets/AssetsByMonth';

const Index = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const noAuthRoutes = ['/', '/login'];
  const pathname = window.location.pathname
  const [varified, setVarified] = useState(false)
  const loading = useSelector((state: any) => state.loading?.loading)


  useEffect(() => {
    if (!AuthStorage.getToken()) {
      navigate('/')
      setVarified(false)
    } else {
      dispatch({
        type: IS_LOADING,
        payload: true
      })
      ApiGet("user/varifyToken").then((res: any) => {
        // console.log('res', res)
        if (res.status === 200 && res.data) {
          setVarified(true)
          navigate((pathname === "/" || pathname == "") ? "/firm" : pathname)
          //   AuthStorage.deauthenticateUser()
          //   navigate('/')
        }
        // } else {
        //   setVarified(true)
        //   navigate((pathname === "/" || pathname == "") ? "/firm" : pathname)
        // }
        // if (!res.data) {
        //   // toast.error(res.message)
        //   navigate('/')
        // }
      }).catch(error => {
        console.log('error', error)
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
          setVarified(false)
          navigate("/");
        }
      })
      dispatch({
        type: IS_LOADING,
        payload: false
      })
    }
  }, [AuthStorage.getToken()])

  return (
    <>
      {
        varified ? (
          <>
            <Routes>
              <Route element={<Layout />}>
                {/* <Route path='/dashboard' element={<Dashboard />} /> */}
                <Route path='/firm' element={<Outlet />} >
                  <Route path='' element={<Firm />} />
                  <Route path='add' element={<FirmManagement />} />
                  <Route path='edit/:id' element={<FirmManagement />} />
                </Route>
                <Route path='/clients' element={<Outlet />} >
                  <Route path='' element={<Clients />} />
                  <Route path='add' element={<ClientsManagement />} />
                  <Route path='edit/:id' element={<ClientsManagement />} />
                </Route>
                <Route path='/employees' element={<Outlet />} >
                  <Route path='' element={<Employees />} />
                  <Route path='add' element={<EmployeesManagement />} />
                  <Route path='edit/:id' element={<EmployeesManagement />} />
                </Route>
                <Route path='/invoice' element={<Outlet />} >
                  <Route path='' element={<Invoice />} />
                  <Route path='add' element={<InvoiceManagement />} />
                  <Route path='edit/:id' element={<InvoiceManagement />} />
                </Route>
                <Route path='/assets' element={<Outlet />} >
                  <Route path='' element={<Assets />} />
                  <Route path='add' element={<AssetsManagement />} />
                  <Route path='edit/:id' element={<AssetsManagement />} />
                  <Route path='assets-by-month' element={<AssetsByMonth />} />
                </Route>
                <Route path='/expenses' element={<Outlet />} >
                  <Route path='' element={<Expenses />} />
                  <Route path='add' element={<ExpensesManagement />} />
                  <Route path='edit/:id' element={<ExpensesManagement />} />
                  <Route path='expenses-by-month' element={<ExpensesByMonth />} />
                </Route>
              </Route>
              <Route path='*' element={<Error />} />
            </Routes>
          </>
        )
          : (
            <Routes>
              <Route path='*' element={<Login />} />
            </Routes>
          )
      }
    </>
  )
}

export default Index