import React, { useEffect, useState } from "react";
import Button from "../../components/elements/Button";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Input from "../../components/elements/Input";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  addExpensesAction,
  editExpensesAction,
  getByIdExpensesAction,
} from "../../redux/actions/expensesAction";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { getAllFirmAction } from "../../redux/actions/firmAction";
import {
  ADD_EXPENSES,
  EDIT_EXPENSES,
  GET_BY_ID_EXPENSES,
} from "../../redux/type";
import dayjs, { Dayjs } from "dayjs";

const ExpensesManagement = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [dateOfBirth, setDateOfBirth] = useState<string>();

  const [selectedFirm, setSelectedFirm] = useState<any>(null);
  const [category, setCategory] = useState<string>();
  const [firms, setFirms] = useState<any[]>([]);
  const [banks, setBanks] = useState<any[]>([]);
  const [paymentType, setPaymentType] = useState<string>();
  const [selectedBank, setSelectedBank] = useState<any>(null);
  const [banksOptions, setBanksOptions] = useState<any[]>([]);
  const [date, setDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [fileData, setFileData] = useState<any>();
  const addExpensesData = useSelector(
    (state: any) => state.addExpenses.addExpenses
  );
  const editExpensesData = useSelector(
    (state: any) => state.editExpenses.editExpenses
  );
  const getFirmData = useSelector(
    (state: any) => state.getAllFirm.getAllFirm.data
  );
  const getByIdExpensesData = useSelector(
    (state: any) => state.getByIdExpenses.getBYIdExpenses
  );

  const expensesSchema = yup.object().shape({
    note: yup.string().required("Please enter note"),
    amount: yup.string(),
    date: yup.string(),
    paymentType: yup.string(),
    creditCard: yup.string(),
    bank: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(expensesSchema),
  });

  const onSelectDateOfBirth = (e: any) => {
    // let date = moment(new Date(e)).format("DD/MM/YYYY");
    setValue("date", dayjs(e).format('DD/MM/YYYY'));
    setDate(e);
  };

  useEffect(() => {
    if (addExpensesData?.status === 200) {
      toast.success(addExpensesData?.message);
      navigate("/expenses");
    } else {
      toast.error(addExpensesData?.message);
    }
    dispatch({
      type: ADD_EXPENSES,
      payload: null,
    });
  }, [addExpensesData]);

  useEffect(() => {
    if (editExpensesData?.status === 200) {
      toast.success(editExpensesData?.message);
      navigate("/expenses");
    } else {
      toast.error(editExpensesData?.message);
    }
    dispatch({
      type: EDIT_EXPENSES,
      payload: "",
    });
  }, [editExpensesData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: EDIT_EXPENSES,
        payload: "",
      });
      dispatch({
        type: ADD_EXPENSES,
        payload: "",
      });
      dispatch({
        type: GET_BY_ID_EXPENSES,
        payload: "",
      });
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(getByIdExpensesAction(params.id));
    }
  }, [params.id]);

  useEffect(() => {
    dispatch(getAllFirmAction());
  }, []);

  useEffect(() => {
    if (getByIdExpensesData && getByIdExpensesData.status === 200) {
      setPaymentType(getByIdExpensesData?.data?.paymentType ?? "");
      setSelectedBank(getByIdExpensesData?.data?.bank ?? "");
      setDate(dayjs(getByIdExpensesData?.data?.date));

      reset({
        date: moment(getByIdExpensesData?.data?.date).format("DD/MM/YYYY") ?? "",
        amount: getByIdExpensesData?.data?.amount ?? "",
        bank: getByIdExpensesData?.data?.bank ?? "",
        note: getByIdExpensesData?.data?.note ?? "",
        creditCard: getByIdExpensesData?.data?.creditCard ?? "",
        paymentType: getByIdExpensesData?.data?.paymentType ?? "",
      });
    }
  }, [getByIdExpensesData]);

  useEffect(() => {
    let banks: any[] = [];
    if (getFirmData?.length) {
      getFirmData.map((item: any) => {
        item.banks.map((bank: any) =>
          banks.push({
            label: `${bank?.bankName} - ${bank?.accountHolderName}`,
            value: `${bank?.bankName} - ${bank?.accountHolderName}`,
          })
        );
      });
    } else {
      banks = [];
    }
    setBanksOptions(banks);
  }, [getFirmData]);

  const onSave = (data: any) => {
    // console.log("data Add", data);

    dispatch(addExpensesAction({ ...data, date: dayjs(date).format('DD/MM/YYYY') }));
  };
  const onEdit = (data: any) => {
    // console.log("data Edit", data);
    dispatch(editExpensesAction(params.id, { ...data, date: dayjs(date).format('DD/MM/YYYY') }));
  };

  const findValue = (value: string, data: any, param: string) => {
    return data?.find((item: any) => item[param] === value);
  };
  // useEffect(() => {

  //   return () => {
  //     dispatch({
  //       type: GET_BY_ID_EXPENSES,
  //       payload: '',
  //     })
  //   }
  // }, [])

  return (
    <div className="">
      <div className="card_header">
        <h1 className="card_header_title">
          {params.id ? "Edit" : "Add"} expenses
        </h1>
        <div className="card_footer">
          <Button
            variant="outlined"
            buttonClass="contained_button_cancel"
            onClick={() => navigate("/expenses")}
            child="Cancel"
          />
          <Button
            onClick={handleSubmit((data) => {
              if (params.id) onEdit(data);
              else onSave(data);
            })}
            variant="outlined"
            buttonClass="contained_button_add"
            child={`${params.id ? "Save" : "Add"}`}
          />
        </div>
      </div>
      <Grid container spacing={2} style={{ marginBottom: "16px" }}>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Date
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register("date")}
              value={date}
              onChange={onSelectDateOfBirth}
              sx={{ width: "100%" }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Amount"
            placeholder="amount"
            type="number"
            register={register("amount")}
            error={errors?.amount?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Note"
            placeholder="note"
            type="text"
            register={register("note")}
            error={errors?.note?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Payment Type
          </InputLabel>
          <FormControl sx={{ minWidth: "100%" }}>
            <Select
              size="small"
              {...register("paymentType")}
              value={paymentType || ""}
              onChange={(e) => setPaymentType(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"bank"}>Bank</MenuItem>
              <MenuItem value={"cash"}>Cash</MenuItem>
              <MenuItem value={"cc"}>CC</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {paymentType === "bank" && (
          <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
            <InputLabel
              className="input_label"
              shrink
              htmlFor="bootstrap-input"
            >
              Select Bank
            </InputLabel>
            <FormControl sx={{ minWidth: "100%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={banksOptions}
                value={selectedBank}
                {...register("bank")}
                onChange={(event: any, newValue: any) => {
                  setSelectedBank(
                    findValue(newValue?.value, banksOptions, "value")
                  );
                  setValue("bank", newValue?.value);
                }}
                style={{ padding: 0 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select bank" />
                )}
              />
            </FormControl>
          </Grid>
        )}
        {paymentType === "cc" && (
          <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
            <Input
              label="Credit card"
              placeholder="Enter card number"
              type="number"
              register={register("creditCard")}
              error={errors?.note?.message}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ExpensesManagement;
