import React, { useEffect, useState } from 'react';
import Button from '../elements/Button';
import { FormControl, Grid, InputLabel, MenuItem, Select, Checkbox, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, FormControlLabel } from '@mui/material';
import Input from '../elements/Input';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Close from '../../assets/img/close.png'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const AddBank = ({ open, handleClose, setBankData, bankData }: any) => {

    const [isDefault, setIsDefault] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const id = params.id ?? localStorage.getItem("firmId")
    useEffect(() => {
        if (!id)
            navigate("/firm")
    }, [id])
    const AddBank = yup.object().shape({
        bankName: yup.string().required('Please enter bank name'),
        accountNumber: yup.string().required('Please enter account number'),
        accountHolderName: yup.string().required('Please enter account name'),
        nickName: yup.string().required('Please enter account nick name'),
        IFSCCode: yup.string().required('Please enter account IFSC code'),
        swift: yup.string().required('Please enter account swift code'),
        accountType: yup.string().required('Please select type'),
    });

    useEffect(() => {
        reset({
            bankName: bankData?.bankName,
            accountNumber: bankData?.accountNumber,
            accountHolderName: bankData?.accountHolderName,
            nickName: bankData?.nickName,
            IFSCCode: bankData?.IFSCCode,
            swift: bankData?.swift,
            accountType: bankData?.accountType
        })
        setIsDefault(bankData?.isDefault)
    }, [bankData])


    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(AddBank),
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='modal_header' >
                Add bank
                <img src={Close} alt='' onClick={handleClose} />
            </DialogTitle>
            <form>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                                <Input
                                    label='Bank name'
                                    placeholder="Bank name"
                                    type='text'
                                    register={register("bankName")}
                                    error={errors?.bankName?.message}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                                <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                                    A/C type
                                </InputLabel>
                                <FormControl sx={{ minWidth: '100%' }}>
                                    <Select
                                        size="small"
                                        defaultValue={bankData?.accountType}
                                        {...register('accountType')}
                                        displayEmpty
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'current'}>Current</MenuItem>
                                        <MenuItem value={'saving'}>Saving</MenuItem>
                                    </Select>
                                </FormControl>
                                <span className='input_error' >{errors?.accountType?.message?.toString()}</span>
                            </Grid>
                            <Grid item xs={12} lg={6} xl={6} md={6} sm={12} >
                                <Input
                                    label='A/C Holder Name'
                                    placeholder="A/C name"
                                    register={register("accountHolderName")}
                                    error={errors?.accountHolderName?.message}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={6} md={6} sm={12} >
                                <Input
                                    label='Nick name'
                                    placeholder="Nick name"
                                    register={register("nickName")}
                                    error={errors?.nickName?.message}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                                <Input
                                    label='A/C No'
                                    placeholder="A/C no"
                                    type='number'
                                    register={register("accountNumber")}
                                    error={errors?.accountNumber?.message}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={6} md={6} sm={12} >
                                <Input
                                    label='IFSC'
                                    placeholder="IFSC"
                                    register={register("IFSCCode")}
                                    error={errors?.IFSCCode?.message}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={6} md={6} sm={12} >
                                <Input
                                    label='Swift'
                                    placeholder="Swift"
                                    register={register("swift")}
                                    error={errors?.swift?.message}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12} xl={12} md={12} sm={12} >
                                <div className='defaultCheckbox' style={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControlLabel
                                        className='input_label' style={{ margin: '0px', cursor: 'pointer' }}
                                        control={
                                            <Checkbox
                                                size="small"
                                                id='checkbox'
                                                checked={isDefault}
                                                onChange={(e) => setIsDefault(e.target.checked)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                sx={{
                                                    color: '#948F94',
                                                    '&.Mui-checked': {
                                                        color: '#6750A4',
                                                    },
                                                }}
                                            />
                                        } label=" Set as default" />
                                    {/* <Checkbox
                                        size="small"
                                        id='checkbox'
                                        checked={isDefault}
                                        onChange={(e) => setIsDefault(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        sx={{
                                            color: '#948F94',
                                            '&.Mui-checked': {
                                                color: '#6750A4',
                                            },
                                        }}
                                    /> */}
                                    {/* <InputLabel className='input_label' style={{ marginTop: '10px', cursor: 'pointer' }} shrink htmlFor="checkbox">
                                        Set as default
                                    </InputLabel> */}
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        reset({
                            bankName: "",
                            accountNumber: "",
                            accountHolderName: "",
                            IFSCCode: "",
                            swift: "",
                            accountType: ""
                        })
                        handleClose()
                    }} child='Close' buttonClass='contained_button_cancel' />
                    <Button
                        type='submit'
                        onClick={handleSubmit((data) => {

                            setBankData({ ...data, isDefault: isDefault ?? false, _id: bankData._id })
                            reset({
                                bankName: "",
                                accountNumber: "",
                                accountHolderName: "",
                                IFSCCode: "",
                                swift: "",
                                accountType: ""
                            })
                            setIsDefault(false)
                            handleClose()
                        })}
                        child='Save'
                        buttonClass='contained_button_add'
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddBank