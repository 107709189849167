import { ADD_FIRM, ADD_FIRM_ASSETS, ADD_FIRM_ASSETS_ERROR, ADD_FIRM_ASSETS_LOADING, ADD_FIRM_BANKS, ADD_FIRM_BANKS_ERROR, ADD_FIRM_BANKS_LOADING, ADD_FIRM_ERROR, ADD_FIRM_LOADING, DELETE_FIRM, DELETE_FIRM_ASSETS, DELETE_FIRM_ASSETS_ERROR, DELETE_FIRM_ASSETS_LOADING, DELETE_FIRM_BANKS, DELETE_FIRM_BANKS_ERROR, DELETE_FIRM_BANKS_LOADING, DELETE_FIRM_ERROR, DELETE_FIRM_LOADING, EDIT_FIRM, EDIT_FIRM_ASSETS, EDIT_FIRM_ASSETS_ERROR, EDIT_FIRM_ASSETS_LOADING, EDIT_FIRM_BANKS, EDIT_FIRM_BANKS_ERROR, EDIT_FIRM_BANKS_LOADING, EDIT_FIRM_ERROR, EDIT_FIRM_LOADING, GET_ALL_FIRM, GET_ALL_FIRM_ERROR, GET_ALL_FIRM_LOADING, GET_BY_FIRM_ASSETS, GET_BY_FIRM_ASSETS_ERROR, GET_BY_FIRM_ASSETS_LOADING, GET_BY_FIRM_BANKS, GET_BY_FIRM_BANKS_ERROR, GET_BY_FIRM_BANKS_LOADING, GET_BY_ID_FIRM, GET_BY_ID_FIRM_ERROR, GET_BY_ID_FIRM_LOADING } from "../type";


const getAllFirmData: any = {
    getAllFirmLoading: false,
    getAllFirm: [],
    getAllFirmError: '',
}

const addFirmData: any = {
    addFirmLoading: false,
    addFirm: null,
    addFirmError: '',
}

const deleteFirmData: any = {
    deleteFirmLoading: false,
    deleteFirm: null,
    deleteFirmError: '',
}

const getByIdFirmData: any = {
    getByIdFirmLoading: false,
    getByIdFirm: null,
    getByIdFirmError: '',
}

const editFirmData: any = {
    editFirmLoading: false,
    editFirm: null,
    editFirmError: '',
}

const getByIdFirmAssetsData: any = {
    getByIdFirmAssetsLoading: false,
    getByIdFirmAssets: null,
    getByIdFirmAssetsError: '',
}

const addFirmFirmAssetsData: any = {
    addFirmFirmAssetsLoading: false,
    addFirmFirmAssets: null,
    addFirmFirmAssetsError: '',
}

const deleteFirmAssetsData: any = {
    deleteFirmAssetsLoading: false,
    deleteFirmAssets: null,
    deleteFirmAssetsError: '',
}

const editFirmAssetsData: any = {
    editFirmAssetsLoading: false,
    editFirmAssets: null,
    editFirmAssetsError: '',
}


const getByIdFirmBanksData: any = {
    getByIdFirmBanksLoading: false,
    getByIdFirmBanks: null,
    getByIdFirmBanksError: '',
}

const addFirmFirmBanksData: any = {
    addFirmFirmBanksLoading: false,
    addFirmFirmBanks: null,
    addFirmFirmBanksError: '',
}

const deleteFirmBanksData: any = {
    deleteFirmBanksLoading: false,
    deleteFirmBanks: null,
    deleteFirmBanksError: '',
}

const editFirmBanksData: any = {
    editFirmBanksLoading: false,
    editFirmBanks: null,
    editFirmBanksError: '',
}

export const getAllFirmReducer = (state = getAllFirmData, action: any) => {
    switch (action.type) {
        case GET_ALL_FIRM_LOADING:
            return {
                ...state,
                getAllFirmLoading: action.payload,
            };
        case GET_ALL_FIRM:
            return {
                ...state,
                getAllFirm: action.payload,
            };
        case GET_ALL_FIRM_ERROR:
            return {
                ...state,
                getAllFirmError: action.payload,
            };
        default:
            return state;
    }
}

export const addFirmReducer = (state = addFirmData, action: any) => {
    switch (action.type) {
        case ADD_FIRM_LOADING:
            return {
                ...state,
                addFirmLoading: action.payload,
            };
        case ADD_FIRM:
            return {
                ...state,
                addFirm: action.payload,
            };
        case ADD_FIRM_ERROR:
            return {
                ...state,
                addFirmError: action.payload,
            };
        default:
            return state;
    }
}

export const deleteFirmReducer = (state = deleteFirmData, action: any) => {
    switch (action.type) {
        case DELETE_FIRM_LOADING:
            return {
                ...state,
                deleteFirmLoading: action.payload,
            };
        case DELETE_FIRM:
            return {
                ...state,
                deleteFirm: action.payload,
            };
        case DELETE_FIRM_ERROR:
            return {
                ...state,
                deleteFirmError: action.payload,
            };
        default:
            return state;
    }
}

export const getByIdFirmReducer = (state = getByIdFirmData, action: any) => {
    switch (action.type) {
        case GET_BY_ID_FIRM_LOADING:
            return {
                ...state,
                getByIdFirmLoading: action.payload,
            };
        case GET_BY_ID_FIRM:
            return {
                ...state,
                getByIdFirm: action.payload,
            };
        case GET_BY_ID_FIRM_ERROR:
            return {
                ...state,
                getByIdFirmError: action.payload,
            };
        default:
            return state;
    }
}

export const editFirmReducer = (state = editFirmData, action: any) => {
    switch (action.type) {
        case EDIT_FIRM_LOADING:
            return {
                ...state,
                editFirmLoading: action.payload,
            };
        case EDIT_FIRM:
            return {
                ...state,
                editFirm: action.payload,
            };
        case EDIT_FIRM_ERROR:
            return {
                ...state,
                editFirmError: action.payload,
            };
        default:
            return state;
    }
}

export const getByIdFirmAssetsReducer = (state = getByIdFirmAssetsData, action: any) => {
    switch (action.type) {
        case GET_BY_FIRM_ASSETS_LOADING:
            return {
                ...state,
                getByIdFirmAssetsLoading: action.payload,
            };
        case GET_BY_FIRM_ASSETS:
            return {
                ...state,
                getByIdFirmAssets: action.payload,
            };
        case GET_BY_FIRM_ASSETS_ERROR:
            return {
                ...state,
                getByIdFirmAssetsError: action.payload,
            };
        default:
            return state;
    }
}

export const addFirmAssetsReducer = (state = addFirmFirmAssetsData, action: any) => {
    switch (action.type) {
        case ADD_FIRM_ASSETS_LOADING:
            return {
                ...state,
                addFirmAssetsLoading: action.payload,
            };
        case ADD_FIRM_ASSETS:
            return {
                ...state,
                addFirmAssets: action.payload,
            };
        case ADD_FIRM_ASSETS_ERROR:
            return {
                ...state,
                addFirmAssetsError: action.payload,
            };
        default:
            return state;
    }
}

export const deleteFirmAssetsReducer = (state = deleteFirmAssetsData, action: any) => {
    switch (action.type) {
        case DELETE_FIRM_ASSETS_LOADING:
            return {
                ...state,
                deleteFirmAssetsLoading: action.payload,
            };
        case DELETE_FIRM_ASSETS:
            return {
                ...state,
                deleteFirmAssets: action.payload,
            };
        case DELETE_FIRM_ASSETS_ERROR:
            return {
                ...state,
                deleteFirmAssetsError: action.payload,
            };
        default:
            return state;
    }
}

export const editFirmAssetsReducer = (state = editFirmAssetsData, action: any) => {
    switch (action.type) {
        case EDIT_FIRM_ASSETS_LOADING:
            return {
                ...state,
                editFirmAssetsLoading: action.payload,
            };
        case EDIT_FIRM_ASSETS:
            return {
                ...state,
                editFirmAssets: action.payload,
            };
        case EDIT_FIRM_ASSETS_ERROR:
            return {
                ...state,
                editFirmAssetsError: action.payload,
            };
        default:
            return state;
    }
}

export const getByIdFirmBanksReducer = (state = getByIdFirmBanksData, action: any) => {
    switch (action.type) {
        case GET_BY_FIRM_BANKS_LOADING:
            return {
                ...state,
                getByIdFirmBanksLoading: action.payload,
            };
        case GET_BY_FIRM_BANKS:
            return {
                ...state,
                getByIdFirmBanks: action.payload,
            };
        case GET_BY_FIRM_BANKS_ERROR:
            return {
                ...state,
                getByIdFirmBanksError: action.payload,
            };
        default:
            return state;
    }
}

export const addFirmBanksReducer = (state = addFirmFirmBanksData, action: any) => {
    switch (action.type) {
        case ADD_FIRM_BANKS_LOADING:
            return {
                ...state,
                addFirmBanksLoading: action.payload,
            };
        case ADD_FIRM_BANKS:
            return {
                ...state,
                addFirmBanks: action.payload,
            };
        case ADD_FIRM_BANKS_ERROR:
            return {
                ...state,
                addFirmBanksError: action.payload,
            };
        default:
            return state;
    }
}

export const deleteFirmBanksReducer = (state = deleteFirmBanksData, action: any) => {
    switch (action.type) {
        case DELETE_FIRM_BANKS_LOADING:
            return {
                ...state,
                deleteFirmBanksLoading: action.payload,
            };
        case DELETE_FIRM_BANKS:
            return {
                ...state,
                deleteFirmBanks: action.payload,
            };
        case DELETE_FIRM_BANKS_ERROR:
            return {
                ...state,
                deleteFirmBanksError: action.payload,
            };
        default:
            return state;
    }
}

export const editFirmBanksReducer = (state = editFirmBanksData, action: any) => {
    switch (action.type) {
        case EDIT_FIRM_BANKS_LOADING:
            return {
                ...state,
                editFirmBanksLoading: action.payload,
            };
        case EDIT_FIRM_BANKS:
            return {
                ...state,
                editFirmBanks: action.payload,
            };
        case EDIT_FIRM_BANKS_ERROR:
            return {
                ...state,
                editFirmBanksError: action.payload,
            };
        default:
            return state;
    }
}