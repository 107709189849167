import { DELETE_INVOICE_ERROR, GET_BY_FIRM_INVOICE, GET_BY_FIRM_INVOICE_ERROR, GET_BY_FIRM_INVOICE_LOADING } from "../type";
import { DELETE_INVOICE } from "../type";
import { ADD_INVOICE, ADD_INVOICE_ERROR, ADD_INVOICE_LOADING, DELETE_INVOICE_LOADING, EDIT_INVOICE, EDIT_INVOICE_ERROR, EDIT_INVOICE_LOADING, GET_ALL_INVOICE, GET_ALL_INVOICE_ERROR, GET_ALL_INVOICE_LOADING, GET_INVOICE_BY_ID, GET_INVOICE_BY_ID_ERROR, GET_INVOICE_BY_ID_LOADING } from "../type";

const getAllInvoiceData: any = {
    getAllInvoiceLoading: false,
    getAllInvoice: [],
    getAllInvoiceError: '',
}

const addInvoiceData: any = {
    addInvoiceLoading: false,
    addInvoice: null,
    addInvoiceError: '',
}

const editInvoiceData: any = {
    editInvoiceLoading: false,
    editInvoice: null,
    editInvoiceError: '',
}

const getInvoiceByIdData: any = {
    getInvoiceByIdLoading: false,
    getInvoiceById: null,
    getInvoiceByIdError: '',
}

const getInvoiceByFirmIdData: any = {
    getInvoiceByFirmIdLoading: false,
    getInvoiceByFirmId: null,
    getInvoiceByFirmIdError: '',
}

const deleteInvoiceData: any = {
    deleteInvoiceLoading: false,
    deleteInvoice: null,
    deleteInvoiceError: '',
}

export const getInvoiceByIdReducer = (state = getInvoiceByIdData, action: any) => {
    switch (action.type) {
        case GET_INVOICE_BY_ID_LOADING:
            return {
                ...state,
                getInvoiceByIdLoading: action.payload,
            };
        case GET_INVOICE_BY_ID:
            return {
                ...state,
                getInvoiceById: action.payload,
            };
        case GET_INVOICE_BY_ID_ERROR:
            return {
                ...state,
                getInvoiceByIdError: action.payload,
            };
        default:
            return state;
    }
}

export const getInvoiceByFirmIdReducer = (state = getInvoiceByFirmIdData, action: any) => {
    switch (action.type) {
        case GET_BY_FIRM_INVOICE_LOADING:
            return {
                ...state,
                getInvoiceByFirmIdLoading: action.payload,
            };
        case GET_BY_FIRM_INVOICE:
            return {
                ...state,
                getInvoiceByFirmId: action.payload,
            };
        case GET_BY_FIRM_INVOICE_ERROR:
            return {
                ...state,
                getInvoiceByFirmIdError: action.payload,
            };
        default:
            return state;
    }
}

export const getAllInvoiceReducer = (state = getAllInvoiceData, action: any) => {
    switch (action.type) {
        case GET_ALL_INVOICE_LOADING:
            return {
                ...state,
                getAllInvoiceLoading: action.payload,
            };
        case GET_ALL_INVOICE:
            return {
                ...state,
                getAllInvoice: action.payload,
            };
        case GET_ALL_INVOICE_ERROR:
            return {
                ...state,
                getAllInvoiceError: action.payload,
            };
        default:
            return state;
    }
}

export const addInvoiceReducer = (state = addInvoiceData, action: any) => {
    switch (action.type) {
        case ADD_INVOICE_LOADING:
            return {
                ...state,
                addInvoiceLoading: action.payload,
            };
        case ADD_INVOICE:
            return {
                ...state,
                addInvoice: action.payload,
            };
        case ADD_INVOICE_ERROR:
            return {
                ...state,
                addInvoiceError: action.payload,
            };
        default:
            return state;
    }
}

export const editInvoiceReducer = (state = editInvoiceData, action: any) => {
    switch (action.type) {
        case EDIT_INVOICE_LOADING:
            return {
                ...state,
                editInvoiceLoading: action.payload,
            };
        case EDIT_INVOICE:
            return {
                ...state,
                editInvoice: action.payload,
            };
        case EDIT_INVOICE_ERROR:
            return {
                ...state,
                editInvoiceError: action.payload,
            };
        default:
            return state;
    }
}

export const deleteInvoiceReducer = (state = deleteInvoiceData, action: any) => {
    switch (action.type) {
        case DELETE_INVOICE_LOADING:
            return {
                ...state,
                deleteInvoiceLoading: action.payload,
            };
        case DELETE_INVOICE:
            return {
                ...state,
                deleteInvoice: action.payload,
            };
        case DELETE_INVOICE_ERROR:
            return {
                ...state,
                deleteInvoiceError: action.payload,
            };
        default:
            return state;
    }
}