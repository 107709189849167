import React, { useEffect, useState } from 'react';
import Button from '../../components/elements/Button';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/elements/Table';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Action from '../../assets/img/table_action.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { deleteInvoiceAction, getAllInvoiceAction } from '../../redux/actions/invoiceAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DeleteConfirmation from '../../components/modals/DeleteConfirmation';
import { toast } from 'react-toastify';
import { DELETE_INVOICE } from '../../redux/type';
import { ApiGet, BaseURL, downloadPDF } from '../../helper/API/ApiData';
import axios from 'axios';
import AuthStorage from '../../helper/AuthStorage';
import GenerateInvoiceModal from './GenerateInvoiceModal';
import { Delete, Edit, Preview } from '@mui/icons-material';

const Index = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceId, setInvoiceID] = useState<any>('')
    const [openModal, setOpenModal] = useState(false);
    const [generateModal, setGenerateModal] = useState(false);

    const getInvoiceData = useSelector((state: any) => state.getAllInvoice.getAllInvoice);
    const deleteInvoiceData = useSelector((state: any) => state.deleteInvoice.deleteInvoice);

    useEffect(() => {
        dispatch(getAllInvoiceAction())
    }, [])

    useEffect(() => {
        if (getInvoiceData && getInvoiceData.data?.length) {
            setInvoiceData(getInvoiceData.data);
        } else {
            setInvoiceData([]);
        }
    }, [getInvoiceData])

    useEffect(() => {
        if (deleteInvoiceData) {
            if (deleteInvoiceData.status === 200) {
                toast.success(deleteInvoiceData.message)
                setOpenModal(false)
                dispatch(getAllInvoiceAction())
            } else {
                toast.error(deleteInvoiceData.message)
            }
            dispatch({
                type: DELETE_INVOICE,
                payload: ''
            })
        }
    }, [deleteInvoiceData])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (id: any) => {
        setAnchorEl(null);
    };

    const editData = (id: any) => {
        navigate(`edit/${id}`)
        handleClose(id);
        setAnchorEl(null);
    }

    const handleDelete = () => {
        if (invoiceId) {
            dispatch(deleteInvoiceAction(invoiceId))
        }
    }
    const PreViewInvoice = async (id: any) => {
        // const fileURL: any = await downloadPDF(`invoice/download/${id}`).then(url => url ?? '')
        axios({
            method: 'get',
            url: `${BaseURL}invoice/download/${id}`,
            responseType: 'blob',
            headers: { Authorization: AuthStorage.getToken() },
        }).then((response) => {
            const file = new Blob([response.data], {
                type: 'application/pdf',
            });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }, (error) => {
            console.log(error);
        });
    }

    const columns: GridColDef[] = [
        { field: 'invoiceNumber', headerName: 'No', flex: 1, renderCell: ((param: any) => <>{`${param.row.areaType === "EXPORT" ? "E" : "T"}${param.row.invoiceNumber}/${param.row.financialYear}`}</>) },
        {
            field: 'invoiceDate', headerName: 'Date', flex: 1,
            renderCell: ({ row }) => {
                return (
                    <>{moment(row.invoiceDate).format('DD/MM/YYYY')}</>
                )
            }
        },
        { field: 'billTo', headerName: 'Client name', flex: 1 },
        { field: 'billFrom', headerName: 'Firm name', flex: 1 },
        { field: 'areaType', headerName: 'Type', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1, },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            renderCell: ({ row }) => {
                return (
                    <>
                        <div className='table_actions' >
                            <Preview fontSize="small" style={{ margin: "5px" }}
                                onClick={() => {
                                    PreViewInvoice(row._id)
                                    // setAnchorEl(null)
                                }}
                            />
                            < Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => editData(row._id)} />
                            <Delete color='error' fontSize="small" style={{ margin: "5px" }}
                                onClick={() => {
                                    setInvoiceID(row._id)
                                    setOpenModal(true)
                                    // setAnchorEl(null)
                                }}
                            />
                        </div>
                    </>
                )
            },
            // getActions: ({ id }) => {
            //     return [
            //         <GridActionsCellItem
            //             icon={
            //                 <div className='table_actions' >
            //                     <Preview fontSize="small" style={{ margin: "5px" }}
            //                         onClick={() => {
            //                             PreViewInvoice(id)
            //                             // setAnchorEl(null)
            //                         }}
            //                     />
            //                     < Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => editData(id)} />
            //                     <Delete color='error' fontSize="small" style={{ margin: "5px" }}
            //                         onClick={() => {
            //                             setInvoiceID(id)
            //                             setOpenModal(true)
            //                             // setAnchorEl(null)
            //                         }}
            //                     />
            //                 </div >
            //                 //     <div>
            //                 //         {/* <IconButton
            //                 //             aria-label="more"
            //                 //             id="long-button"
            //                 //             aria-controls={open ? 'long-menu' : undefined}
            //                 //             aria-expanded={open ? 'true' : undefined}
            //                 //             aria-haspopup="true"
            //                 //             onClick={(e) => {
            //                 //                 setInvoiceID(id)
            //                 //                 handleClick(e)
            //                 //             }}
            //                 //         >
            //                 //             <img src={Action} alt='' />
            //                 //         </IconButton> */}
            //                 //         {/* <Menu
            //                 //             id="long-menu"
            //                 //             MenuListProps={{
            //                 //                 'aria-labelledby': 'long-button',
            //                 //             }}
            //                 //             anchorEl={anchorEl}
            //                 //             open={open}
            //                 //             onClose={(e) => handleClose(id)}
            //                 //             PaperProps={{
            //                 //                 style: {
            //                 //                     maxHeight: '150px',
            //                 //                     width: '20ch',
            //                 //                 },
            //                 //             }}
            //                 //         >
            //                 //             <MenuItem onClick={(e) => editData(id)}>
            //                 //                 Edit
            //                 //             </MenuItem>
            //                 //             <MenuItem onClick={() => {
            //                 //                 setOpenModal(true)
            //                 //                 setAnchorEl(null)
            //                 //             }}>
            //                 //                 Delete
            //                 //             </MenuItem>
            //                 //             <MenuItem onClick={() => {
            //                 //                 PreViewInvoice(id)
            //                 //                 setAnchorEl(null)
            //                 //             }}>
            //                 //                 Preview
            //                 //             </MenuItem>
            //                 //         </Menu> */}
            //                 //     </div>
            //             }
            //             label="Edit"
            //             color="inherit"
            //         />
            //     ];
            // },
        },
    ];

    return (
        <div className=''>
            <div className='card_header'>
                <h1 className='card_header_title'>Invoice</h1>
                <div className='card_footer'>
                    <Button
                        onClick={() => setGenerateModal(true)}
                        variant="outlined"
                        buttonClass='contained_button_add'
                        child='Generate Invoice' />
                    <Button
                        onClick={() => navigate('add')}
                        variant="outlined"
                        buttonClass='contained_button_add'
                        child='Add' />
                </div>
            </div>
            <Table rows={invoiceData?.map((item: any, i: number) => { return { ...item, id: item._id ?? i + 1, createdAt: moment(item.createdAt).format('DD/MM/YYYY') } })} columns={columns} />
            {openModal && <DeleteConfirmation open={openModal} title='invoice' handleClose={(data: any) => setOpenModal(false)} handleConfirm={() => handleDelete()} />}
            {generateModal && <GenerateInvoiceModal open={generateModal} title='invoice' handleClose={(data: any) => setGenerateModal(false)} />}
        </div>
    )
}

export default Index