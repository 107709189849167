import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const getCookie = (name: string) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");
  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
};

export const dateFunction = (date: any) => {
  if (
    moment(date).format("YYYY.MM.DD") ===
    moment(new Date()).format("YYYY.MM.DD")
  ) {
    return "Today";
  } else if (
    moment(date).format("YYYY.MM.DD") ===
    moment(new Date()).subtract(1, "days").format("YYYY.MM.DD")
  ) {
    return "Yesterday";
  }
  return moment(date).format("YYYY.MM.DD");
};


export const checkImageURL = (nationality: any) => {
  const pngImages = ["Antarctica"];

  let url_image = `./img/flags/${nationality}.svg`;
  if (pngImages.includes(nationality)) {
    url_image = `./img/flags/${nationality}.png`;
  }
  return url_image
}

export const xwwwFormUrlencoded = (formData: any) => {
  var formBody: any = [];
  for (var property in formData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(formData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return formBody
}

export const dateFormate = (date: any, type: any) => {
  if (type === 'FullDateTime') {
    return moment(date).format('DD MMM YYYY hh:mm A')
  } else if (type === 'Date') {
    return moment(date).format('DD MMM YYYY')
  } else if (type === 'Time') {
    return moment(date).format('hh:mm A')
  }
}

export const downloadFile = (data: any, name: any, showAmmount: any) => {
  let totalQuantity = 0
  // let fileName = name.split(' ').join('_')
  let entries = data.purchaseItems.map((ele: any, ind: any) => {
    totalQuantity = totalQuantity + ele.quantity
    return showAmmount ? [
      { text: ind + 1, alignment: 'center', border: [true, false, true, false] },
      { text: ele.productName, border: [true, false, true, false] },
      { text: ele.quantity, alignment: 'center', border: [true, false, true, false] },
      { text: ele.rate, alignment: 'center', border: [true, false, true, false] },
      { text: ele.amount, alignment: 'center', border: [true, false, true, false] }
    ] :
      [
        { text: ind + 1, alignment: 'center', border: [true, false, true, false] },
        { text: ele.productName, border: [true, false, true, false] },
        { text: ele.quantity, alignment: 'center', border: [true, false, true, false], colSpan: 3 },
        "",
        ""
      ]
  })

  if (name === 'Purchase Challan' && showAmmount) {
    entries = [...entries,
    [
      { text: 'Product Amount', alignment: 'right', colSpan: 2, bold: true, fontSize: 13 },
      '',
      { text: '', colSpan: 2 },
      '',
      { text: data.productAmount, width: 80, alignment: 'center', bold: true, fontSize: 13 }
    ],
    [
      { text: 'Discount', alignment: 'right', colSpan: 2, bold: true, fontSize: 13 },
      '',
      { text: '', colSpan: 2 },
      '',
      { text: data.discountAmount, width: 80, alignment: 'center', bold: true, fontSize: 13 }
    ],
    ]
  }

  let termAndCondition: any = ''
  if (data.termAndConditions !== '') {
    termAndCondition = {
      stack: [
        { text: 'Terms and Condition:', bold: true, fontSize: 10 },
        { text: '\n', fontSize: 2 },
        { text: data.termAndConditions, fontSize: 8 },
      ],
      colSpan: 2
    }
  }


  const docDefinition: any = {
    pageSize: 'A4',
    // pageOrientation: 'landscape',
    content: [
      {
        table: {
          headerRows: 0,
          widths: [15, '*', 60, 65, 85],
          dontBreakRows: true,
          body: [
            [
              {
                stack: [
                  { text: '\n', fontSize: 3 },
                  { text: 'SHRADDHA FIRE AND SAFETY SERVICES', bold: true, fontSize: 25 },
                  { text: '\n', fontSize: 3 },
                  { text: '119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT', fontSize: 10 }

                ],
                alignment: 'center',
                colSpan: 5,
              }, '', '', '', ''
            ],
            [
              { text: name, alignment: 'center', fontSize: 12, bold: true, colSpan: 5 },
              '', '', '', ''

            ],
            [
              {
                stack: [
                  { text: 'To,', fontSize: 10 },
                  { text: '\n', fontSize: 2 },
                  { text: data.customerName, bold: true, fontSize: 10 },
                  { text: '\n', fontSize: 2 },
                ],
                colSpan: 3
              },
              '', '',
              {
                stack: [
                  {
                    columns: [
                      { text: 'Challan No.', width: '*', fontSize: 10 },
                      { text: `: ${data.invoiceNo}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                  { text: '\n', fontSize: 2 },
                  {
                    columns: [
                      { text: 'Date', width: '*', fontSize: 10 },
                      { text: `: ${dateFormate(data.invoiceDate, 'Date')}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                ],
                colSpan: 2
              },
              '',
            ],
            showAmmount ? [
              { text: 'SR', alignment: 'center', fontSize: 11 },
              { text: 'Description Of Goods', alignment: 'center', fontSize: 11 },
              { text: 'Quantity', alignment: 'center', fontSize: 11 },
              { text: 'Rate', alignment: 'center', fontSize: 11 },
              { text: 'Amount', alignment: 'center', fontSize: 11 }
            ]
              :
              [
                { text: 'SR', alignment: 'center', fontSize: 11 },
                { text: 'Description Of Goods', alignment: 'center', fontSize: 11 },
                { text: 'Quantity', alignment: 'center', fontSize: 11, colSpan: 3 },
                "",
                ""
              ],
            ...entries,
            showAmmount ? [
              { text: `Total Amount`, alignment: 'right', colSpan: 2, bold: true, fontSize: 14 },
              '',
              { text: totalQuantity, alignment: 'center', bold: true, fontSize: 14 },
              '',
              { text: data.totalAmount, width: 80, alignment: 'center', bold: true, fontSize: 14 }
            ] :
              [
                { text: `Total Quantity`, alignment: 'right', colSpan: 2, bold: true, fontSize: 14 },
                '',
                { text: totalQuantity, alignment: 'center', bold: true, fontSize: 14, colSpan: 3 },
                '',
                ""
              ]
            ,
            [
              termAndCondition,
              '',
              {
                stack: [
                  { text: 'For, SHRADDHA FIRE AND SAFETY SERVICES', bold: true, fontSize: 11 },
                  { text: '\n' }, { text: '\n' }, { text: '\n' }, { text: '\n' },
                  { text: 'Authorised Signatory', fontSize: 9 },
                ],
                colSpan: 3
              },
              '', ''
            ]

          ]
        },
      }
    ]

  }
  // pdfMake.createPdf(docDefinition).download(`${fileName}_${data.invoiceNo}`) // to download   
  pdfMake.createPdf(docDefinition).open()

}




export const downloadPRFile = (data: any, name: any) => {
  // let fileName = name.split(' ').join('_')
  let entries = data.items.map((ele: any, ind: any) => {
    return (
      [{ text: ind + 1, alignment: 'center', border: [true, false, true, true] },
      { text: ele.productName, alignment: 'center', border: [true, false, true, true] },
      { text: ele.reqQuantity, alignment: 'center', border: [true, false, true, true] },
      { text: ele.deliveredQuantity, alignment: 'center', border: [true, false, true, true] },
      { text: ele.priority, alignment: 'center', border: [true, false, true, true] },
      { text: ele.unit, alignment: 'center', border: [true, false, true, true] },
      { text: ele.desc, alignment: 'center', border: [true, false, true, true] },
      { text: ele.make, alignment: 'center', border: [true, false, true, true] }]
    )
  })


  const docDefinition: any = {
    pageSize: 'A4',
    // pageOrientation: 'landscape',
    content: [
      {
        table: {
          headerRows: 0,
          widths: [15, '*', 50, 50, 50, 50, 80, 80],
          dontBreakRows: true,
          body: [
            [
              {
                stack: [
                  { text: '\n', fontSize: 3 },
                  { text: 'SHRADDHA FIRE AND SAFETY SERVICES', bold: true, fontSize: 25 },
                  { text: '\n', fontSize: 3 },
                  { text: '119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT', fontSize: 10 }

                ],
                alignment: 'center',
                colSpan: 8,
              }, '', '', '', '', '', '', ''
            ],
            [
              {
                stack: [
                  { text: name, alignment: 'center', fontSize: 12, bold: true, colSpan: 5 },
                ],
                alignment: 'center',
                colSpan: 8,
              }, '', '', '', '', '', '', ''
            ],
            [
              {
                stack: [
                  {
                    columns: [
                      { text: 'Purchase Request No.', width: '*', fontSize: 10 },
                      { text: `: ${data?.purchaseRequestNumber ? data?.purchaseRequestNumber : ''}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                  { text: '\n', fontSize: 2 },
                  {
                    columns: [
                      { text: 'Project Name.', width: '*', fontSize: 10 },
                      {
                        text: `: ${data?.project?.projectName}`, width: '*', fontSize: 10, bold: true
                      }
                    ]
                  },
                  { text: '\n', fontSize: 2 },
                  {
                    columns: [
                      { text: 'Status.', width: '*', fontSize: 10 },
                      { text: `: ${data.status}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                ],
                colSpan: 8
              },
              '', '', '', '', '', '', ''
            ],
            [
              {
                stack: [
                  { text: '', alignment: 'center', fontSize: 12, border: [false, false, false, false] },
                ],
                alignment: 'center',
                colSpan: 8,
              }, '', '', '', '', '', '', ''
            ],
            [
              { text: 'SR', alignment: 'center', fontSize: 11 },
              { text: 'Product', alignment: 'center', fontSize: 11 },
              { text: 'Req Qty', alignment: 'center', fontSize: 11 },
              { text: 'Delv Qty', alignment: 'center', fontSize: 11 },
              { text: 'Priority', alignment: 'center', fontSize: 11 },
              { text: 'Unit', alignment: 'center', fontSize: 11 },
              { text: 'Description', alignment: 'center', fontSize: 11 },
              { text: 'make', alignment: 'center', fontSize: 11 },
            ],
            ...entries,
          ]
        },
      }
    ]

  }
  // // pdfMake.createPdf(docDefinition).download(`${fileName}_${data.invoiceNo}`) // to download   
  pdfMake.createPdf(docDefinition).open()

}