import React, { useEffect, useState } from "react";
import Button from "../../components/elements/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { getByMonthAssetsAction } from "../../redux/actions/assetsAction";
import moment from "moment";
import noData from "../../assets/img/no-data.png";
import NoDataElement from "../../components/elements/NoDataElement";

const AssetsByMonth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getByMonthAssetsData = useSelector(
    (state: any) => state.getByMonthAssets.getByMonthAssets
  );

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [byMonthAssetsData, setByMonthAssetsData] = useState([]);

  useEffect(() => {
    dispatch(getByMonthAssetsAction());
  }, []);

  useEffect(() => {
    if (getByMonthAssetsData && getByMonthAssetsData.data) {
      setByMonthAssetsData(getByMonthAssetsData.data);
    }
  }, [getByMonthAssetsData]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      {Object.entries(byMonthAssetsData).length ? Object.entries(byMonthAssetsData).map(([key, ele]: any) => {
        console.log("ele", ele);
        return (
          <Accordion
            style={{ backgroundColor: "#f3e9f5" }}
            expanded={expanded === key}
            onChange={handleChange(key)}
            key={key}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {key?.replace("_", " ")} (
                {ele.reduce((sum: any, obj: any) => +sum + +obj.amount, 0)})
              </Typography>
            </AccordionSummary>
            {ele.map((item: any) => {
              return (
                <AccordionDetails key={item._id}>
                  <Typography>
                    <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                      <div className="group_by_month_card">
                        <Grid
                          container
                          spacing={2}
                          style={{ marginBottom: "16px" }}
                        >
                          <Grid item xs={12} lg={4} xl={4} md={6} sm={12}>
                            Date :- {moment(item.date).format("DD/MM/YYYY")}
                          </Grid>
                          <Grid item xs={12} lg={4} xl={4} md={6} sm={12}>
                            Amount :- {item.amount}
                          </Grid>
                          <Grid item xs={12} lg={4} xl={4} md={6} sm={12}>
                            Recovery Type :- {item.recoveryType}
                          </Grid>
                          <Grid item xs={12} lg={4} xl={4} md={6} sm={12}>
                            Expiry Date :-{" "}
                            {moment(item.expirydate).format("DD/MM/YYYY")}
                          </Grid>
                          <Grid item xs={12} lg={4} xl={4} md={6} sm={12}>
                            Is working :- {item.isWorking ? "Yes" : "No"}
                          </Grid>
                          <Grid item xs={12} lg={4} xl={4} md={6} sm={12}>
                            Period :- {item.period} Years
                          </Grid>

                          <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                            Note :- {item.note}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              );
            })}
          </Accordion>
        );
      })
        :
        <NoDataElement />
      }
    </div>
  );
};

export default AssetsByMonth;
