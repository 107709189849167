import React, { useEffect, useState } from 'react'
import Button from '../../../components/elements/Button'
import { useNavigate, useParams } from 'react-router-dom'
import Tables from '../../../components/elements/Table'
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton';
import Action from '../../../assets/img/table_action.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@mui/material/Grid";
import { deleteInvoiceAction, getInvoiceByFirmIdAction } from '../../../redux/actions/invoiceAction'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios';
import { BaseURL } from '../../../helper/API/ApiData'
import AuthStorage from '../../../helper/AuthStorage'
import DeleteConfirmation from '../../../components/modals/DeleteConfirmation'
import { toast } from 'react-toastify'
import { DELETE_INVOICE, IS_LOADING } from '../../../redux/type'
import { Delete, Edit, Preview } from '@mui/icons-material';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoDataElement from "../../../components/elements/NoDataElement";
import { isTemplateMiddle } from 'typescript'

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';

const FirmInvoice = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const firmId = params.id

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [firmInvoiceData, setFirmInvoiceData] = useState([]);
    const [firmInvoiceDataByMonth, setFirmInvoiceDataByMonth] = useState([]);
    const [invoiceId, setInvoiceID] = useState<any>('')
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = useState(false);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [Type, setType] = useState(false)

    const getInvoiceByFirmIdData = useSelector((state: any) => state.getInvoiceByFirmId.getInvoiceByFirmId);
    const deleteInvoiceData = useSelector((state: any) => state.deleteInvoice.deleteInvoice);


    function createData(
        name: string,
        calories: number,
        fat: number,
        carbs: number,
        protein: number,
    ) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    ];



    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    useEffect(() => {
        if (getInvoiceByFirmIdData && getInvoiceByFirmIdData?.data?.length) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const data = getInvoiceByFirmIdData?.data
            const groupByMonth = data.reduce((group: any, item: any) => {
                const { invoiceDate }: any = item;
                console.log('invoiceDate', invoiceDate)

                let dateMonth = new Date(invoiceDate).getMonth();
                let dateYear = new Date(invoiceDate).getFullYear();

                group[`${months[dateMonth]}_${dateYear}`] = group[`${months[dateMonth]}_${dateYear}`] ?? [];
                group[`${months[dateMonth]}_${dateYear}`].push(item);

                return group;
            }, {});
            setFirmInvoiceData(getInvoiceByFirmIdData?.data);
            setFirmInvoiceDataByMonth(groupByMonth);
        } else {
            setFirmInvoiceData([]);
        }
    }, [getInvoiceByFirmIdData])

    useEffect(() => {
        dispatch(getInvoiceByFirmIdAction(firmId))
    }, [])

    useEffect(() => {
        if (deleteInvoiceData) {
            if (deleteInvoiceData.status === 200) {
                toast.success(deleteInvoiceData.message)
                setOpenModal(false)
                dispatch(getInvoiceByFirmIdAction(firmId))
            } else {
                toast.error(deleteInvoiceData.message)
            }
            dispatch({
                type: DELETE_INVOICE,
                payload: ''
            })
        }
    }, [deleteInvoiceData])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (id: any) => {
        setAnchorEl(null);
    };

    const PreViewInvoice = async (id: any) => {
        // const fileURL: any = await downloadPDF(`invoice/download/${id}`).then(url => url ?? '')
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        axios({
            method: 'get',
            url: `${BaseURL}invoice/download/${id}`,
            responseType: 'blob',
            headers: { Authorization: AuthStorage.getToken() },
        }).then((response) => {
            const file = new Blob([response.data], {
                type: 'application/pdf',
            });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }, (error) => {

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
            console.log(error);
        });
    }

    const handleDelete = () => {
        if (invoiceId) {
            dispatch(deleteInvoiceAction(invoiceId))
        }
    }

    const columns: GridColDef[] = [
        { field: 'invoiceNumber', headerName: 'No', flex: 1, renderCell: ((param: any) => <>{`${param.row.areaType === "EXPORT" ? "E" : "T"}${param.row.invoiceNumber}/${param.row.financialYear}`}</>) },
        {
            field: 'invoiceDate', headerName: 'Date', flex: 1,
            renderCell: ({ row }) => {
                return (
                    <>{moment(row.invoiceDate).format('DD/MM/YYYY')}</>
                )
            }
        },
        { field: 'billTo', headerName: 'Client name', flex: 1 },
        { field: 'billFrom', headerName: 'Firm name', flex: 1 },
        { field: 'areaType', headerName: 'Type', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1, },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            renderCell: ({ row }) => {
                return (
                    <>
                        <div className='table_actions' >
                            <Preview fontSize="small" style={{ margin: "5px" }} onClick={() => { PreViewInvoice(row._id) }} />
                            <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => { setInvoiceID(row._id); navigate(`/invoice/edit/${row._id}`, { state: { firmId: firmId } }) }} />
                            <Delete color='error' fontSize="small" style={{ margin: "5px" }}
                                onClick={() => {
                                    setInvoiceID(row._id);
                                    setOpenModal(true)
                                }}
                            />
                        </div>
                    </>
                )
            },
            // getActions: ({ id }) => {
            //     return [
            //         <GridActionsCellItem
            //             icon={
            //                 <div className='table_actions' >
            //                     <Preview fontSize="small" style={{ margin: "5px" }} onClick={() => { PreViewInvoice(id) }} />
            //                     <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => { setInvoiceID(id); navigate(`/invoice/edit/${id}`, { state: { firmId: firmId } }) }} />
            //                     <Delete color='error' fontSize="small" style={{ margin: "5px" }}
            //                         onClick={() => {
            //                             setInvoiceID(id);
            //                             setOpenModal(true)
            //                         }}
            //                     />
            //                 </div>
            //                 // <div>
            //                 //     <IconButton
            //                 //         aria-label="more"
            //                 //         id="long-button"
            //                 //         aria-controls={open ? 'long-menu' : undefined}
            //                 //         aria-expanded={open ? 'true' : undefined}
            //                 //         aria-haspopup="true"
            //                 //         onClick={(e) => {
            //                 //             setInvoiceID(id)
            //                 //             handleClick(e)
            //                 //         }}
            //                 //     >
            //                 //         <img src={Action} alt='' />
            //                 //     </IconButton>
            //                 //     <Menu
            //                 //         id="long-menu"
            //                 //         MenuListProps={{
            //                 //             'aria-labelledby': 'long-button',
            //                 //         }}
            //                 //         anchorEl={anchorEl}
            //                 //         open={open}
            //                 //         onClose={(e) => handleClose(id)}
            //                 //         PaperProps={{
            //                 //             style: {
            //                 //                 maxHeight: '150px',
            //                 //                 width: '20ch',
            //                 //             },
            //                 //         }}
            //                 //     >
            //                 //         <MenuItem onClick={(e) =>
            //                 //             navigate(`/invoice/edit/${firmId}`, { state: { firmId: firmId } })
            //                 //         }>
            //                 //             Edit
            //                 //         </MenuItem>
            //                 //         <MenuItem onClick={() => {
            //                 //             setOpenModal(true)
            //                 //             setAnchorEl(null);
            //                 //         }}>
            //                 //             Delete
            //                 //         </MenuItem>
            //                 //         <MenuItem onClick={() => {
            //                 //             PreViewInvoice(id)
            //                 //         }}>
            //                 //             Preview
            //                 //         </MenuItem>
            //                 //     </Menu>
            //                 // </div>
            //             }
            //             label="Edit"
            //             className="textPrimary"
            //             onClick={() => { }}
            //             color="inherit"
            //         />
            //     ];
            // },
        },
    ];

    console.log('firmInvoiceDataByMonth', firmInvoiceDataByMonth)
    return (
        <>
            <div className='card_header'>
                <h1 className='card_header_title'>Firm Invoice</h1>
                <div className='card_footer'>
                    <Button
                        onClick={() => navigate(`/invoice/add`, { state: { firmId: firmId } })}
                        variant="outlined"
                        buttonClass='contained_button_add'
                        child='Add' />
                    <Button
                        onClick={() => { setType(!Type) }}
                        variant="outlined"
                        buttonClass='contained_button_add'
                        child={Type ? 'Invoice By List' : 'Invoice By Month'} />
                </div>
            </div>
            {openModal && <DeleteConfirmation open={openModal} title='invoice' handleClose={(data: any) => setOpenModal(false)} handleConfirm={() => handleDelete()} />}
            {Type ? <div className='expenses_month_section'>
                {Object.entries(firmInvoiceDataByMonth).length ? Object.entries(firmInvoiceDataByMonth).map(([key, ele]: any) => {
                    return (
                        <Accordion
                            style={{ backgroundColor: "#f3e9f5", boxShadow: 'none' }}
                            expanded={expanded === key}
                            onChange={handleChange(key)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                style={{ borderBottom: '1px solid #6C48A3', marginTop: '7px' }}
                            >
                                <Typography className='month_name' sx={{ width: "33%", flexShrink: 0, display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <p
                                        style={{
                                            fontSize: '15px',
                                            backgroundColor: '#e9dcf8',
                                            height: '35px',
                                            width: '35px',
                                            lineHeight: '35px',
                                            textAlign: 'center',
                                            borderRadius: '50px'
                                        }}
                                    >{ele.length < 10 ? `0${ele.length}` : ele.length} </p>
                                    {key?.replace("_", " ")}
                                </Typography>
                            </AccordionSummary>
                            {/* <Grid item xs={12} lg={12} xl={12} md={12} sm={12} style={{ padding: '0 16px 0px', borderBottom: '1px solid #a5a5a5' }}>
                                <div className="group_by_month_card" style={{ backgroundColor: 'transparent', borderRadius: '0' }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        className='herader'
                                    >
                                        <Grid item xs={12} lg={1} xl={2} md={1} sm={12} sx={{ alignSelf: 'center' }}>
                                            <p>No</p>
                                        </Grid>
                                        <Grid item xs={12} lg={1} xl={2} md={1} sm={12} sx={{ alignSelf: 'center' }}>
                                            <p>Date</p>
                                        </Grid>
                                        <Grid item xs={12} lg={4} xl={2} md={2} sm={12} sx={{ alignSelf: 'center' }}>
                                            <p>Client Name</p>
                                        </Grid>
                                        <Grid item xs={12} lg={2} xl={2} md={2} sm={12} sx={{ alignSelf: 'center' }}>
                                            <p>Firm name</p>
                                        </Grid>
                                        <Grid item xs={12} lg={1} xl={2} md={1} sm={12} sx={{ alignSelf: 'center' }}>
                                            <p>Type</p>
                                        </Grid>
                                        <Grid item xs={12} lg={1} xl={1} md={2} sm={12} sx={{ alignSelf: 'center' }}>
                                            <p>Amount</p>
                                        </Grid>
                                        <Grid item xs={12} lg={2} xl={1} md={1} sm={12}>
                                            <div className='table_actions'>
                                                <p>Action</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid> */}

                            <AccordionDetails style={{ padding: '0 0px 0px' }}>
                                <Typography>
                                    {/* <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                                                <div className="group_by_month_card firmlvoice_details" style={{ backgroundColor: 'transparent', borderBottom: '1px solid #a5a5a5', borderRadius: '0' }}>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                    >
                                                        <Grid xs={12} lg={1} xl={2} md={1} sm={12} sx={{ alignSelf: 'center' }}>
                                                            <p className="description">{`${item?.areaType === "EXPORT" ? "E" : "T"}${item.invoiceNumber}/${item.financialYear}`}</p>
                                                        </Grid>
                                                        <Grid item xs={12} lg={1} xl={2} md={1} sm={12} sx={{ alignSelf: 'center' }} style={{ paddingTop: '0' }}>
                                                            <p className="description">{moment(item.invoiceDate).format('DD/MM/YYYY')}</p>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4} xl={2} md={2} sm={12} sx={{ alignSelf: 'center' }}>
                                                            <p className="description client_name"
                                                                style={{
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    WebkitLineClamp: 2,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    wordWrap: 'break-word',
                                                                    wordBreak: 'break-word',
                                                                    lineHeight: '13px'
                                                                }}
                                                            >{item.billTo}</p>
                                                        </Grid>
                                                        <Grid item xs={12} lg={2} xl={2} md={2} sm={12} sx={{ alignSelf: 'center' }}>
                                                            <p className="description">{item.billFrom}</p>
                                                        </Grid>
                                                        <Grid item xs={12} lg={1} xl={2} md={1} sm={12} sx={{ alignSelf: 'center' }}>
                                                            <p className="description">{item.areaType}</p>
                                                        </Grid>
                                                        <Grid item xs={12} lg={1} xl={1} md={2} sm={12} sx={{ alignSelf: 'center' }}>
                                                            <p className="description">{item?.currency === 'USD to USD' ? '$' : '₹'}{item.amount}</p>
                                                        </Grid>
                                                        <Grid item xs={12} lg={2} xl={1} md={1} sm={12}>
                                                            <div className='table_actions'>
                                                                <Preview fontSize="small" style={{ margin: "5px" }} onClick={() => { PreViewInvoice(item._id) }} />
                                                                <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => { setInvoiceID(item._id); navigate(`/invoice/edit/${item._id}`, { state: { firmId: firmId } }) }} />
                                                                <Delete color='error' fontSize="small" style={{ margin: "5px" }}
                                                                    onClick={() => {
                                                                        setInvoiceID(item._id);
                                                                        setOpenModal(true)
                                                                    }}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid> */}
                                    <TableContainer component={Paper} className='firmlnvoice_table'>
                                        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: '#e9dcf8', borderRadius: '0' }}>
                                                    <TableCell style={{ fontSize: '16px' }}><p>No</p></TableCell>
                                                    <TableCell style={{ fontSize: '16px' }}><p>Date</p></TableCell>
                                                    <TableCell style={{ fontSize: '16px' }}><p>Client Name</p></TableCell>
                                                    <TableCell style={{ fontSize: '16px' }}><p>Firm name</p></TableCell>
                                                    <TableCell style={{ fontSize: '16px' }}><p>Type</p></TableCell>
                                                    <TableCell style={{ fontSize: '16px' }}><p>Amount</p></TableCell>
                                                    <TableCell style={{ fontSize: '16px' }}><p>Action</p></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ele.map((item: any) => {
                                                    return (
                                                        <TableRow
                                                            style={{ backgroundColor: '#f3e8f5' }}
                                                            key={'row.name'}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {`${item?.areaType === "EXPORT" ? "E" : "T"}${item.invoiceNumber}/${item.financialYear}`}
                                                            </TableCell>
                                                            <TableCell>{moment(item.invoiceDate).format('DD/MM/YYYY')}</TableCell>
                                                            <TableCell
                                                            >
                                                                <Grid width={250} style={{
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    WebkitLineClamp: 2,
                                                                    overflow: 'hidden',
                                                                    width: item.billTo.length > 20 ? '250px' : '',
                                                                    textOverflow: 'ellipsis',
                                                                    wordWrap: 'break-word',
                                                                    wordBreak: 'break-word',
                                                                    lineHeight: '15px'
                                                                }}>
                                                                    <p className="description" style={{fontWeight:'400'}}>{item.billTo}</p>
                                                                </Grid>
                                                            </TableCell>
                                                            {/* <TableCell
                                                                // width={250}
                                                                style={{
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    WebkitLineClamp: 1,
                                                                    overflow: 'hidden',
                                                                    width: item.billTo.length > 20 ? '250px' : '',
                                                                    textOverflow: 'ellipsis',
                                                                    wordWrap: 'break-word',
                                                                    wordBreak: 'break-word',
                                                                    lineHeight: '16px'
                                                                }}
                                                            >{item.billTo}</TableCell> */}
                                                            <TableCell>{item.billFrom}</TableCell>
                                                            <TableCell>{item.areaType}</TableCell>
                                                            <TableCell>{item?.currency === 'USD to USD' ? '$' : '₹'}{item.amount}</TableCell>
                                                            <TableCell> <div className='table_actions'>
                                                                <Preview fontSize="small" style={{ margin: "5px" }} onClick={() => { PreViewInvoice(item._id) }} />
                                                                <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => { setInvoiceID(item._id); navigate(`/invoice/edit/${item._id}`, { state: { firmId: firmId } }) }} />
                                                                <Delete color='error' fontSize="small" style={{ margin: "5px" }}
                                                                    onClick={() => {
                                                                        setInvoiceID(item._id);
                                                                        setOpenModal(true)
                                                                    }}
                                                                />
                                                            </div></TableCell>

                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })
                    :
                    <NoDataElement />
                }
            </div> :
                <Tables rows={firmInvoiceData?.map((item: any, i: number) => { return { ...item, id: item._id ?? i + 1, createdAt: moment(item.createdAt).format('DD/MM/YYYY') } })} columns={columns} />
            }
        </>
    )
}

export default FirmInvoice