import { LOGIN, LOGIN_ERROR, LOGIN_LOADING } from "../type";


const loginData: any = {
    loginLoading: false,
    login: [],
    loginError: '',
}

export const loginReducer = (state = loginData, action: any) => {
    switch (action.type) {
        case LOGIN_LOADING:
            return {
                ...state,
                loginLoading: action.payload,
            };
        case LOGIN:
            return {
                ...state,
                login: action.payload,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload,
            };
        default:
            return state;
    }
}
