import React, { useState, useEffect } from "react";
import Button from "../../components/elements/Button";
import { useNavigate } from "react-router-dom";
import Table from "../../components/elements/Table";
import { GridColDef } from "@mui/x-data-grid";
import { Delete, Edit } from "@mui/icons-material";
import DeleteConfirmation from "../../components/modals/DeleteConfirmation";
import AssetsByMonth from "./AssetsByMonth";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAssetsAction,
  getAssetsAction,
} from "../../redux/actions/assetsAction";
import moment from "moment";
import { toast } from "react-toastify";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [employeesData, setemployeesData] = useState([]);
  const [assetsBy, setAssetsBy] = useState(false);
  const [clientId, setClientId] = useState<any>("");
  const [assetsData, setAssetsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const getAssetsData = useSelector((state: any) => state.getAssets.getAssets);
  const deleteAssetsData = useSelector(
    (state: any) => state.deleteAssets.deleteAssets
  );

  useEffect(() => {
    dispatch(getAssetsAction());
  }, []);

  useEffect(() => {
    if (deleteAssetsData?.status === 200) {
      toast.success(deleteAssetsData?.message);
      setClientId("");
      setOpenModal(false);
      dispatch({
        type: "DELETE_ASSETS",
        payload: null,
      });
    } else {
      toast.error(deleteAssetsData?.message);
    }
  }, [deleteAssetsData]);

  useEffect(() => {
    setAssetsData(
      getAssetsData && getAssetsData.data?.length ? getAssetsData.data : []
    );
  }, [getAssetsData]);

  const handleDelete = () => {
    if (clientId) {
      // console.log("clientId", clientId);
      dispatch(deleteAssetsAction(clientId));
    }
  };

  const handleEdit = (id:any) => {
    // console.log("id", id);
    navigate(`edit/${id}`);
  }

  const columns: GridColDef[] = [
    { field: "index", headerName: "No", width: 100 },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{moment(row.date).format("DD/MM/YYYY")}</>;
      },
    },
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "supplierName", headerName: "Supplier Name", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    {
      field: "isWorking",
      headerName: "Working",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.isWorking ? "Yes" : "No"}</>;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: ({ row }) => {
        return (
          <>
            <div className="table_actions">
              <Edit
                fontSize="small"
                style={{ margin: "5px" }}
                onClick={(e) => {handleEdit(row._id)}}
              />
              <Delete
                color="error"
                fontSize="small"
                style={{ margin: "5px" }}
                onClick={() => {
                  setClientId(row._id);
                  setOpenModal(true);
                }}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="">
      <div className="card_header">
        <h1 className="card_header_title">Assets</h1>
        <div className="card_footer">
          <Button
            onClick={() => navigate("add")}
            variant="outlined"
            buttonClass="contained_button_add"
            child="Add"
          />
          <Button
            onClick={() => setAssetsBy(!assetsBy)}
            variant="outlined"
            buttonClass="contained_button_add"
            child={`${!assetsBy ? "Assets by month" : "Assets by list"}`}
          />
        </div>
      </div>
      {assetsBy ? (
        <AssetsByMonth />
      ) : (
        <Table
          rows={assetsData?.map((item: any, i: number) => {
            return { ...item, id: item._id ?? i + 1, index: i + 1 };
          })}
          columns={columns}
        />
      )}
      {openModal && (
        <DeleteConfirmation
          open={openModal}
          title="assets"
          handleClose={(data: any) => setOpenModal(false)}
          handleConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default Index;
