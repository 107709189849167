import { ADD_EMPLOYEES, ADD_EMPLOYEES_ERROR, ADD_EMPLOYEES_LOADING, DELETE_EMPLOYEES, DELETE_EMPLOYEES_ERROR, DELETE_EMPLOYEES_LOADING, EDIT_EMPLOYEES, EDIT_EMPLOYEES_ERROR, EDIT_EMPLOYEES_LOADING, GET_ALL_EMPLOYEES, GET_ALL_EMPLOYEES_ERROR, GET_ALL_EMPLOYEES_LOADING, GET_BY_ID_EMPLOYEES, GET_BY_ID_EMPLOYEES_ERROR, GET_BY_ID_EMPLOYEES_LOADING } from "../type"

const getAllEmployeesData: any = {
    getAllEmployeesLoading: false,
    getAllEmployees: [],
    getAllEmployeesError: '',
}

const addEmployeesData: any = {
    addEmployeesLoading: false,
    addEmployees: null,
    addEmployeesError: '',
}

const deleteEmployeesData: any = {
    deleteEmployeesLoading: false,
    deleteEmployees: null,
    deleteEmployeesError: '',
}

const getByIdEmployeesData: any = {
    getByIdEmployeesLoading: false,
    getByIdEmployees: null,
    getByIdEmployeesError: '',
}

const editeEmployeesData: any = {
    editEmployeesLoading: false,
    editEmployees: null,
    editEmployeesError: '',
}

export const getAllEmployeesReducer = (state = getAllEmployeesData, action: any) => {
    switch (action.type) {
        case GET_ALL_EMPLOYEES_LOADING:
            return {
                ...state,
                getAllEmployeesLoading: action.payload,
            };
        case GET_ALL_EMPLOYEES:
            return {
                ...state,
                getAllEmployees: action.payload,
            };
        case GET_ALL_EMPLOYEES_ERROR:
            return {
                ...state,
                getAllEmployeesError: action.payload,
            };
        default:
            return state;
    }
}

export const addEmployeesReducer = (state = addEmployeesData, action: any) => {
    switch (action.type) {
        case ADD_EMPLOYEES_LOADING:
            return {
                ...state,
                addEmployeesLoading: action.payload,
            };
        case ADD_EMPLOYEES:
            return {
                ...state,
                addEmployees: action.payload,
            };
        case ADD_EMPLOYEES_ERROR:
            return {
                ...state,
                addEmployeesError: action.payload,
            };
        default:
            return state;
    }
}

export const deleteEmployeesReducer = (state = deleteEmployeesData, action: any) => {
    switch (action.type) {
        case DELETE_EMPLOYEES_LOADING:
            return {
                ...state,
                deleteEmployeesLoading: action.payload,
            };
        case DELETE_EMPLOYEES:
            return {
                ...state,
                deleteEmployees: action.payload,
            };
        case DELETE_EMPLOYEES_ERROR:
            return {
                ...state,
                deleteEmployeesError: action.payload,
            };
        default:
            return state;
    }
}

export const getByIdEmployeesReducer = (state = getByIdEmployeesData, action: any) => {
    switch (action.type) {
        case GET_BY_ID_EMPLOYEES_LOADING:
            return {
                ...state,
                getByIdEmployeesLoading: action.payload,
            };
        case GET_BY_ID_EMPLOYEES:
            return {
                ...state,
                getByIdEmployees: action.payload,
            };
        case GET_BY_ID_EMPLOYEES_ERROR:
            return {
                ...state,
                getByIdEmployeesError: action.payload,
            };
        default:
            return state;
    }
}

export const editEmployeesReducer = (state = editeEmployeesData, action: any) => {
    switch (action.type) {
        case EDIT_EMPLOYEES_LOADING:
            return {
                ...state,
                editEmployeesLoading: action.payload,
            };
        case EDIT_EMPLOYEES:
            return {
                ...state,
                editEmployees: action.payload,
            };
        case EDIT_EMPLOYEES_ERROR:
            return {
                ...state,
                editEmployeesError: action.payload,
            };
        default:
            return state;
    }
}