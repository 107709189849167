import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import { ADD_INVOICE, ADD_INVOICE_ERROR, ADD_INVOICE_LOADING, DELETE_INVOICE, DELETE_INVOICE_ERROR, DELETE_INVOICE_LOADING, EDIT_INVOICE, EDIT_INVOICE_ERROR, EDIT_INVOICE_LOADING, GET_ALL_INVOICE, GET_ALL_INVOICE_ERROR, GET_ALL_INVOICE_LOADING, GET_BY_FIRM_INVOICE, GET_BY_FIRM_INVOICE_ERROR, GET_BY_FIRM_INVOICE_LOADING, GET_INVOICE_BY_ID, GET_INVOICE_BY_ID_ERROR, GET_INVOICE_BY_ID_LOADING, IS_LOADING } from "../type";

export const getAllInvoiceAction: any = (type: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        dispatch({
            type: GET_ALL_INVOICE_LOADING,
            payload: true,
        });

        // await ApiGet(`enquiry?status=${status}`)
        await ApiGet('invoice')
            .then((res) => {
                dispatch({
                    type: GET_ALL_INVOICE,
                    payload: res,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch({
            type: GET_ALL_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_INVOICE_ERROR,
            payload: err,
        });

        dispatch({
            type: GET_ALL_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    }
};
export const getInvoiceByIdAction: any = (id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        dispatch({
            type: GET_INVOICE_BY_ID_LOADING,
            payload: true,
        });

        // await ApiGet(`enquiry?status=${status}`)
        await ApiGet(`invoice/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_INVOICE_BY_ID,
                    payload: res,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch({
            type: GET_INVOICE_BY_ID_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    } catch (err) {
        dispatch({
            type: GET_INVOICE_BY_ID_ERROR,
            payload: err,
        });

        dispatch({
            type: GET_INVOICE_BY_ID_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    }
};
export const getInvoiceByFirmIdAction: any = (id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        dispatch({
            type: GET_BY_FIRM_INVOICE_LOADING,
            payload: true,
        });

        // await ApiGet(`enquiry?status=${status}`)
        await ApiGet(`invoice/getByFirm/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_BY_FIRM_INVOICE,
                    payload: res,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch({
            type: GET_BY_FIRM_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    } catch (err) {
        dispatch({
            type: GET_BY_FIRM_INVOICE_ERROR,
            payload: err,
        });

        dispatch({
            type: GET_BY_FIRM_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    }
};

export const addInvoiceAction: any = (data: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        dispatch({
            type: ADD_INVOICE_LOADING,
            payload: true,
        });

        // await ApiGet(`enquiry?status=${status}`)
        await ApiPost('invoice/add',data)
            .then((res) => {
                dispatch({
                    type: ADD_INVOICE,
                    payload: res,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch({
            type: ADD_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    } catch (err) {
        dispatch({
            type: ADD_INVOICE_ERROR,
            payload: err,
        });

        dispatch({
            type: ADD_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    }
};

export const editInvoiceAction: any = (id:string,data: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        dispatch({
            type: EDIT_INVOICE_LOADING,
            payload: true,
        });

        // await ApiGet(`enquiry?status=${status}`)
        await ApiPost(`invoice/edit/${id}`,data)
            .then((res) => {
                dispatch({
                    type: EDIT_INVOICE,
                    payload: res,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch({
            type: EDIT_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    } catch (err) {
        dispatch({
            type: EDIT_INVOICE_ERROR,
            payload: err,
        });

        dispatch({
            type: EDIT_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    }
};

export const deleteInvoiceAction: any = (id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        dispatch({
            type: DELETE_INVOICE_LOADING,
            payload: true,
        });

        // await ApiGet(`enquiry?status=${status}`)
        await ApiGet(`invoice/delete/${id}`)
            .then((res: any) => {
                dispatch({
                    type: DELETE_INVOICE,
                    payload: res,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch({
            type: DELETE_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    } catch (err) {
        dispatch({
            type: DELETE_INVOICE_ERROR,
            payload: err,
        });

        dispatch({
            type: DELETE_INVOICE_LOADING,
            payload: false,
        });

        dispatch({
            type: IS_LOADING,
            payload: false,
        });
    }
};