import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../elements/Button';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Input from '../elements/Input';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Close from '../../assets/img/close.png'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addFirmAssetsAction, editFirmAssetsAction } from '../../redux/actions/firmAction';

const AssetsModal = ({ open, handleClose, assetsData }: any) => {

    const dispatch = useDispatch()
    const params = useParams();

    const id = params.id ?? localStorage.getItem("firmId")
    const assetsId = id
    const [fileData, setFileData] = useState<any>();

    const AddAssets = yup.object().shape({
        name: yup.string().required('Please enter name'),
        firmDocument: yup.string().required('Please enter file'),
    });

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(AddAssets),
    });

    const onSave = (data: any) => {
        const formData = new FormData();

        formData.append('firmDocument', fileData)
        delete data.firmDocument
        for (const key in data) {
            formData.append(`${key}`, data[key])
        }
        dispatch(addFirmAssetsAction(id, formData))
    }

    const onEdit = (data: any) => {
        const body = {
            id: assetsData._id,
            name: data.name
        }
        dispatch(editFirmAssetsAction(assetsId, body))
    }

    useEffect(() => {
        reset({
            name: assetsData.name,
            firmDocument: assetsData.firmDocument?.split("/firmDocument/").pop()
        })
    }, [assetsData])


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='modal_header' >
                {assetsData?._id ? "Edit" : "Add"} assets
                <img src={Close} alt='' onClick={handleClose} />
            </DialogTitle>
            <form>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                                <Input
                                    label='Name'
                                    placeholder="File name"
                                    type='text'
                                    register={register("name")}
                                    error={errors?.name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                                {!assetsData?._id ? <Input
                                    label='Select file'
                                    placeholder=""
                                    type='file'
                                    register={register("firmDocument", {
                                        onChange: (e: any) => {
                                            setFileData(e.target.files[0])
                                        }
                                    })}
                                    error={errors?.firmDocument?.message}
                                /> :
                                    <Input
                                        label='Select file'
                                        placeholder="File name"
                                        disabled={true}
                                        type='text'
                                        register={register("firmDocument")}
                                        error={errors?.firmDocument?.message}
                                    />}
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        reset({
                            name: "",
                            firmDocument: ""
                        })
                        handleClose()
                    }} child='Close' buttonClass='contained_button_cancel' />
                    <Button
                        type='submit'
                        onClick={handleSubmit((data) => {
                            {
                                assetsData?._id ?
                                onEdit(data)
                                :
                                onSave(data)
                            }
                            reset({
                                name: "",
                                firmDocument: ""
                            })
                            handleClose()
                        })}
                        child='Save'
                        buttonClass='contained_button_add'
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AssetsModal