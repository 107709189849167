import React, { useEffect, useState } from 'react';
import Button from '../../components/elements/Button';
import Grid from '@mui/material/Grid';
import Input from '../../components/elements/Input';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { addEmployeesAction, editEmployeesAction, getByIdEmployeesAction } from '../../redux/actions/employeesAction';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import { ADD_EMPLOYEES, EDIT_EMPLOYEES, GET_BY_ID_EMPLOYEES } from '../../redux/type';
import dayjs, { Dayjs } from 'dayjs';

const EmployeesManagement = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [fileData, setFileData] = useState<any>()
    const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(dayjs(new Date()));
    const [dateOfJoin, setDateOfJoin] = useState<Dayjs | null>(dayjs(new Date()));
    const [accountType, setAccountType] = useState<string>();
    const [technology, setTechnology] = useState<string>();
    const getByIdemployeesData = useSelector((state: any) => state.getByIdEmployees.getByIdEmployees?.data)
    const addEmployees = useSelector((state: any) => state.addEmployees.addEmployees)
    const editEmployees = useSelector((state: any) => state.editEmployees.editEmployees)

    useEffect(() => {
        return (() => {
            dispatch({
                type: GET_BY_ID_EMPLOYEES,
                payload: '',
            });
        })
    }, [])

    const employeesSchema = yup.object().shape({
        name: yup.string().required('Please enter name'),
    });

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(employeesSchema),
    });

    useEffect(() => {
        reset(
            {
                name: getByIdemployeesData?.name,
                number: getByIdemployeesData?.number,
                alternativeNumber: getByIdemployeesData?.alternativeNumber,
                dateOfBirth: moment(getByIdemployeesData?.dateOfBirth).format('DD/MM/YYYY'),
                address: getByIdemployeesData?.address,
                email: getByIdemployeesData?.email,
                technology: getByIdemployeesData?.technology,
                dateOfJoin: moment(getByIdemployeesData?.dateOfJoin).format('DD/MM/YYYY'),
                salary: getByIdemployeesData?.salary,
                employeesDocument: getByIdemployeesData?.employeesDocument ?? null,
                bankName: getByIdemployeesData?.bankName,
                accountNumber: getByIdemployeesData?.accountNumber,
                accountHolderName: getByIdemployeesData?.accountHolderName,
                IFSCCode: getByIdemployeesData?.IFSCCode,
                swift: getByIdemployeesData?.swift,
                accountType: getByIdemployeesData?.accountType,
            }
        );

        setDateOfJoin(dayjs(getByIdemployeesData?.dateOfJoin));
        setDateOfBirth(dayjs(getByIdemployeesData?.dateOfBirth));
        setAccountType(getByIdemployeesData?.accountType)
        setTechnology(getByIdemployeesData?.technology)
    }, [getByIdemployeesData])

    const oncancel = () => {
        navigate('/employees')
    }

    useEffect(() => {
        if (params.id) {
            dispatch(getByIdEmployeesAction(params.id))
        }
    }, [params.id])

    useEffect(() => {
        if (addEmployees) {
            if (addEmployees.status === 200) {
                toast.success(addEmployees.message)
                oncancel()
            } else {
                toast.error(addEmployees.message)
            }
            dispatch({
                type: ADD_EMPLOYEES,
                payload: ''
            })
        }
    }, [addEmployees])

    useEffect(() => {
        if (editEmployees) {
            if (editEmployees.status === 200) {
                toast.success(editEmployees.message)
                oncancel()
            } else {
                toast.error(editEmployees.message)
            }
            dispatch({
                type: EDIT_EMPLOYEES,
                payload: '',
            });
        }
    }, [editEmployees])


    const onSave = (data: any) => {
        const formData = new FormData();

        formData.append('employeesDocument', fileData)
        delete data.employeesDocument
        for (const key in data) {
            formData.append(`${key}`, data[key])
        }
        dispatch(addEmployeesAction(formData))
    }

    const onSelectDateOfBirth = (e: any) => {
        let date = moment(new Date(e)).format('DD/MM/YYYY')
        setDateOfBirth(e)
        setValue('dateOfBirth', date)
    }

    const onSelectDateOfJoin = (e: any) => {
        let date = moment(new Date(e)).format('DD/MM/YYYY')
        setDateOfJoin(e)
        setValue('dateOfJoin', date)
    }

    const onEdit = (data: any) => {
        const formData = new FormData();
        delete data.employeesDocument
        if (fileData) {
            formData.append('employeesDocument', fileData)
        }
        for (const key in data) {
            formData.append(`${key}`, data[key])
        }

        dispatch(editEmployeesAction(params.id, formData))
    }

    return (
        <div className=''>
            <div className='card_header'>
                <h1 className='card_header_title'>Add employees</h1>
                <div className='card_footer'>
                    <Button
                        variant="outlined"
                        buttonClass='contained_button_cancel'
                        onClick={() => oncancel()}
                        child='Cancel' />
                    <Button
                        onClick={handleSubmit((data) => {
                            if (params.id)
                                onEdit(data)
                            else
                                onSave(data)
                        })}
                        variant="outlined"
                        buttonClass='contained_button_add'
                        child={`${params.id ? 'Save' : 'Add'}`} />
                </div>
            </div>
            <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Name'
                        placeholder="Name"
                        type='text'
                        register={register("name")}
                        error={errors?.name?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Contact number'
                        placeholder="Name"
                        type='number'
                        register={register("number")}
                        error={errors?.number?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Alternative number'
                        placeholder="Name"
                        type='number'
                        register={register("alternativeNumber")}
                        error={errors?.alternativeNumber?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        DOB
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            // defaultValue={new Date()}
                            {...register('dateOfBirth')}
                            value={dateOfBirth}
                            onChange={onSelectDateOfBirth}
                            sx={{ width: '100%' }}
                            format="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Address'
                        placeholder="Address"
                        register={register("address")}
                        error={errors?.address?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Personal Email'
                        placeholder="personal email"
                        type='email'
                        register={register("email")}
                        error={errors?.email?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        Department
                    </InputLabel>
                    <FormControl sx={{ minWidth: '100%' }}>
                        <Select
                            size="small"
                            {...register('technology')}
                            value={technology || ''}
                            onChange={(e) => setTechnology(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"web"}>Web</MenuItem>
                            <MenuItem value={"android"}>Android</MenuItem>
                            <MenuItem value={"ios"}>IOS</MenuItem>
                            <MenuItem value={"ui-ux"}>UI/UX</MenuItem>
                        </Select>
                    </FormControl>
                    <span className='input_error' >{errors?.technology?.message?.toString()}</span>
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        Joining date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            {...register('dateOfJoin')}
                            value={dateOfJoin}
                            onChange={onSelectDateOfJoin}
                            sx={{ width: '100%' }}
                            format="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Salary'
                        placeholder="salary"
                        type='number'
                        register={register("salary")}
                        error={errors?.salary?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Document'
                        placeholder=""
                        type='file'
                        register={register("employeesDocument", {
                            onChange: (e) => { setFileData(e.target.files[0]) }
                        })}
                        error={errors?.employeesDocument?.message}
                    />
                </Grid>

                <Grid item xs={12} lg={12} xl={12} md={12} sm={12} >
                    <div className='card_header'>
                        <h1 className='card_header_title'>Bank Detail</h1>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                            <Input
                                label='Bank name'
                                placeholder="Bank name"
                                type='text'
                                register={register("bankName")}
                                error={errors?.bankName?.message}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                            <Input
                                label='A/C No'
                                placeholder="A/C no"
                                type='text'
                                register={register("accountNumber")}
                                error={errors?.accountNumber?.message}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4} xl={3} md={6} sm={12} >
                            <Input
                                label='A/C Name'
                                placeholder="A/C name"
                                register={register("accountHolderName")}
                                error={errors?.accountHolderName?.message}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4} xl={3} md={6} sm={12} >
                            <Input
                                label='IFSC'
                                placeholder="IFSC"
                                register={register("IFSCCode")}
                                error={errors?.IFSCCode?.message}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4} xl={3} md={6} sm={12} >
                            <Input
                                label='Swift'
                                placeholder="Swift"
                                register={register("swift")}
                                error={errors?.swift?.message}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                            <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                                A/C type
                            </InputLabel>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <Select
                                    size="small"
                                    value={accountType || ""}
                                    {...register('accountType')}
                                    onChange={(e) => setAccountType(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'current'}>Current</MenuItem>
                                    <MenuItem value={'saving'}>Saving</MenuItem>
                                </Select>
                            </FormControl>
                            <span className='input_error' >{errors?.accountType?.message?.toString()}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default EmployeesManagement