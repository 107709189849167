import {
  GET_EXPENSES,
  GET_EXPENSES_LOADING,
  GET_EXPENSES_ERROR,
  GET_BY_ID_EXPENSES,
  GET_BY_ID_EXPENSES_ERROR,
  GET_BY_ID_EXPENSES_LOADING,
  ADD_EXPENSES,
  ADD_EXPENSES_ERROR,
  ADD_EXPENSES_LOADING,
  EDIT_EXPENSES,
  EDIT_EXPENSES_ERROR,
  EDIT_EXPENSES_LOADING,
  DELETE_EXPENSES,
  DELETE_EXPENSES_ERROR,
  DELETE_EXPENSES_LOADING,
  GET_BY_MONTH_EXPENSES_LOADING,
  GET_BY_MONTH_EXPENSES_ERROR,
  GET_BY_MONTH_EXPENSES,
  IS_LOADING,
} from "../type";

const getExpensesData: any = {
  getExpensesLoading: false,
  getExpenses: [],
  getExpensesError: false,
};

const getBYIdExpensesData : any =  {
  getBYIdExpensesLoading: false,
  getBYIdExpenses: null,
  getBYIdExpensesError: false,
}

const getByMonthExpensesData: any = {
  getByMonthExpensesLoading: false,
  getByMonthExpenses: [],
  getByMonthExpensesError: false,
};

const addExpensesData : any = {
  addExpensesLoading: false,
  addExpenses:null,
  addExpensesError: "",
}

const editExpensesData:any = {
  editExpensesLoading: false,
  editExpenses: null,
  editExpensesError: "",
}

const deleteExpensesData : any = {
  deleteExpensesLoading: false,
  deleteExpenses: null,
  deleteExpensesError: "",
}

export const getExpensesReducer = (state = getExpensesData, action: any) => {
  switch (action.type) {
    case GET_EXPENSES_LOADING:
      return {
        ...state,
        getExpensesLoading: action.payload,
      };
    case GET_EXPENSES_ERROR:
      return {
        ...state,
        getExpensesError: action.payload,
      };
    case GET_EXPENSES:
      return {
        ...state,
        getExpenses: action.payload,
      };
    default:
      return state;
  }
};

export const getByIdExpensesReducer = (state = getBYIdExpensesData , action : any) => {
  switch (action.type) {
    case GET_BY_ID_EXPENSES_LOADING:
      return {
        ...state,
        getBYIdExpensesLoading: action.payload,
      }
    case GET_BY_ID_EXPENSES:
      return {
        ...state,
        getBYIdExpenses: action.payload,
      }
    case GET_BY_ID_EXPENSES_ERROR:
      return {
        ...state,
        getBYIdExpensesError: action.payload,
      }
    default:
      return state;
  }
}

export const getByMonthExpensesReducer = (state = getByMonthExpensesData, action: any) => {
    switch (action.type) {
      case GET_BY_MONTH_EXPENSES_LOADING:
        return {
          ...state,
          getByMonthExpensesLoading: action.payload,
        };
      case GET_BY_MONTH_EXPENSES_ERROR:
        return {
          ...state,
          getByMonthExpensesError: action.payload,
        };
      case GET_BY_MONTH_EXPENSES:
        return {
          ...state,
          getByMonthExpenses: action.payload,
        };
      default:
        return state;
    }
  };

export const addExpensesReducer = (state=addExpensesData,action:any) => {
  switch (action.type) {
    case ADD_EXPENSES_LOADING:
      return {
        ...state,
        addExpensesLoading: action.payload,
      }
    case ADD_EXPENSES:
      return {
        ...state,
        addExpenses: action.payload,
      }
    case ADD_EXPENSES_ERROR:
      return {
        ...state,
        addExpensesError: action.payload,
      }
    default:
      return state;
  }
}
export const editExpensesReducer = (state=editExpensesData,action:any) => {
  switch (action.type) {
    case EDIT_EXPENSES_LOADING:
      return {
        ...state,
        editExpensesLoading: action.payload,
      }
    case EDIT_EXPENSES:
      return {
        ...state,
        editExpenses: action.payload,
      }
    case EDIT_EXPENSES_ERROR:
      return {
        ...state,
        editExpensesError: action.payload,
      }
    default:
      return state;
  }
}
export const deleteExpensesReducer = (state=deleteExpensesData,action:any) => {
  switch (action.type) {
    case DELETE_EXPENSES_LOADING:
      return {
        ...state,
        deleteExpensesLoading: action.payload,
      }
    case DELETE_EXPENSES:
      return {
        ...state,
        deleteExpenses: action.payload,
      }
    case DELETE_EXPENSES_ERROR:
      return {
        ...state,
        deleteExpensesError: action.payload,
      }
    default:
      return state;
  }
}

