import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import {
  GET_ASSETS,
  GET_ASSETS_ERROR,
  GET_ASSETS_LOADING,
  GET_BY_ID_ASSETS,
  GET_BY_ID_ASSETS_LOADING,
  GET_BY_ID_ASSETS_ERROR,
  ADD_ASSETS,
  ADD_ASSETS_ERROR,
  ADD_ASSETS_LOADING,
  EDIT_ASSETS,
  EDIT_ASSETS_ERROR,
  EDIT_ASSETS_LOADING,
  DELETE_ASSETS,
  DELETE_ASSETS_ERROR,
  DELETE_ASSETS_LOADING,
  GET_BY_MONTH_ASSETS,
  GET_BY_MONTH_ASSETS_ERROR,
  GET_BY_MONTH_ASSETS_LOADING,
  IS_LOADING,
} from "../type";

export const getAssetsAction: any = (type: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: GET_ASSETS_LOADING,
      payload: true,
    });
    await ApiGet(`assets`)
      .then((res) => {
        dispatch({
          type: GET_ASSETS,
          payload: res,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });

    dispatch({
      type: GET_ASSETS_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: GET_ASSETS_ERROR,
      payload: error,
    });
    dispatch({
      type: GET_ASSETS_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  }
};

export const getByIdAssetsAction: any = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: GET_BY_ID_ASSETS_LOADING,
      payload: true,
    });
    await ApiGet(`assets/${id}`)
      .then((res) => {
        dispatch({
          type: GET_BY_ID_ASSETS,
          payload: res,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
    dispatch({
      type: GET_BY_ID_ASSETS_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: GET_ASSETS_ERROR,
      payload: error,
    });
    dispatch({
      type: GET_ASSETS_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  }
};

export const getByMonthAssetsAction: any =
  (type: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: IS_LOADING,
        payload: true,
      });
      dispatch({
        type: GET_ASSETS_LOADING,
        payload: true,
      });
      await ApiGet(`assets/by-months`)
        .then((res) => {
          dispatch({
            type: GET_BY_MONTH_ASSETS,
            payload: res,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
      dispatch({
        type: GET_ASSETS_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: GET_BY_MONTH_ASSETS_ERROR,
        payload: error,
      });
      dispatch({
        type: GET_BY_MONTH_ASSETS_LOADING,
        payload: false,
      });
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
    }
  };

export const addAssetsAction: any = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: ADD_ASSETS_LOADING,
      payload: true,
    });
    await ApiPost(`assets/add`, data)
      .then((res) => {
        dispatch({
          type: ADD_ASSETS,
          payload: res,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    dispatch({
      type: ADD_ASSETS_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: ADD_ASSETS_ERROR,
      payload: error,
    });
    dispatch({
      type: ADD_ASSETS_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  }
};

export const editAssetsAction: any =
  (id: any, body: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: IS_LOADING,
        payload: true,
      });
      dispatch({
        type: EDIT_ASSETS_LOADING,
        payload: true,
      });
      await ApiPost(`assets/edit/${id}`, body)
        .then((res) => {
          dispatch({
            type: EDIT_ASSETS,
            payload: res,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
      dispatch({
        type: EDIT_ASSETS_LOADING,
        payload: false,
      });
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: EDIT_ASSETS_ERROR,
        payload: error,
      });
      dispatch({
        type: EDIT_ASSETS_LOADING,
        payload: false,
      });
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
    }
  };

export const deleteAssetsAction: any = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: DELETE_ASSETS_LOADING,
      payload: true,
    });
    await ApiPost(`assets/delete/${id}`, {})
      .then((res: any) => {
        dispatch({
          type: DELETE_ASSETS,
          payload: res,
        });
        if (res.status === 200) {
          dispatch(getAssetsAction());
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    dispatch({
      type: DELETE_ASSETS_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ASSETS_ERROR,
      payload: error,
    });
    dispatch({
      type: DELETE_ASSETS_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  }
};
