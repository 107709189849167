export const IS_LOADING = "IS_LOADING";

//Login
export const LOGIN = "LOGIN"
export const LOGIN_LOADING = "LOGIN_LOADING"
export const LOGIN_ERROR = "LOGIN_ERROR"
//Firm get All
export const GET_ALL_FIRM = "GET_ALL_PROJECT"
export const GET_ALL_FIRM_LOADING = "GET_ALL_PROJECT_LOADING"
export const GET_ALL_FIRM_ERROR = "GET_ALL_PROJECT_ERROR"
//Add Firm 
export const ADD_FIRM = "ADD_FIRM"
export const ADD_FIRM_LOADING = "ADD_FIRM_LOADING"
export const ADD_FIRM_ERROR = "ADD_FIRM_ERROR"
//Delete Firm 
export const DELETE_FIRM = "DELETE_FIRM"
export const DELETE_FIRM_LOADING = "DELETE_FIRM_LOADING"
export const DELETE_FIRM_ERROR = "DELETE_FIRM_ERROR"
//Get by id Firm 
export const GET_BY_ID_FIRM = "GET_BY_ID_FIRM"
export const GET_BY_ID_FIRM_LOADING = "GET_BY_ID_FIRM_LOADING"
export const GET_BY_ID_FIRM_ERROR = "GET_BY_ID_FIRM_ERROR"
//Edit Firm 
export const EDIT_FIRM = "EDIT_FIRM"
export const EDIT_FIRM_LOADING = "EDIT_FIRM_LOADING"
export const EDIT_FIRM_ERROR = "EDIT_FIRM_ERROR"
//Get All Clients 
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS"
export const GET_ALL_CLIENTS_LOADING = "GET_ALL_CLIENTS_LOADING"
export const GET_ALL_CLIENTS_ERROR = "GET_ALL_CLIENTS_ERROR"
//Add Clients 
export const ADD_CLIENTS = "ADD_CLIENTS"
export const ADD_CLIENTS_LOADING = "ADD_CLIENTS_LOADING"
export const ADD_CLIENTS_ERROR = "ADD_CLIENTS_ERROR"
//Get by id Clients
export const GET_BY_ID_CLIENTS = "GET_BY_ID_CLIENTS"
export const GET_BY_ID_CLIENTS_LOADING = "GET_BY_ID_CLIENTS_LOADING"
export const GET_BY_ID_CLIENTS_ERROR = "GET_BY_ID_CLIENTS_ERROR"
//Edit Clients 
export const EDIT_CLIENTS = "EDIT_CLIENTS"
export const EDIT_CLIENTS_LOADING = "EDIT_CLIENTS_LOADING"
export const EDIT_CLIENTS_ERROR = "EDIT_CLIENTS_ERROR"
//Delete Clients 
export const DELETE_CLIENTS = "DELETE_CLIENTS"
export const DELETE_CLIENTS_LOADING = "DELETE_CLIENTS_LOADING"
export const DELETE_CLIENTS_ERROR = "DELETE_CLIENTS_ERROR"
//get All employees
export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES"
export const GET_ALL_EMPLOYEES_LOADING = "GET_ALL_EMPLOYEES_LOADING"
export const GET_ALL_EMPLOYEES_ERROR = "GET_ALL_EMPLOYEES_ERROR"
//Add EMPLOYEES 
export const ADD_EMPLOYEES = "ADD_EMPLOYEES"
export const ADD_EMPLOYEES_LOADING = "ADD_EMPLOYEES_LOADING"
export const ADD_EMPLOYEES_ERROR = "ADD_EMPLOYEES_ERROR"
//Delete EMPLOYEES 
export const DELETE_EMPLOYEES = "DELETE_EMPLOYEES"
export const DELETE_EMPLOYEES_LOADING = "DELETE_EMPLOYEES_LOADING"
export const DELETE_EMPLOYEES_ERROR = "DELETE_EMPLOYEES_ERROR"
//Get by id EMPLOYEES 
export const GET_BY_ID_EMPLOYEES = "GET_BY_ID_EMPLOYEES"
export const GET_BY_ID_EMPLOYEES_LOADING = "GET_BY_ID_EMPLOYEES_LOADING"
export const GET_BY_ID_EMPLOYEES_ERROR = "GET_BY_ID_EMPLOYEES_ERROR"
//Edit EMPLOYEES 
export const EDIT_EMPLOYEES = "EDIT_EMPLOYEES"
export const EDIT_EMPLOYEES_LOADING = "EDIT_EMPLOYEES_LOADING"
export const EDIT_EMPLOYEES_ERROR = "EDIT_EMPLOYEES_ERROR"
//get All Invoice
export const GET_ALL_INVOICE = "GET_ALL_INVOICE"
export const GET_ALL_INVOICE_LOADING = "GET_ALL_INVOICE_LOADING"
export const GET_ALL_INVOICE_ERROR = "GET_ALL_INVOICE_ERROR"
//get Invoice By Id
export const GET_INVOICE_BY_ID = "GET_INVOICE_BY_ID"
export const GET_INVOICE_BY_ID_LOADING = "GET_INVOICE_BY_ID_LOADING"
export const GET_INVOICE_BY_ID_ERROR = "GET_INVOICE_BY_ID_ERROR"
//add Invoice
export const ADD_INVOICE = "ADD_INVOICE"
export const ADD_INVOICE_LOADING = "ADD_INVOICE_LOADING"
export const ADD_INVOICE_ERROR = "ADD_INVOICE_ERROR"
//edit Invoice
export const EDIT_INVOICE = "EDIT_INVOICE"
export const EDIT_INVOICE_LOADING = "EDIT_INVOICE_LOADING"
export const EDIT_INVOICE_ERROR = "EDIT_INVOICE_ERROR"
//delete Invoice
export const DELETE_INVOICE = "DELETE_INVOICE"
export const DELETE_INVOICE_LOADING = "DELETE_INVOICE_LOADING"
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR"
//get By firm Invoice
export const GET_BY_FIRM_INVOICE = "GET_BY_FIRM_INVOICE"
export const GET_BY_FIRM_INVOICE_LOADING = "GET_BY_FIRM_INVOICE_LOADING"
export const GET_BY_FIRM_INVOICE_ERROR = "GET_BY_FIRM_INVOICE_ERROR"
//get Assets 
export const GET_ASSETS = "GET_ASSETS"
export const GET_ASSETS_LOADING = "GET_ASSETS_LOADING"
export const GET_ASSETS_ERROR = "GET_ASSETS_ERROR"
//get by id  Assets 
export const GET_BY_ID_ASSETS = "GET_BY_ID_ASSETS"
export const GET_BY_ID_ASSETS_LOADING = "GET_BY_ID_ASSETS_LOADING"
export const GET_BY_ID_ASSETS_ERROR = "GET_BY_ID_ASSETS_ERROR"
//get by month assets
export const GET_BY_MONTH_ASSETS = "GET_BY_MONTH_ASSETS"
export const GET_BY_MONTH_ASSETS_LOADING = "GET_BY_MONTH_ASSETS_LOADING"
export const GET_BY_MONTH_ASSETS_ERROR = "GET_BY_MONTH_ASSETS_ERROR"
//add Assets 
export const ADD_ASSETS = "ADD_ASSETS"
export const ADD_ASSETS_LOADING = "ADD_ASSETS_LOADING"
export const ADD_ASSETS_ERROR = "ADD_ASSETS_ERROR"
//edit Assets 
export const EDIT_ASSETS = "EDIT_ASSETS"
export const EDIT_ASSETS_LOADING = "EDIT_ASSETS_LOADING"
export const EDIT_ASSETS_ERROR = "EDIT_ASSETS_ERROR"
//delete Assets 
export const DELETE_ASSETS = "DELETE_ASSETS"
export const DELETE_ASSETS_LOADING = "DELETE_ASSETS_LOADING"
export const DELETE_ASSETS_ERROR = "DELETE_ASSETS_ERROR"
//get By Firm Assets 
export const GET_BY_FIRM_ASSETS = "GET_BY_FIRM_ASSETS"
export const GET_BY_FIRM_ASSETS_LOADING = "GET_BY_FIRM_ASSETS_LOADING"
export const GET_BY_FIRM_ASSETS_ERROR = "GET_BY_FIRM_ASSETS_ERROR"
//add Firm Assets 
export const ADD_FIRM_ASSETS = "ADD_FIRM_ASSETS"
export const ADD_FIRM_ASSETS_LOADING = "ADD_FIRM_ASSETS_LOADING"
export const ADD_FIRM_ASSETS_ERROR = "ADD_FIRM_ASSETS_ERROR"
//delete Firm Assets
export const DELETE_FIRM_ASSETS = "DELETE_FIRM_ASSETS"
export const DELETE_FIRM_ASSETS_LOADING = "DELETE_FIRM_ASSETS_LOADING"
export const DELETE_FIRM_ASSETS_ERROR = "DELETE_FIRM_ASSETS_ERROR"
//edit Firm Assets 
export const EDIT_FIRM_ASSETS = "EDIT_FIRM_ASSETS"
export const EDIT_FIRM_ASSETS_LOADING = "EDIT_FIRM_ASSETS_LOADING"
export const EDIT_FIRM_ASSETS_ERROR = "EDIT_FIRM_ASSETS_ERROR"
//get By Firm Banks 
export const GET_BY_FIRM_BANKS = "GET_BY_FIRM_BANKS"
export const GET_BY_FIRM_BANKS_LOADING = "GET_BY_FIRM_BANKS_LOADING"
export const GET_BY_FIRM_BANKS_ERROR = "GET_BY_FIRM_BANKS_ERROR"
//add Firm Banks 
export const ADD_FIRM_BANKS = "ADD_FIRM_BANKS"
export const ADD_FIRM_BANKS_LOADING = "ADD_FIRM_BANKS_LOADING"
export const ADD_FIRM_BANKS_ERROR = "ADD_FIRM_BANKS_ERROR"
//delete Firm Banks
export const DELETE_FIRM_BANKS = "DELETE_FIRM_BANKS"
export const DELETE_FIRM_BANKS_LOADING = "DELETE_FIRM_BANKS_LOADING"
export const DELETE_FIRM_BANKS_ERROR = "DELETE_FIRM_BANKS_ERROR"
//edit Firm Banks 
export const EDIT_FIRM_BANKS = "EDIT_FIRM_BANKS"
export const EDIT_FIRM_BANKS_LOADING = "EDIT_FIRM_BANKS_LOADING"
export const EDIT_FIRM_BANKS_ERROR = "EDIT_FIRM_BANKS_ERROR"
//get expenses
export const GET_EXPENSES = "GET_EXPENSES"
export const GET_EXPENSES_LOADING = "GET_EXPENSES_LOADING"
export const GET_EXPENSES_ERROR = "GET_EXPENSES_ERROR"
//get by id expenses
export const GET_BY_ID_EXPENSES = "GET_BY_ID_EXPENSES"
export const GET_BY_ID_EXPENSES_LOADING = "GET_BY_ID_EXPENSES_LOADING"
export const GET_BY_ID_EXPENSES_ERROR = "GET_BY_ID_EXPENSES_ERROR"
//get expenses by month
export const GET_BY_MONTH_EXPENSES = "GET_BY_MONTH_EXPENSES"
export const GET_BY_MONTH_EXPENSES_LOADING = "GET_BY_MONTH_EXPENSES_LOADING"
export const GET_BY_MONTH_EXPENSES_ERROR = "GET_BY_MONTH_EXPENSES_ERROR"
//add expenses
export const ADD_EXPENSES = "ADD_EXPENSES"
export const ADD_EXPENSES_LOADING = "ADD_EXPENSES_LOADING"
export const ADD_EXPENSES_ERROR = "ADD_EXPENSES_ERROR"
//update expenses
export const EDIT_EXPENSES = "EDIT_EXPENSES"
export const EDIT_EXPENSES_LOADING = "EDIT_EXPENSES_LOADING"
export const EDIT_EXPENSES_ERROR = "EDIT_EXPENSES_ERROR"
//delete expenses
export const DELETE_EXPENSES = "DELETE_EXPENSES"
export const DELETE_EXPENSES_LOADING = "DELETE_EXPENSES_LOADING"
export const DELETE_EXPENSES_ERROR = "DELETE_EXPENSES_ERROR"