import React, { useEffect, useState } from "react";
import Button from "../../components/elements/Button";
import Grid from "@mui/material/Grid";
import Input from "../../components/elements/Input";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel, FormControlLabel, Checkbox } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssetsAction,
  editAssetsAction,
  getByIdAssetsAction,
} from "../../redux/actions/assetsAction";
import { toast } from "react-toastify";
import { ADD_ASSETS, EDIT_ASSETS, GET_BY_ID_ASSETS } from "../../redux/type";
import dayjs, { Dayjs } from "dayjs";

const AssetsManagement = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [category, setCategory] = useState<string>();
  const [isWorking, setIsWorking] = useState<boolean>(true);
  const [recoveryTypes, setRecoveryTypes] = useState<string>();
  const [fileData, setFileData] = useState<any>();
  const addAssetsData = useSelector((state: any) => state.addAssets.addAssets);
  const getByIdAssetsData = useSelector(
    (state: any) => state.getByIdAssets.getByIdAssets
  );
  const editAssetsData = useSelector(
    (state: any) => state.editAssets.editAssets
  );

  const asstesSchema = yup.object().shape({
    productName: yup.string().required("Please enter product name"),
    period: yup.string(),
    date: yup.string(),
    amount: yup.string(),
    note: yup.string(),
    recoveryType: yup.string(),
    category: yup.string(),
    supplierNumber: yup.string(),
    supplierName: yup.string(),
    isWorking: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(asstesSchema),
  });

  const onSelectDateOfBirth = (e: any) => {
    let date = moment(new Date(e)).format("DD/MM/YYYY");
    setDate(e);
    setValue("date", date);
  };

  useEffect(() => {
    if (getByIdAssetsData && getByIdAssetsData.status === 200) {
      if (getByIdAssetsData.data) {
        setCategory(getByIdAssetsData.data?.category)
        setRecoveryTypes(getByIdAssetsData.data?.recoveryType)
        setIsWorking(getByIdAssetsData.data?.isWorking)
        setDate(dayjs(getByIdAssetsData.data?.date))
        reset({
          date: moment(getByIdAssetsData.data?.date).format("DD/MM/YYYY"),
          amount: getByIdAssetsData.data?.amount,
          note: getByIdAssetsData.data?.note,
          productName: getByIdAssetsData.data?.productName,
          period: getByIdAssetsData.data?.period,
          supplierNumber: getByIdAssetsData.data?.supplierNumber,
          supplierName: getByIdAssetsData.data?.supplierName,
          category: getByIdAssetsData.data?.category,
          recoveryType: getByIdAssetsData.data?.recoveryType,
          isWorking: getByIdAssetsData.data?.isWorking,
        })
      }
    }
  }, [getByIdAssetsData])

  useEffect(() => {
    if (addAssetsData && addAssetsData.status === 200) {
      toast.success(addAssetsData?.message);
      navigate("/assets");
    } else {
      toast.error(addAssetsData?.message);
    }
    dispatch({
      type: ADD_ASSETS,
      payload: null,
    });
  }, [addAssetsData]);

  useEffect(() => {
    if (editAssetsData && editAssetsData.status === 200) {
      toast.success(editAssetsData?.message);
      navigate("/assets");
    } else {
      toast.error(editAssetsData?.message);
    }
    dispatch({
      type: EDIT_ASSETS,
      payload: "",
    });
  }, [editAssetsData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: EDIT_ASSETS,
        payload: "",
      });
      dispatch({
        type: ADD_ASSETS,
        payload: "",
      });
      dispatch({
        type: GET_BY_ID_ASSETS,
        payload: "",
      });
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(getByIdAssetsAction(params.id));
    }
  }, [params.id]);

  const onSave = (data: any) => {
    const formData = new FormData();

    if (fileData) formData.append('assteDocument', fileData)
    delete data.assteDocument
    for (const key in data) {
      formData.append(`${key}`, data[key])
    }
    dispatch(addAssetsAction(formData));
  };
  const onEdit = (data: any) => {
    const formData = new FormData();

    if (fileData) formData.append('assteDocument', fileData)
    delete data.assteDocument
    for (const key in data) {
      formData.append(`${key}`, data[key])
    }
    dispatch(editAssetsAction(params.id, formData));
  };

  return (
    <div className="">
      <div className="card_header">
        <h1 className="card_header_title">
          {params.id ? "Edit" : "Add"} assets
        </h1>
        <div className="card_footer">
          <Button
            variant="outlined"
            buttonClass="contained_button_cancel"
            onClick={() => navigate("/assets")}
            child="Cancel"
          />
          <Button
            onClick={handleSubmit((data) => {
              // console.log("data", data);
              if (params.id) onEdit(data);
              else onSave(data);
            })}
            variant="outlined"
            buttonClass="contained_button_add"
            child={`${params.id ? "Save" : "Add"}`}
          />
        </div>
      </div>
      <Grid container spacing={2} style={{ marginBottom: "16px" }}>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Date
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register("date")}
              value={date}
              onChange={onSelectDateOfBirth}
              sx={{ width: "100%" }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Product name"
            placeholder="Product name"
            type="text"
            register={register("productName")}
            error={errors?.productName?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Amount"
            placeholder="amount"
            type="number"
            register={register("amount")}
            error={errors?.amount?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Note"
            placeholder="note"
            type="text"
            register={register("note")}
            error={errors?.note?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Recovery types
          </InputLabel>
          <FormControl sx={{ minWidth: "100%" }}>
            <Select
              size="small"
              {...register("recoveryType")}
              value={recoveryTypes || ""}
              onChange={(e) => setRecoveryTypes(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Guarantee"}>Guarantee</MenuItem>
              <MenuItem value={"Warranty"}>Warranty</MenuItem>
            </Select>
          </FormControl>
          <span className="input_error">
            {errors?.recoveryType?.message?.toString()}
          </span>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Periods"
            placeholder="period"
            type="text"
            register={register("period")}
            error={errors?.period?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Select file"
            placeholder=""
            type="file"
            register={register("assteDocument", {
              onChange: (e: any) => {
                setFileData(e.target.files[0]);
              },
            })}
            error={errors?.firmDocument?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Supplier number"
            placeholder="Supplier number"
            type="number"
            register={register("supplierNumber")}
            error={errors?.supplierNumber?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Supplier Name"
            placeholder="Supplier name"
            register={register("supplierName")}
            error={errors?.supplierName?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Category
          </InputLabel>
          <FormControl sx={{ minWidth: "100%" }}>
            <Select
              size="small"
              {...register("category")}
              value={category || ""}
              onChange={(e) => setCategory(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"web"}>Web</MenuItem>
              <MenuItem value={"android"}>Android</MenuItem>
              <MenuItem value={"ios"}>IOS</MenuItem>
              <MenuItem value={"ui-ux"}>UI/UX</MenuItem>
            </Select>
          </FormControl>
          <span className="input_error">
            {errors?.category?.message?.toString()}
          </span>
        </Grid>
        <Grid item xs={12} lg={12} xl={12} md={12} sm={12} >
          <div className='defaultCheckbox' style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              className='input_label' style={{ margin: '0px', cursor: 'pointer' }}
              control={
                <Checkbox
                  size="small"
                  id='checkbox'
                  checked={isWorking}
                  onChange={(e) => { setIsWorking(e.target.checked); setValue("isWorking", e.target.checked) }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    color: '#948F94',
                    '&.Mui-checked': {
                      color: '#6750A4',
                    },
                  }}
                />
              } label="Is working" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AssetsManagement;
