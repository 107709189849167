import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const Table = ({ columns, rows }: any) => {

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rowHeight={40}
        rows={rows}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick
        initialState={{
          ...rows?.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 15, 20]}
      />
    </div>
  )
}

export default Table