import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import AddBank from '../../../components/modals/AddBank'
import Table from '../../../components/elements/Table';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Action from '../../../assets/img/table_action.png'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '../../../components/elements/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_FIRM, EDIT_FIRM, GET_BY_ID_FIRM } from '../../../redux/type';
import { toast } from 'react-toastify';
import { editFirmAction, getByIdFirmAction } from '../../../redux/actions/firmAction';
import { Delete, Edit } from '@mui/icons-material';
import DeleteConfirmation from '../../../components/modals/DeleteConfirmation';

interface Props {
    banksData: any;
    saveData: (e: any) => void;
}
const BankDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [editData, setEditData] = useState<any>({});
    const [firmData, setFirmData] = useState<any>([]);
    const [bankDataId, setBankDataId] = useState<any>('')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const params = useParams();
    const id = params.id ?? localStorage.getItem("firmId")

    const getByIdFirmData = useSelector((state: any) => state.getByIdFirm.getByIdFirm?.data);
    const editFirmData = useSelector((state: any) => state.editFirm.editFirm);

    const columns: GridColDef[] = [
        { field: 'index', headerName: 'No', width: 100 },
        { field: 'bankName', headerName: 'Bank Name', flex: 1 },
        { field: 'accountNumber', headerName: 'A/C No', flex: 1 },
        { field: 'accountHolderName', headerName: 'A/C Name', flex: 1 },
        { field: 'nickName', headerName: 'Nick Name', flex: 1 },
        { field: 'IFSCCode', headerName: 'IFSC', flex: 1 },
        { field: 'swift', headerName: 'Swift', flex: 1 },
        { field: 'accountType', headerName: 'A/C type', flex: 1 },
        {
            field: 'isDefault', headerName: 'Is Default', width: 100, renderCell: ((param: any) => <>{param.row.isDefault ? <Checkbox
                size="small"
                id='checkbox'
                checked={param.row.isDefault}
                disabled
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                    color: '#948F94',
                    '&.Mui-checked': {
                        color: '#6750A4',
                    },
                }}
            /> : <></>}</>)
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            renderCell: ({ row }) => {
                return (
                    <>
                        <div className='table_actions' >
                            <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => { editBank(row._id) }} />
                            <Delete color='error' fontSize="small" style={{ margin: "5px" }}
                                onClick={() => {
                                    setBankDataId(row._id);
                                    setOpenDeleteModal(true)
                                }}
                            />
                        </div>
                    </>
                )
            },
            // getActions: ({ id }) => {
            //     return [
            //         <GridActionsCellItem
            //             icon={
            //                 <div className='table_actions' >
            //                     <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => { editBank(id) }} />
            //                     <Delete color='error' fontSize="small" style={{ margin: "5px" }}
            //                         onClick={() => {
            //                             setBankDataId(id);
            //                             setOpenDeleteModal(true)
            //                         }}
            //                     />
            //                 </div>
            //                 // <div>
            //                 //     <IconButton
            //                 //         aria-label="more"
            //                 //         id="long-button"
            //                 //         aria-controls={open ? 'long-menu' : undefined}
            //                 //         aria-expanded={open ? 'true' : undefined}
            //                 //         aria-haspopup="true"
            //                 //         onClick={(e) => {
            //                 //             setBankDataId(id)
            //                 //             handleClick(e)
            //                 //         }}
            //                 //     >
            //                 //         <img src={Action} alt='' />
            //                 //     </IconButton>
            //                 //     <Menu
            //                 //         id="long-menu"
            //                 //         MenuListProps={{
            //                 //             'aria-labelledby': 'long-button',
            //                 //         }}
            //                 //         anchorEl={anchorEl}
            //                 //         open={open}
            //                 //         onClose={handleClose}
            //                 //         PaperProps={{
            //                 //             style: {
            //                 //                 maxHeight: '150px',
            //                 //                 width: '20ch',
            //                 //             },
            //                 //         }}
            //                 //     >
            //                 //         <MenuItem onClick={(e) => editBank(id)}>
            //                 //             Edit
            //                 //         </MenuItem>
            //                 //         <MenuItem onClick={(e) => deleteBank(id)}>
            //                 //             Delete
            //                 //         </MenuItem>
            //                 //     </Menu>
            //                 // </div>
            //             }
            //             label="Edit"
            //             className="textPrimary"
            //             onClick={() => { }}
            //             color="inherit"
            //         />
            //     ];
            // },
        },
    ];



    useEffect(() => {
        return (() => {
            dispatch({
                type: GET_BY_ID_FIRM,
                payload: '',
            });
        })
    }, [])

    useEffect(() => {
        if (editFirmData) {
            if (editFirmData?.status === 200) {
                toast.success(`Your bank ${openDeleteModal ? "deleted" : !isEdit ? "added" : "edited"} successfully!`)
                setOpenDeleteModal(false)
                setOpenModal(false)
            } else {
                toast.error(editFirmData.message)
            }
            dispatch({
                type: EDIT_FIRM,
                payload: '',
            });
        }
    }, [editFirmData])


    useEffect(() => {
        if (getByIdFirmData?.banks)
            setFirmData(getByIdFirmData)
        else
            setFirmData(null)
    }, [getByIdFirmData])

    useEffect(() => {
        if (id) {
            dispatch(getByIdFirmAction(id))
        }
    }, [id, editFirmData]);

    const openAddBank = () => {
        setOpenModal(true)
    };

    const closeAddBank = () => {
        setEditData({})
        setOpenModal(false)
    };

    const editBank = (id: any) => {
        setBankDataId(id);
        setEditData({ ...firmData?.banks?.find((item: any) => item._id === id), _id: id })
        setOpenModal(true)
        handleClose(id)
    };

    const deleteBank = () => {
        let temp: any[] = []
        if (bankDataId) {
            temp = firmData?.banks?.filter((item: any) => item?._id !== bankDataId)
        }
        console.log("🚀 ~ file: BankDetails.tsx:191 ~ deleteBank ~ firmData?.banks:", firmData?.banks)
        console.log("🚀 ~ file: BankDetails.tsx:191 ~ deleteBank ~ bankDataId:", bankDataId)
        console.log("🚀 ~ file: BankDetails.tsx:191 ~ deleteBank ~ firmData:", firmData)
        console.log("🚀 ~ file: BankDetails.tsx:188 ~ deleteBank ~ temp:", temp)
        onEdit({
            GST: firmData?.GST,
            LUTNumber: firmData?.LUTNumber,
            PAN: firmData?.PAN,
            address: firmData?.address,
            city: firmData?.city,
            country: firmData?.country,
            name: firmData?.name,
            pincode: firmData?.pincode,
            reverseCharge: firmData?.reverseCharge,
            state: firmData?.state,
            banks: temp
        })
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (id: any) => {
        setAnchorEl(null);
    };

    const onBankSave = (e: any) => {
        let temp: any[] = []

        if (e._id) {
            setIsEdit(true)
            for (let i = 0; i < firmData?.banks?.length; i++) {
                const element = firmData?.banks[i];
                if (element?._id === e?._id) {
                    temp.push(e)
                } else {
                    temp.push(element)
                }
            }
        } else {
            setIsEdit(false)
            temp = [...firmData?.banks, e]
        }

        // Set as Default
        temp = temp?.map((item: any) => {
            if (e.isDefault) {
                if (item._id === e._id) {
                    return {
                        ...item,
                        isDefault: true
                    }
                } else {
                    return {
                        ...item,
                        isDefault: false
                    }
                }
            } else {
                return {
                    ...item
                }
            }
        })
        onEdit({
            GST: firmData?.GST,
            LUTNumber: firmData?.LUTNumber,
            PAN: firmData?.PAN,
            address: firmData?.address,
            city: firmData?.city,
            country: firmData?.country,
            name: firmData?.name,
            pincode: firmData?.pincode,
            reverseCharge: firmData?.reverseCharge,
            state: firmData?.state,
            banks: temp
        })
    }

    const onEdit = (data: any) => {
        console.log("🚀 ~ file: BankDetails.tsx:270 ~ onEdit ~ data:", data)
        const formData = new FormData();
        delete data.firmDocument
        for (const key in data) {
            if (typeof data[key] === "object") {
                for (let i = 0; i < data[key]?.length; i++) {
                    const element = data[key][i];
                    delete element._id
                    for (const elementkey in element) {
                        formData.append(`${key}[${i}][${elementkey}]`, element[elementkey])
                    }
                }

            } else {
                formData.append(`${key}`, data[key])
            }

        }
        dispatch(editFirmAction(id, formData))
    }


    return (
        <>
            <div className='card_footer'>
                <Button
                    variant="outlined"
                    buttonClass='contained_button_cancel'
                    onClick={() => { localStorage.removeItem("firmId"); navigate('/firm') }}
                    child='Cancel' />
                <Button
                    onClick={() => openAddBank()}
                    variant="outlined"
                    buttonClass='contained_button_add'
                    child={'Add'} />
            </div>
            <Table rows={firmData?.banks?.map((item: any, i: number) => { return { ...item, id: item._id ?? i + 1, index: i + 1 } }) ?? []} columns={columns} />
            {(openModal && editData) && < AddBank open={openModal} handleClose={() => closeAddBank()} setBankData={(e: any) => onBankSave(e)} bankData={editData} />}
            {openDeleteModal && <DeleteConfirmation open={openDeleteModal} title='bank' handleClose={(data: any) => setOpenDeleteModal(false)} handleConfirm={() => deleteBank()} />}

        </>
    )
}

export default BankDetails