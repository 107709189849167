import {
  ADD_CLIENTS,
  ADD_CLIENTS_ERROR,
  ADD_CLIENTS_LOADING,
  DELETE_CLIENTS,
  DELETE_CLIENTS_ERROR,
  DELETE_CLIENTS_LOADING,
  EDIT_CLIENTS,
  EDIT_CLIENTS_ERROR,
  EDIT_CLIENTS_LOADING,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_ERROR,
  GET_ALL_CLIENTS_LOADING,
  GET_BY_ID_CLIENTS,
  GET_BY_ID_CLIENTS_ERROR,
  GET_BY_ID_CLIENTS_LOADING,
} from "../type";

const getAllClientsData: any = {
  getAllClientsLoading: false,
  getAllClients: [],
  getAllClientsError: "",
};

const addClientsData: any = {
  addClientsLoading: false,
  addClients: null,
  addClientsError: "",
};

const getByIdClientsData: any = {
  getByIdClientsLoading: false,
  getByIdClients: null,
  getByIdClientsError: "",
};

const editClientsData: any = {
  editClientsLoading: false,
  editClients: null,
  editClientsError: "",
};

const deleteClientsData: any = {
  deleteClientsLoading: false,
  deleteClients: null,
  deleteClientsError: "",
};

export const getAllClientsReducer = (
  state = getAllClientsData,
  action: any
) => {
  switch (action.type) {
    case GET_ALL_CLIENTS_LOADING:
      return {
        ...state,
        getAllClientsLoading: action.payload,
      };
    case GET_ALL_CLIENTS:
      return {
        ...state,
        getAllClients: action.payload,
      };
    case GET_ALL_CLIENTS_ERROR:
      return {
        ...state,
        getAllClientsError: action.payload,
      };
    default:
      return state;
  }
};

export const addClientsReducer = (state = addClientsData, action: any) => {
  switch (action.type) {
    case ADD_CLIENTS_LOADING:
      return {
        ...state,
        addClientsding: action.payload,
      };
    case ADD_CLIENTS:
      return {
        ...state,
        addClients: action.payload,
      };
    case ADD_CLIENTS_ERROR:
      return {
        ...state,
        addClientsError: action.payload,
      };
    default:
      return state;
  }
};

export const getByIdClientsReducer = (
  state = getByIdClientsData,
  action: any
) => {
  switch (action.type) {
    case GET_BY_ID_CLIENTS_LOADING:
      return {
        ...state,
        getByIdClientsLoading: action.payload,
      };
    case GET_BY_ID_CLIENTS:
      return {
        ...state,
        getByIdClients: action.payload,
      };
    case GET_BY_ID_CLIENTS_ERROR:
      return {
        ...state,
        getByIdClientsError: action.payload,
      };
    default:
      return state;
  }
};

export const editClientsReducer = (state = editClientsData, action: any) => {
  switch (action.type) {
    case EDIT_CLIENTS_LOADING:
      return {
        ...state,
        editClientsLoading: action.payload,
      };
    case EDIT_CLIENTS:
      return {
        ...state,
        editClients: action.payload,
      };
    case EDIT_CLIENTS_ERROR:
      return {
        ...state,
        editClientsError: action.payload,
      };
    default:
      return state;
  }
};

export const deleteClientsReducer = (
  state = deleteClientsData,
  action: any
) => {
  switch (action.type) {
    case DELETE_CLIENTS_LOADING:
      return {
        ...state,
        deleteClientsLoading: action.payload,
      };
    case DELETE_CLIENTS:
      return {
        ...state,
        deleteClients: action.payload,
      };
    case DELETE_CLIENTS_ERROR:
      return {
        ...state,
        deleteClientsError: action.payload,
      };
    default:
      return state;
  }
};
