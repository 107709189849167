import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Details from './setps/Details';
import BankDetails from './setps/BankDetails';
import { useLocation, useParams } from 'react-router-dom';
import FirmInvoice from './setps/FirmInvoice';
import Assets from './setps/Assets';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const FirmManagement = () => {

    const [tabValue, settabValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        settabValue(newValue);
    };

    const location = useLocation()
    const id = useParams().id
    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        return () => {
            localStorage.removeItem("firmId")
        }

    }, [])

    useEffect(() => {
        console.log('location', location)
        if (location.state && location.state.activeTab?.toString()) {
            settabValue(location.state.activeTab)
        }
    }, [location])


    return (

        <>
            < Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab disabled={!id} label="Invoices" {...a11yProps(1)} />
                    <Tab disabled={!id} label="Banks" {...a11yProps(2)} />
                    <Tab disabled={!id} label="Assets" {...a11yProps(3)} />
                </Tabs>
            </Box >
            <TabPanel value={tabValue} index={0}>
                <Details />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <FirmInvoice />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <BankDetails />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <Assets />
            </TabPanel>
        </>
    )
}

export default FirmManagement

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '10px 0px' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}