import { ApiGet, ApiPost, ApiPostWithFormData, ApiPut } from "../../helper/API/ApiData"
import { ADD_FIRM, ADD_FIRM_ASSETS, ADD_FIRM_ASSETS_ERROR, ADD_FIRM_ASSETS_LOADING, ADD_FIRM_BANKS, ADD_FIRM_BANKS_ERROR, ADD_FIRM_BANKS_LOADING, ADD_FIRM_ERROR, ADD_FIRM_LOADING, DELETE_FIRM, DELETE_FIRM_ASSETS, DELETE_FIRM_ASSETS_ERROR, DELETE_FIRM_ASSETS_LOADING, DELETE_FIRM_BANKS, DELETE_FIRM_BANKS_ERROR, DELETE_FIRM_BANKS_LOADING, DELETE_FIRM_ERROR, DELETE_FIRM_LOADING, EDIT_FIRM, EDIT_FIRM_ASSETS, EDIT_FIRM_ASSETS_ERROR, EDIT_FIRM_ASSETS_LOADING, EDIT_FIRM_BANKS, EDIT_FIRM_BANKS_ERROR, EDIT_FIRM_BANKS_LOADING, EDIT_FIRM_ERROR, EDIT_FIRM_LOADING, GET_ALL_FIRM, GET_ALL_FIRM_ERROR, GET_ALL_FIRM_LOADING, GET_BY_FIRM_ASSETS, GET_BY_FIRM_ASSETS_ERROR, GET_BY_FIRM_ASSETS_LOADING, GET_BY_ID_FIRM, GET_BY_ID_FIRM_ERROR, GET_BY_ID_FIRM_LOADING, IS_LOADING } from "../type";


export const getAllFirmAction: any =
    (type: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: GET_ALL_FIRM_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiGet(`firm`)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_FIRM,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: GET_ALL_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: GET_ALL_FIRM_ERROR,
                payload: err,
            });

            dispatch({
                type: GET_ALL_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const addFirmAction: any =
    (data: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: ADD_FIRM_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPostWithFormData(`firm/add`, data)
                .then((res) => {
                    dispatch({
                        type: ADD_FIRM,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: ADD_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: ADD_FIRM_ERROR,
                payload: err,
            });

            dispatch({
                type: ADD_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const deleteFirmAction: any =
    (data: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: DELETE_FIRM_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiGet(`firm/delete/${data}`)
                .then((res: any) => {
                    dispatch({
                        type: DELETE_FIRM,
                        payload: res,
                    });
                    if (res.status === 200) {
                        dispatch(getAllFirmAction())
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: DELETE_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: DELETE_FIRM_ERROR,
                payload: err,
            });

            dispatch({
                type: DELETE_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const getByIdFirmAction: any =
    (id: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: GET_BY_ID_FIRM_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiGet(`firm/${id}`)
                .then((res: any) => {
                    dispatch({
                        type: GET_BY_ID_FIRM,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: GET_BY_ID_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: GET_BY_ID_FIRM_ERROR,
                payload: err,
            });

            dispatch({
                type: GET_BY_ID_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const editFirmAction: any =
    (id: any, body: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: EDIT_FIRM_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPostWithFormData(`firm/edit/${id}`, body)
                .then((res: any) => {
                    dispatch({
                        type: EDIT_FIRM,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: EDIT_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: EDIT_FIRM_ERROR,
                payload: err,
            });

            dispatch({
                type: EDIT_FIRM_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const getByIdFirmAssetsAction: any =
    (id: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: GET_BY_FIRM_ASSETS_LOADING,
                payload: true,
            });
            await ApiGet(`firm/assets/${id}`)
                .then((res: any) => {
                    dispatch({
                        type: GET_BY_FIRM_ASSETS,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: GET_BY_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: GET_BY_FIRM_ASSETS_ERROR,
                payload: err,
            });

            dispatch({
                type: GET_BY_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const addFirmAssetsAction: any =
    (id: any, data: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: ADD_FIRM_ASSETS_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPostWithFormData(`firm/add-asset/${id}`, data)
                .then((res) => {
                    dispatch({
                        type: ADD_FIRM_ASSETS,
                        payload: res,
                    });
                    dispatch(getByIdFirmAssetsAction(id))
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: ADD_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: ADD_FIRM_ASSETS_ERROR,
                payload: err,
            });

            dispatch({
                type: ADD_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const deleteFirmAssetsAction: any =
    (id: any, data: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: DELETE_FIRM_ASSETS_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPost(`firm/delete-asset/${id}`, data)
                .then((res: any) => {
                    dispatch({
                        type: DELETE_FIRM_ASSETS,
                        payload: res,
                    });
                    if (res.status === 200) {
                        dispatch(getByIdFirmAssetsAction(id))
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: DELETE_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: DELETE_FIRM_ASSETS_ERROR,
                payload: err,
            });

            dispatch({
                type: DELETE_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };

export const editFirmAssetsAction: any =
    (id: any, body: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: EDIT_FIRM_ASSETS_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPost(`firm/edit-asset/${id}`, body)
                .then((res: any) => {
                    dispatch({
                        type: EDIT_FIRM_ASSETS,
                        payload: res,
                    });
                    if (res.status === 200) {
                        dispatch(getByIdFirmAssetsAction(id))
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: EDIT_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: EDIT_FIRM_ASSETS_ERROR,
                payload: err,
            });

            dispatch({
                type: EDIT_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };

export const getByIdFirmBanksAction: any =
    (id: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: GET_BY_FIRM_ASSETS_LOADING,
                payload: true,
            });
            await ApiGet(`firm/assets/${id}`)
                .then((res: any) => {
                    dispatch({
                        type: GET_BY_FIRM_ASSETS,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: GET_BY_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: GET_BY_FIRM_ASSETS_ERROR,
                payload: err,
            });

            dispatch({
                type: GET_BY_FIRM_ASSETS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const addFirmBanksAction: any =
    (id: any, data: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: ADD_FIRM_BANKS_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPost(`firm/add-bank/${id}`, data)
                .then((res) => {
                    dispatch({
                        type: ADD_FIRM_BANKS,
                        payload: res,
                    });
                    dispatch(getByIdFirmBanksAction(id))
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: ADD_FIRM_BANKS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: ADD_FIRM_BANKS_ERROR,
                payload: err,
            });

            dispatch({
                type: ADD_FIRM_BANKS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };
export const deleteFirmBanksAction: any =
    (id: any, data: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: DELETE_FIRM_BANKS_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPost(`firm/delete-asset/${id}`, data)
                .then((res: any) => {
                    dispatch({
                        type: DELETE_FIRM_BANKS,
                        payload: res,
                    });
                    if (res.status === 200) {
                        dispatch(getByIdFirmBanksAction(id))
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: DELETE_FIRM_BANKS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: DELETE_FIRM_BANKS_ERROR,
                payload: err,
            });

            dispatch({
                type: DELETE_FIRM_BANKS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };

export const editFirmBanksAction: any =
    (id: any, body: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: EDIT_FIRM_BANKS_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPost(`firm/edit-asset/${id}`, body)
                .then((res: any) => {
                    dispatch({
                        type: EDIT_FIRM_BANKS,
                        payload: res,
                    });
                    if (res.status === 200) {
                        dispatch(getByIdFirmBanksAction(id))
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: EDIT_FIRM_BANKS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: EDIT_FIRM_BANKS_ERROR,
                payload: err,
            });

            dispatch({
                type: EDIT_FIRM_BANKS_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };