import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/elements/Button";
import Table from "../../components/elements/Table";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientsAction,
  getAllClientsAction,
} from "../../redux/actions/clientsAction";
import IconButton from "@mui/material/IconButton";
import Action from "../../assets/img/table_action.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteConfirmation from "../../components/modals/DeleteConfirmation";
import { toast } from "react-toastify";
import { DELETE_CLIENTS } from "../../redux/type";
import { Delete, Edit } from "@mui/icons-material";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [clintData, setClintData] = useState([]);
  const [clintId, setClintId] = useState<any>("");
  const [openModal, setOpenModal] = useState(false);
  const getClientsData = useSelector(
    (state: any) => state.getAllClients.getAllClients
  );
  const deleteClientData = useSelector(
    (state: any) => state.deleteClients.deleteClients
  );

  useEffect(() => {
    dispatch(getAllClientsAction());
  }, []);

  useEffect(() => {
    if (deleteClientData) {
      if (deleteClientData?.status === 200) {
        toast.success(deleteClientData?.message);
        setClintId("");
        setOpenModal(false);
        dispatch({
          type: DELETE_CLIENTS,
          payload: null,
        });
      } else {
        toast.error(deleteClientData?.message);
      }
    }
  }, [deleteClientData]);

  useEffect(() => {
    setClintData(
      getClientsData && getClientsData.data?.length ? getClientsData.data : []
    );
  }, [getClientsData]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id: any) => {
    setAnchorEl(null);
  };
  const editData = (id: any) => {
    navigate(`edit/${id}`);
    handleClose(id);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (clintId) {
      dispatch(deleteClientsAction(clintId));
    }
  };

  const columns: GridColDef[] = [
    { field: "index", headerName: "No", width: 100 },
    { field: "companyName", headerName: "Company Name", flex: 1 },
    { field: "clientName", headerName: "Client Name", flex: 1 },
    { field: "GST", headerName: "GST", flex: 1 },
    { field: "PAN", headerName: "PAN", flex: 1 },
    { field: "placeOfSupply", headerName: "Place of supply", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: ({ row }) => {
        return (
          <>
            <div className="table_actions">
              <Edit
                fontSize="small"
                style={{ margin: "5px" }}
                onClick={(e) => editData(row._id)}
              />
              <Delete
                color="error"
                fontSize="small"
                style={{ margin: "5px" }}
                onClick={() => {
                  setClintId(row._id);
                  setOpenModal(true);
                }}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="">
      <div className="card_header">
        <h1 className="card_header_title">Clients</h1>
        <div className="card_footer">
          <Button
            onClick={() => navigate("add")}
            variant="outlined"
            buttonClass="contained_button_add"
            child="Add"
          />
        </div>
      </div>
      <Table
        rows={clintData?.map((item: any, i: number) => {
          return { ...item, id: item._id ?? i + 1, index: i + 1 };
        })}
        columns={columns}
      />
      {openModal && (
        <DeleteConfirmation
          open={openModal}
          title="client"
          handleClose={(data: any) => setOpenModal(false)}
          handleConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default Index;
