import AuthStorage from "../AuthStorage";
import { API } from "../../config/API/api.config";
import axios from "axios";
import { toast } from "react-toastify";
export const BaseURL = API.endpoint + "/";
// export const BaseURL = `${process.env.REACT_BASE_URL_LOCAL}/`;
// const axios = require('axios').default;

const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
};

interface apiResponse {
  data: any,
  status: number;
}

export const downloadPDF = (type: string) => {
  // const navigate = useNavigate();
  return new Promise<string>((resolve, reject) => {
    axios
      .get(`${BaseURL}${type}`, getHttpOptions())
      .then((responseJson: any) => {
        const file = new Blob([responseJson.data], {
          type: 'application/pdf',
        });

        const fileURL = URL.createObjectURL(file);
        resolve(fileURL);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
          // navigate("/");
        }
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiGet = (type: string) => {
  // const navigate = useNavigate();
  return new Promise((resolve, reject) => {
    axios
      .get(`${BaseURL}${type}`, getHttpOptions())
      .then((responseJson: apiResponse) => {
        resolve(responseJson.data);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
          // navigate("/");
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiGetNoAuth = (type: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BaseURL}${type}`,
        getHttpOptions({ ...defaultHeaders, isAuth: false })
      )
      .then((responseJson: apiResponse) => {
        resolve(responseJson.data);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPost = (type: string, userData: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BaseURL}${type}`, userData, getHttpOptions())
      .then((responseJson: apiResponse) => {
        resolve(responseJson.data);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPostWithFormData = (type: string, userData: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BaseURL}${type}`, userData, getHttpOptions({ ...defaultHeaders, isJsonRequest: false }))
      .then((responseJson: apiResponse) => {
        resolve(responseJson.data);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};
export const ApiPostNoAuth = (type: string, userData: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${BaseURL}${type}`,
        userData,
        getHttpOptions({ ...defaultHeaders, isAuth: false })
      )
      .then((responseJson: apiResponse) => {
        resolve(responseJson.data);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPatch = (type: string, userData: any) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${BaseURL}${type}`, userData, getHttpOptions())
      .then((responseJson: apiResponse) => {
        resolve(responseJson);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiDelete = (type: string, userData: any) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${BaseURL}${type}`, getHttpOptions())
      .then((responseJson: apiResponse) => {
        resolve(responseJson.data);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPut = (type: string, userData: any) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${BaseURL}${type}`, userData, getHttpOptions())
      .then((responseJson: apiResponse) => {
        resolve(responseJson.data);
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser();
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {
    Authorization: "",
    "Content-Type": "",
    "Access-Control-Allow-Origin": null,
    "Access-Control-Allow-credentials": true,
  };
  // "Origin, X-Requested-With, Content-Type, Accept"

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers["Authorization"] = AuthStorage.getToken() ?? "";
  }

  if (options.hasOwnProperty("isJsonRequest") && options.isJsonRequest) {
    headers["Content-Type"] = "application/json";
  }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }

  return { headers };
};
