import React, { useEffect } from 'react';
import Button from '../../components/elements/Button';
import Input from '../../components/elements/Input';
import Grid from '@mui/material/Grid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { loginAction } from '../../redux/actions/loginAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from '../../redux/type';
import STORAGEKEY from '../../config/APP/app.config';
import AuthStorage from '../../helper/AuthStorage';
import { toast } from 'react-toastify';

const Index = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loginData = useSelector((state: any) => state.login.login)
    useEffect(() => {
        if (loginData) {
            console.log('loginData', loginData)
            if (loginData.status === 200) {
                AuthStorage.setStorageData(STORAGEKEY.token, loginData.data.token, true)
                toast.success(loginData.message)
                navigate('/firm')
            }
            dispatch({
                type: LOGIN,
                payload: ''
            })
        }
    }, [loginData])

    const loginDataSchema = yup.object().shape({
        email: yup.string().required('Please enter email'),
        password: yup.string().required('Please enter password'),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(loginDataSchema),
    });

    const login = (data: any) => {
        dispatch(loginAction(data))
        reset({
            email: "",
            password: ""
        })
    }

    return (
        <div className='bg_main'>
                <div className='login_main'>
                    <h1 className='title'>Login</h1>
                    <Grid container spacing={2}>
                        <Grid item xs={24} lg={12}>
                            <Input
                                placeholder="User name"
                                register={register("email")}
                                error={errors?.email?.message}
                            />
                        </Grid>
                        <Grid item xs={24} lg={12}>
                            <Input
                                placeholder="Password"
                                type='password'
                                register={register("password")}
                                error={errors?.password?.message}
                            />
                        </Grid>
                        <Grid item xs={24} lg={12}>
                            <Button
                                onClick={handleSubmit((data) => {
                                    login(data)
                                })}
                                variant="outlined"
                                buttonClass='outlined_button'
                                child='Log in' />
                        </Grid>
                    </Grid>
                </div>
        </div>
    )
}

export default Index