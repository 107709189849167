import React from 'react';
import Box from '@mui/material/Box';
import Sidebar from './sidebar';
import Header from './header';
import { Outlet } from 'react-router-dom';
import Loader from '../components/elements/Loader';
import { useSelector } from 'react-redux';

const Index = ({ children }: any) => {
    const [open, setOpen] = React.useState(true);

    const loading = useSelector((state: any) => state?.loading?.loading)

    const handleDrawer = () => {
        setOpen(!open);
    };

    return (
        <>
            {loading && <Loader />}
            <Box sx={{ display: 'flex' }}>
                <Header drawerOpen={open} handleDrawer={handleDrawer} />
                <Sidebar drawerOpen={open} />
                <Box component="main" className='layout_children_main' sx={{ flexGrow: 1, p: 3 }}>
                    <Outlet />
                </Box>
            </Box>
        </>
    )
}

export default Index