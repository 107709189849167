import React, { useEffect, useState } from "react";
import Button from "../../components/elements/Button";
import Grid from "@mui/material/Grid";
import Input from "../../components/elements/Input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllFirmAction } from "../../redux/actions/firmAction";
import { getAllClientsAction } from "../../redux/actions/clientsAction";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { StringDecoder } from "string_decoder";
import {
  addInvoiceAction,
  editInvoiceAction,
  getInvoiceByIdAction,
} from "../../redux/actions/invoiceAction";
import { toast } from "react-toastify";
import { ADD_INVOICE, EDIT_INVOICE, GET_INVOICE_BY_ID } from "../../redux/type";
import dayjs, { Dayjs } from "dayjs";

const InvoiceManagement = () => {
  const currencysOptions = [
    { label: "USD to USD", value: "USD to USD" },
    { label: "USD to INR", value: "USD to INR" },
    { label: "INR to INR", value: "INR to INR" },
    { label: "INR", value: "INR" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [selectedFirm, setSelectedFirm] = useState<any>(null);
  const [firms, setFirms] = useState<any[]>([]);
  const [banks, setBanks] = useState<any[]>([]);
  const [banksOptions, setBanksOptions] = useState<any[]>([]);
  const [selectedBank, setSelectedBank] = useState<any>(null);
  const [firmsOptions, setFirmsOptions] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [clients, setClients] = useState<any[]>([]);
  const [clientCurrency, setClientCurrency] = useState<any>(null);
  const [clientsOptions, setClientsOptions] = useState<any[]>([]);
  const [type, setType] = useState("");
  const [invoiceDate, setInvoiceDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );

  const getFirmData = useSelector((state: any) => state.getAllFirm.getAllFirm);
  const getClientsData = useSelector(
    (state: any) => state.getAllClients.getAllClients
  );

  const addInvoiceData = useSelector(
    (state: any) => state.addInvoice.addInvoice
  );
  const editInvoiceData = useSelector(
    (state: any) => state.editInvoice.editInvoice
  );
  const getInvoiceByIdData = useSelector(
    (state: any) => state.getInvoiceById.getInvoiceById
  );

  const findValue = (value: string, data: any, param: string) => {
    return data?.find((item: any) => item[param] === value);
  };

  useEffect(() => {
    dispatch(getAllFirmAction());
    dispatch(getAllClientsAction());
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(getInvoiceByIdAction(params.id));
    }
  }, [params.id]);
  console.log('  dayjs(new Date())', dayjs(new Date()).format('DD/MM/YYYY'))
  useEffect(() => {
    if (getFirmData) {
      setFirms(getFirmData.data);
      let option: any[] = [];
      getFirmData?.data?.map((item: any) => {
        option.push({ label: item.name, value: item._id });
      });
      if (location.state?.firmId) {
        setSelectedFirm(findValue(location.state?.firmId, option, "value"));
        setValue(
          "firm",
          findValue(location.state?.firmId, option, "value")?.value
        );
      }
      setFirmsOptions(option);
    }
  }, [getFirmData]);

  useEffect(() => {
    if (getClientsData) {
      setClients(getClientsData.data);
      let option: any[] = [];
      getClientsData?.data?.map((item: any) => {
        option.push({ label: item.companyName, value: item._id });
      });
      setClientsOptions(option);
    }
  }, [getClientsData]);

  useEffect(() => {
    if (selectedClient?.value) {
      let client = findValue(selectedClient?.value, clients, "_id");
      setClientCurrency(findValue(client?.currency, currencysOptions, "value"));
      setValue("currency", client?.currency);

      setValue(
        "areaType",
        client?.clientType === "EXPORT" ? "EXPORT" : client?.type
      );
      setType(client?.clientType === "EXPORT" ? "EXPORT" : client?.type);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedFirm?.value) {
      let firm = findValue(selectedFirm?.value, firms, "_id");
      setBanks(firm?.banks);
      let option: any[] = [];
      firm?.banks?.map((item: any) => {
        option.push({
          label: `${item?.bankName} - ${item?.accountHolderName}`,
          value: item._id,
        });
      });
      setBanksOptions(option);
      setSelectedBank(
        option.find(
          (opt: any) =>
            opt.value === firm?.banks?.find((item: any) => item?.isDefault)?._id
        )
      );
      setValue(
        "bank",
        option.find(
          (opt: any) =>
            opt.value === firm?.banks?.find((item: any) => item?.isDefault)?._id
        )?.value
      );
    }
  }, [selectedFirm]);

  useEffect(() => {
    if (addInvoiceData) {
      if (addInvoiceData.status === 200) {
        navigate(`/firm/edit/${location.state?.firmId}`, {
          state: { activeTab: 1 },
        });
        toast.success(addInvoiceData.message);
      } else {
        toast.error(addInvoiceData.message);
      }
    }
  }, [addInvoiceData]);

  useEffect(() => {
    if (editInvoiceData) {
      if (editInvoiceData.status === 200) {
        navigate(`/firm/edit/${location.state?.firmId}`, {
          state: { activeTab: 1 },
        });
        toast.success(editInvoiceData.message);
        dispatch({ type: EDIT_INVOICE, payload: null });
      } else {
        toast.error(editInvoiceData.message);
      }
    }
  }, [editInvoiceData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: ADD_INVOICE,
        payload: null,
      });
      dispatch({
        type: EDIT_INVOICE,
        payload: null,
      });
      dispatch({
        type: GET_INVOICE_BY_ID,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (getInvoiceByIdData && getInvoiceByIdData.status === 200) {
      reset({
        invoiceDate: moment(
          new Date(getInvoiceByIdData?.data?.invoiceDate)
        ).format("DD/MM/YYYY"),
        amount: getInvoiceByIdData?.data?.amount,
        rate: getInvoiceByIdData?.data?.rate,
        firm: getInvoiceByIdData?.data?.firm,
        client: getInvoiceByIdData?.data?.client,
        areaType: getInvoiceByIdData?.data?.areaType,
        currency: getInvoiceByIdData?.data?.currency,
        invoiceNumber: getInvoiceByIdData?.data?.invoiceNumber,
        bank: getInvoiceByIdData?.data?.bank,
      });
      setInvoiceDate(dayjs(getInvoiceByIdData?.data?.invoiceDate));
      setType(getInvoiceByIdData?.data?.areaType);
      if (clientsOptions.length)
        setSelectedClient(
          findValue(getInvoiceByIdData?.data?.client, clientsOptions, "value")
        );
      if (firmsOptions.length)
        setSelectedFirm(
          findValue(getInvoiceByIdData?.data?.firm, firmsOptions, "value")
        );
      if (banksOptions.length)
        setSelectedBank(
          findValue(getInvoiceByIdData?.data?.bank, banksOptions, "value")
        );
    }
  }, [getInvoiceByIdData, firmsOptions, clientsOptions]);

  const invoiceAddSchema = yup.object().shape({
    firm: yup.string().required("Please select firm"),
    client: yup.string().required("Please select client"),
    bank: yup.string().required("Please select bank"),
    invoiceDate: yup.string().required("Please enter invoice date"),
    amount: yup.string().required("Please enter amount"),
    rate: yup.string().required("Please enter rate"),
    areaType: yup.string().required("Please select areaType"),
    currency: yup.string().required("Please select currency"),
  });

  const invoiceEditSchema = yup.object().shape({
    firm: yup.string().required("Please select firm"),
    client: yup.string().required("Please select client"),
    bank: yup.string().required("Please select bank"),
    invoiceDate: yup.string().required("Please enter invoice date"),
    amount: yup.string().required("Please enter amount"),
    rate: yup.string().required("Please enter rate"),
    areaType: yup.string().required("Please select areaType"),
    currency: yup.string().required("Please select currency"),
    invoiceNumber: yup.number().required("Please enter invoice number"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(params.id ? invoiceEditSchema : invoiceAddSchema),
    // defaultValues: {
    //     firm: "",
    //     client: "",
    //     invoiceDate: moment(new Date()).format('MM/DD/YYYY'),
    //     amount: "",
    //     areaType: "",
    //     currency: "",
    //     invoiceNumber: "",
    // }
  });

  const oncancel = () => {
    // if (location.state?.firmId)
    navigate(`/firm/edit/${location.state?.firmId ?? params.firmId}`, {
      state: { activeTab: 1 },
    });
  };

  const onAdd = (data: any) => {
    dispatch(addInvoiceAction(data));
  };

  const onEdit = (data: any) => {
    dispatch(editInvoiceAction(params.id, data));
  };

  const onSelectDateOfBirth = (e: any) => {
    let date = moment(new Date(e)).format("MM/DD/YYYY");
    setInvoiceDate(e);
    setValue("invoiceDate", date);
  };
  console.log("errors", errors);
  return (
    <div className="">
      <div className="card_header">
        <h1 className="card_header_title">
          {params.id ? "Edit" : "Add"} invoice
        </h1>
        <div className="card_footer">
          <Button
            variant="outlined"
            buttonClass="contained_button_cancel"
            onClick={() => oncancel()}
            child="Cancel"
          />
          <Button
            onClick={handleSubmit((data) => {
              if (params.id) onEdit(data);
              else onAdd(data);
            })}
            variant="outlined"
            buttonClass="contained_button_add"
            child={`${params.id ? "Save" : "Add"}`}
          />
        </div>
      </div>
      <Grid container spacing={2} style={{ marginBottom: "16px" }}>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Firm
          </InputLabel>
          <FormControl sx={{ minWidth: "100%" }}>
            <Autocomplete
              disablePortal
              disabled={location.state?.firmId}
              id="combo-box-demo"
              options={firmsOptions}
              value={selectedFirm}
              {...register("firm")}
              onChange={(event: any, newValue: any) => {
                setSelectedFirm(
                  findValue(newValue?.value, firmsOptions, "value")
                );
                setValue("firm", newValue?.value);
              }}
              style={{ padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="select firm" />
              )}
            />
          </FormControl>
          <span className="input_error">
            {errors?.firm?.message?.toString()}
          </span>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Company
          </InputLabel>
          <FormControl sx={{ minWidth: "100%" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={clientsOptions}
              value={selectedClient}
              {...register("client")}
              onChange={(event: any, newValue: any) => {
                setSelectedClient(
                  findValue(newValue?.value, clientsOptions, "value")
                );
                setValue("client", newValue?.value);
                setValue("rate", 1);
              }}
              style={{ padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="select client" />
              )}
            />
          </FormControl>
          <span className="input_error">
            {errors?.client?.message?.toString()}
          </span>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            InvoiceDate
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
            <DatePicker
              {...register("invoiceDate")}
              value={invoiceDate}
              onChange={(e: any) => onSelectDateOfBirth(e)}
              sx={{ width: "100%" }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <Input
            label="Amount"
            placeholder="amount"
            type="number"
            register={register("amount")}
            error={errors?.amount?.message}
          />
        </Grid>
        {findValue(selectedClient?.value, clients, "_id")?.currency ===
          "USD to INR" && (
            <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
              <Input
                label="Rate"
                placeholder="rate"
                type="number"
                register={register("rate")}
                error={errors?.rate?.message}
              />
            </Grid>
          )}
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Type
          </InputLabel>
          <FormControl sx={{ minWidth: "100%" }}>
            <Select
              size="small"
              value={type || ""}
              {...register("areaType")}
              disabled
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"EXPORT"}>EXPORT</MenuItem>
              <MenuItem value={"CENTRAL"}>CENTRAL</MenuItem>
              <MenuItem value={"STATE"}>STATE</MenuItem>
            </Select>
          </FormControl>
          <span className="input_error">
            {errors?.areaType?.message?.toString()}
          </span>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Currency
          </InputLabel>
          <FormControl sx={{ minWidth: "100%" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={currencysOptions}
              value={clientCurrency}
              disabled
              {...register("currency")}
              onChange={(event: any, newValue: any) => {
                setValue("currency", newValue?.value);
              }}
              style={{ padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="select currency" />
              )}
            />
          </FormControl>
          <span className="input_error">
            {errors?.currency?.message?.toString()}
          </span>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
          <InputLabel className="input_label" shrink htmlFor="bootstrap-input">
            Bank
          </InputLabel>
          <FormControl sx={{ minWidth: "100%" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={banksOptions}
              value={selectedBank}
              {...register("bank")}
              onChange={(event: any, newValue: any) => {
                setSelectedBank(
                  findValue(newValue?.value, banksOptions, "value")
                );
                setValue("bank", newValue?.value);
              }}
              style={{ padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="select bank" />
              )}
            />
          </FormControl>
          <span className="input_error">
            {errors?.firm?.message?.toString()}
          </span>
        </Grid>
        {params.id ? (
          <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
            <Input
              label="Invoice No."
              placeholder="Invoice No."
              type="number"
              register={register("invoiceNumber")}
              error={errors?.invoiceNumber?.message}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

export default InvoiceManagement;
