import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../../components/elements/Button';
import Close from '../../assets/img/close.png'
import Grid from '@mui/material/Grid';
import { InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import Input from '../../components/elements/Input';
import Select from '@mui/material/Select';
import dayjs, { Dayjs } from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFirmAction } from '../../redux/actions/firmAction';
import { getAllClientsAction } from '../../redux/actions/clientsAction';
import { BaseURL } from '../../helper/API/ApiData';
import axios from 'axios';
import AuthStorage from '../../helper/AuthStorage';

const GenerateInvoiceModal = ({ open, handleClose }: any) => {

    const currencysOptions = [
        { label: 'USD to USD', value: 'USD to USD' },
        { label: 'USD to INR', value: 'USD to INR' },
        { label: 'INR to INR', value: 'INR to INR' },
        { label: 'INR', value: 'INR' },
    ]

    const dispatch = useDispatch()

    const [firms, setFirms] = useState<any[]>([]);
    const [firmsOptions, setFirmsOptions] = useState<any[]>([]);
    const [selectedFirm, setSelectedFirm] = useState<any>(null);
    const [clients, setClients] = useState<any[]>([]);
    const [clientsOptions, setClientsOptions] = useState<any[]>([]);
    const [selectedClient, setSelectedClient] = useState<any>(null);
    const [invoiceDate, setInvoiceDate] = useState<Dayjs | null>(dayjs(new Date()))
    const [type, setType] = useState("")
    const [clientCurrency, setClientCurrency] = useState<any>(null);
    const [banks, setBanks] = useState<any[]>([]);
    const [banksOptions, setBanksOptions] = useState<any[]>([]);
    const [selectedBank, setSelectedBank] = useState<any>(null);

    const getFirmData = useSelector((state: any) => state.getAllFirm.getAllFirm)
    const getClientsData = useSelector((state: any) => state.getAllClients.getAllClients);

    const invoiceAddSchema = yup.object().shape({
        firm: yup.string().required('Please select firm'),
        client: yup.string().required('Please select client'),
        bank: yup.string().required('Please select bank'),
        invoiceDate: yup.string().required('Please enter invoice date'),
        amount: yup.number().typeError('amount must be a number').required('Please enter amount'),
        areaType: yup.string().required('Please select areaType'),
        currency: yup.string().required('Please select currency'),
        rate: yup.number().required('Please enter rate'),
    });

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(invoiceAddSchema),
    });

    const findValue = (value: string, data: any, param: string) => {
        return data?.find((item: any) => item[param] === value)
    }

    const onSelectDateOfBirth = (e: any) => {
        let date = moment(new Date(e)).format('DD/MM/YYYY')
        setInvoiceDate(e)
        setValue('invoiceDate', date)
    };

    const onSave = (data: any) => {
        let body = {
            ...data,
            rate: data.rate ?? 1
        }
        axios({
            method: 'post',
            url: `${BaseURL}invoice/generateInvoice`,
            data: data,
            responseType: 'blob',
            headers: { Authorization: AuthStorage.getToken() },
        }).then((response) => {
            const file = new Blob([response.data], {
                type: 'application/pdf',
            });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }, (error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        dispatch(getAllFirmAction())
        dispatch(getAllClientsAction())
    }, [])

    useEffect(() => {
        if (getFirmData) {
            setFirms(getFirmData.data)
            let option: any[] = []
            getFirmData?.data?.map((item: any) => {
                option.push({ label: item.name, value: item._id })
            })
            setFirmsOptions(option)
        }
    }, [getFirmData])

    useEffect(() => {
        if (getClientsData) {
            setClients(getClientsData.data)
            let option: any[] = []
            getClientsData?.data?.map((item: any) => {
                option.push({ label: item.companyName, value: item._id })
            })
            setClientsOptions(option)
        }
    }, [getClientsData])

    useEffect(() => {
        if (selectedFirm?.value) {
            let firm = findValue(selectedFirm?.value, firms, "_id")
            setBanks(firm?.banks)
            let option: any[] = []
            firm?.banks?.map((item: any) => {
                option.push({ label: `${item?.bankName} - ${item?.accountHolderName}`, value: item._id })
            })
            setBanksOptions(option)
            setSelectedBank(option.find((opt: any) => opt.value === firm?.banks?.find((item: any) => item?.isDefault)?._id))
            setValue("bank", option.find((opt: any) => opt.value === firm?.banks?.find((item: any) => item?.isDefault)?._id)?.value)
        }
    }, [selectedFirm])

    useEffect(() => {
        if (selectedClient?.value) {
            let client = findValue(selectedClient?.value, clients, "_id")
            setClientCurrency(currencysOptions.find(item => item.value === client?.currency))
            setValue('currency', client?.currency)
            setValue('rate', 1)

            setValue("areaType", client?.clientType === "EXPORT" ? "EXPORT" : client?.type)
            setType(client?.clientType === "EXPORT" ? "EXPORT" : client?.type)
        }
    }, [selectedClient])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='modal_header' >
                Generate Invoice
                <img src={Close} alt='' onClick={handleClose} />
            </DialogTitle>
            <form>
                <DialogContent>
                    <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                        <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                            <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                                Firm
                            </InputLabel>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={firmsOptions}
                                    value={selectedFirm}
                                    {...register("firm")}
                                    onChange={(event: any, newValue: any) => {
                                        setSelectedFirm(findValue(newValue?.value, firmsOptions, "value"));
                                        setValue("firm", newValue?.value)
                                    }}
                                    style={{ padding: 0 }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="select firm" />}
                                />
                            </FormControl>
                            <span className='input_error' >{errors?.firm?.message?.toString()}</span>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                            <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                                Company
                            </InputLabel>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={clientsOptions}
                                    value={selectedClient}
                                    {...register("client")}
                                    onChange={(event: any, newValue: any) => {
                                        setSelectedClient(findValue(newValue?.value, clientsOptions, "value"));
                                        setValue("client", newValue?.value)
                                    }}
                                    style={{ padding: 0 }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="select client" />}
                                />
                            </FormControl>
                            <span className='input_error' >{errors?.client?.message?.toString()}</span>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                            <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                                InvoiceDate
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    {...register('invoiceDate')}
                                    value={invoiceDate}
                                    onChange={(e: any) => onSelectDateOfBirth(e)}
                                    sx={{ width: '100%' }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                            <Input
                                label='Amount'
                                placeholder="amount"
                                type='number'
                                register={register("amount")}
                                error={errors?.amount?.message}
                            />
                        </Grid>
                        {findValue(selectedClient?.value, clients, "_id")?.currency === "USD to INR" &&
                            <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                                <Input
                                    label='Rate'
                                    placeholder="rate"
                                    type='number'
                                    register={register("rate")}
                                    error={errors?.rate?.message}
                                />
                            </Grid>}
                        <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                            <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                                Type
                            </InputLabel>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <Select
                                    size="small"
                                    value={type || ""}
                                    {...register('areaType')}
                                    disabled
                                    displayEmpty
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'EXPORT'}>EXPORT</MenuItem>
                                    <MenuItem value={'CENTRAL'}>CENTRAL</MenuItem>
                                    <MenuItem value={'STATE'}>STATE</MenuItem>
                                </Select>
                            </FormControl>
                            <span className='input_error' >{errors?.areaType?.message?.toString()}</span>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                            <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                                Currency
                            </InputLabel>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={currencysOptions}
                                    value={clientCurrency}
                                    disabled
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    {...register("currency")}
                                    onChange={(event: any, newValue: any) => {
                                        setValue("currency", newValue?.value)
                                    }}
                                    style={{ padding: 0 }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="select currency" />}
                                />
                            </FormControl>
                            <span className='input_error' >{errors?.currency?.message?.toString()}</span>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                            <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                                Bank
                            </InputLabel>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={banksOptions}
                                    value={selectedBank}
                                    {...register("bank")}
                                    onChange={(event: any, newValue: any) => {
                                        setSelectedBank(findValue(newValue?.value, banksOptions, "value"));
                                        setValue("bank", newValue?.value)
                                    }}
                                    style={{ padding: 0 }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="select bank" />}
                                />
                            </FormControl>
                            <span className='input_error' >{errors?.firm?.message?.toString()}</span>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                            <Input
                                label='Invoice No.'
                                placeholder="Invoice No."
                                type='number'
                                register={register("invoiceNumber")}
                                error={errors?.invoiceNumber?.message}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleClose()
                    }} child='Close' buttonClass='contained_button_cancel' />
                    <Button
                        onClick={handleSubmit((data) => onSave(data))}
                        child='Confirm'
                        buttonClass='contained_button_add'
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default GenerateInvoiceModal