import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/Logo.png';
import MuiDrawer from '@mui/material/Drawer';
import AuthStorage from '../../helper/AuthStorage';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Index = ({ drawerOpen }: any) => {

  const navigate = useNavigate()
  const location = useLocation()

  const logout = () => {
    AuthStorage.deauthenticateUser()
    navigate('/')
  }

  const manus = [
    // {
    //   name: 'Dashboard',
    //   icon: 'space_dashboard',
    //   path: '/dashboard'
    // },
    {
      name: 'Firm',
      icon: 'home',
      path: '/firm'
    },
    {
      name: 'Clients',
      icon: 'group',
      path: '/clients'
    },
    {
      name: 'Employees',
      icon: 'person',
      path: '/employees'
    },
    {
      name: 'Assets',
      icon: 'real_estate_agent',
      path: '/assets'
    },
    {
      name: 'Expenses',
      icon: 'account_balance_wallet',
      path: '/expenses'
    },
    // {
    //   name: 'Invoice',
    //   icon: 'description',
    //   path: '/invoice'
    // },
  ]
  return (
    <Drawer variant="permanent" open={drawerOpen}>
      <DrawerHeader style={{ justifyContent: 'space-around' }}>
        <div>
          <img src={Logo} alt='' width="100px" />
        </div>
      </DrawerHeader>
      <List>
        {manus.map((text) => (
          <ListItem key={Math.random()} disablePadding sx={{ display: 'block', padding: '5px 10px' }}>
            <ListItemButton
              onClick={() => navigate(text.path)}
              className={`${location.pathname.includes(text.path) && 'sidebar_link_main_active'} sidebar_link_main`}
              sx={{
                padding: '12px', justifyContent: 'center', borderRadius: '50px', gap: '10px',
              }}
            >
              <span className='material-symbols-rounded '>{text.icon}</span>
              <ListItemText className="sidebar_link" primary={text.name} sx={{ display: drawerOpen ? 'block' : 'none' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List style={{marginTop:'auto'}}>
        <ListItem key={Math.random()} disablePadding sx={{ display: 'block', padding: '5px 10px' }}>
          <ListItemButton
            onClick={logout}
            className={`${location.pathname.includes('/') && 'sidebar_link_main_active'} sidebar_link_main`}
            sx={{
              padding: '12px', justifyContent: 'center', borderRadius: '50px', gap: '10px',
            }}
          >
            <span className='material-symbols-rounded '>logout</span>
            <ListItemText className="sidebar_link" primary={'Logout'} sx={{ display: drawerOpen ? 'block' : 'none' }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer >
  )
}

export default Index