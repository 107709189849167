const STORAGEKEY = {
  token: "token",
  company: "company",
  userData: "userData",
  userId: "userId",
  userName: "userName",
  layoutData: "layoutData",
  roles: "roles",
  email: "email",
  lang: "i18nextLng",
};

export default STORAGEKEY;
