import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../elements/Button';
import Close from '../../assets/img/close.png'
import Delete_image from '../../assets/img/recycle-bin.png'

const DeleteConfirmation = ({ open, handleClose, handleConfirm, title }: any) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='modal_header' >
                Delete {title}
                <img src={Close} alt='' onClick={handleClose} />
            </DialogTitle>
            <form>
                <DialogContent>
                    <div className='delete_modal_main'>
                        {/* <img src={Delete_image} alt='' width='100px' /> */}
                        <h1>You are deleting {title} data!</h1>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleClose()
                    }} child='Close' buttonClass='contained_button_cancel' />
                    <Button
                        onClick={() => {
                            handleConfirm()
                        }}
                        child='Confirm'
                        buttonClass='contained_button_add'
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default DeleteConfirmation