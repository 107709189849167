import React from 'react'
import noData from "../../assets/img/no-data.png"

const NoDataElement = () => {
    return (
        <div className="nodata_div">
            <img src={noData} className="nodata_img" alt="no Data" height={120} width={120} />
            <p className="nodata_text">No data found!</p>
        </div>
    )
}

export default NoDataElement