import React from 'react'
import Pages from '../pages'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const Routes = () => {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="light"
        style={{ height: "100px", width: "auto" }}
      />
      <Pages />
      {/* <ToastContainer /> */}
    </>
  )
}

export default Routes