import React from 'react'
import TextField from '@mui/material/TextField';
import { InputLabel } from '@mui/material';

interface InputProps {
    label?: string;
    placeholder: string;
    type?: string;
    name?: string;
    shrink?: boolean;
    registerProps?: any;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    register?: any;
    error?: any;
    defaultValue?: any;
    disabled?: boolean
}

const Input = ({ placeholder, type = "text", onChange, label, shrink, name,registerProps, register, error, defaultValue, disabled,...props }: InputProps) => {

    return (
        <>
            <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                {label}
            </InputLabel>
            <TextField
                size="small"
                placeholder={placeholder}
                type={type}
                className="text_input"
                fullWidth
                defaultValue={defaultValue}
                disabled={disabled}
                {...register}
                // onChange={onChange}
                autoComplete='off'
                {...props}
            />
            <span className='input_error' >{error}</span>
        </>
    )
}

export default Input