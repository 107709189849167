import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import {
  GET_EXPENSES,
  GET_EXPENSES_LOADING,
  GET_EXPENSES_ERROR,
  GET_BY_MONTH_EXPENSES,
  GET_BY_MONTH_EXPENSES_LOADING,
  GET_BY_MONTH_EXPENSES_ERROR,
  ADD_EXPENSES,
  ADD_EXPENSES_ERROR,
  ADD_EXPENSES_LOADING,
  EDIT_EXPENSES,
  EDIT_EXPENSES_ERROR,
  EDIT_EXPENSES_LOADING,
  DELETE_EXPENSES,
  DELETE_EXPENSES_ERROR,
  DELETE_EXPENSES_LOADING,
  IS_LOADING,
  GET_BY_ID_EXPENSES,
  GET_BY_ID_EXPENSES_LOADING,
  GET_BY_ID_EXPENSES_ERROR,
} from "../type";

export const getExpensesAction: any = (type: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: GET_EXPENSES_LOADING,
      payload: true,
    });

    await ApiGet(`expenses`)
      .then((res) => {
        dispatch({
          type: GET_EXPENSES,
          payload: res,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });

    dispatch({
      type: GET_EXPENSES_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: GET_EXPENSES_ERROR,
      payload: error,
    });
    dispatch({
      type: GET_EXPENSES_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      paload: false,
    });
  }
};

export const getByIdExpensesAction : any = (id : any) => async (dispatch:any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    })
    dispatch({
      type: GET_BY_ID_EXPENSES_LOADING,
      payload: true,
    })
    await ApiGet(`expenses/${id}`).then((res)=>{
      dispatch({
        type: GET_BY_ID_EXPENSES,
        payload: res,
      })
    }).catch((error)=>{
      console.log('error', error);
    })
    dispatch({
      type: IS_LOADING,
      payload: false,
    })
    dispatch({
      type: GET_BY_ID_EXPENSES_LOADING,
      payload: false,
    })
    
  } catch (error) {
    dispatch({
      type: GET_BY_ID_EXPENSES_ERROR,
      payload: error,
    })
    dispatch({
      type: GET_BY_ID_EXPENSES_LOADING,
      payload: false,
    })
    dispatch({
      type: IS_LOADING,
      paload: false,
    })
  }
}

export const getByMonthExpensesAction: any =
  (type: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: IS_LOADING,
        payload: true,
      });
      dispatch({
        type: GET_BY_MONTH_EXPENSES_LOADING,
        payload: true,
      });

      await ApiGet(`expenses/by-months`)
        .then((res) => {
          dispatch({
            type: GET_BY_MONTH_EXPENSES,
            payload: res,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

      dispatch({
        type: IS_LOADING,
        payload: false,
      });
      dispatch({
        type: GET_BY_MONTH_EXPENSES_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: GET_BY_MONTH_EXPENSES_ERROR,
        payload: error,
      });
      dispatch({
        type: GET_BY_MONTH_EXPENSES_LOADING,
        payload: false,
      });
      dispatch({
        type: IS_LOADING,
        paload: false,
      });
    }
  };

export const addExpensesAction: any = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: ADD_EXPENSES_LOADING,
      payload: true,
    });
    console.log('data', data);
    await ApiPost(`expenses/add`, data)
      .then((res) => {
        dispatch({
          type: ADD_EXPENSES,
          payload: res,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    dispatch({
      type: IS_LOADING,
      payload: false,
    });
    dispatch({
      type: ADD_EXPENSES_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: ADD_EXPENSES_ERROR,
      payload: error,
    });
    dispatch({
      type: ADD_EXPENSES_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      paload: false,
    });
  }
};

export const editExpensesAction: any =
  (id: any, body: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: IS_LOADING,
        payload: true,
      });
      dispatch({
        type: EDIT_EXPENSES_LOADING,
        payload: true,
      });
      await ApiPost(`expenses/edit/${id}`, body)
        .then((res) => {
          dispatch({
            type: EDIT_EXPENSES,
            payload: res,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
      dispatch({
        type: EDIT_EXPENSES_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: EDIT_EXPENSES_ERROR,
        payload: error,
      });
      dispatch({
        type: EDIT_EXPENSES_LOADING,
        payload: false,
      });
      dispatch({
        type: IS_LOADING,
        paload: false,
      });
    }
  };

export const deleteExpensesAction: any = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: DELETE_EXPENSES_LOADING,
      payload: true,
    });
    await ApiPost(`expenses/delete/${id}`,{})
      .then((res: any) => {
        dispatch({
          type: DELETE_EXPENSES,
          payload: res,
        });
        if (res.status === 200) {
          dispatch(getExpensesAction());
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  } catch (error) {
    dispatch({
      type: DELETE_EXPENSES_ERROR,
      payload: error,
    });
    dispatch({
      type: DELETE_EXPENSES_LOADING,
      payload: false,
    });
    dispatch({
      type: IS_LOADING,
      paload: false,
    });
  }
};
