import { ApiPost} from "../../helper/API/ApiData"
import { IS_LOADING, LOGIN, LOGIN_ERROR, LOGIN_LOADING } from "../type";


export const loginAction: any =
    (body: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: LOGIN_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPost("user/login", body)
                .then((res: any) => {
                    dispatch({
                        type: LOGIN,
                        payload: res,
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: LOGIN,
                        payload: error,
                    });
                });

            dispatch({
                type: LOGIN_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: LOGIN_ERROR,
                payload: err,
            });

            dispatch({
                type: LOGIN_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };