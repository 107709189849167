import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/elements/Button';
import Table from '../../components/elements/Table';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmployeesAction, getAllEmployeesAction } from '../../redux/actions/employeesAction';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Action from '../../assets/img/table_action.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DELETE_EMPLOYEES } from '../../redux/type';
import DeleteConfirmation from '../../components/modals/DeleteConfirmation';
import { Delete, Edit } from '@mui/icons-material';

const Index = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [employeesData, setemployeesData] = useState([]);
  const [employeesId, setemployeesID] = useState<any>('')
  const [openModal, setOpenModal] = useState(false);
  const getEmployeesData = useSelector((state: any) => state.getAllEmployees.getAllEmployees);
  const deleteEmployees = useSelector((state: any) => state.deleteEmployees.deleteEmployees);

  useEffect(() => {
    setemployeesData((getEmployeesData && getEmployeesData.data?.length) ? getEmployeesData?.data : [])
  }, [getEmployeesData])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id: any) => {
    setAnchorEl(null);
  };

  const editData = (id: any) => {
    navigate(`edit/${id}`)
    handleClose(id);
    setAnchorEl(null);
  }

  const handleDelete = () => {
    if (employeesId) {
      dispatch(deleteEmployeesAction(employeesId))
    }
  }

  useEffect(() => {
    if (deleteEmployees) {
      if (deleteEmployees.status === 200) {
        toast.success(deleteEmployees.message)
        setOpenModal(false)
      } else {
        toast.error(deleteEmployees.message)
      }
      dispatch({
        type: DELETE_EMPLOYEES,
        payload: ''
      })
    }
  }, [deleteEmployees])

  const columns: GridColDef[] = [
    { field: 'index', headerName: 'No', width: 100 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'number', headerName: 'Number', flex: 1 },
    // { field: 'dateOfBirth', headerName: 'DOB', flex: 1, renderCell: ((row: any) => { return <>{console.log('row', row)}</> }), },
    { field: 'dateOfBirth', headerName: 'DOB', flex: 1, renderCell: (({ row }: any) => { return <>{moment(row.dateOfBirth).format("DD/MM/YYYY")}</> }), },
    { field: 'email', headerName: 'Personal Email', flex: 1 },
    { field: 'technology', headerName: 'Technology', flex: 1 },
    { field: 'salary', headerName: 'Salary', flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      renderCell: ({ row }) => {
        return (
          <>
            <div className='table_actions' >
              <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => editData(row._id)} />
              <Delete color='error' fontSize="small" style={{ margin: "5px" }}
                onClick={() => {
                  setemployeesID(row._id)
                  setOpenModal(true)
                }}
              />
            </div>
          </>
        )
      },
      // getActions: ({ id }) => {
      //   return [
      //     <GridActionsCellItem
      //       icon={
      //         <div className='table_actions' >
      //           <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => editData(id)} />
      //           <Delete color='error' fontSize="small" style={{ margin: "5px" }}
      //             onClick={() => {
      //               setemployeesID(id)
      //               setOpenModal(true)
      //             }}
      //           />
      //         </div>
      //         // <div>
      //         //   <IconButton
      //         //     aria-label="more"
      //         //     id="long-button"
      //         //     aria-controls={open ? 'long-menu' : undefined}
      //         //     aria-expanded={open ? 'true' : undefined}
      //         //     aria-haspopup="true"
      //         //     onClick={(e) => {
      //         //       setemployeesID(id)
      //         //       handleClick(e)
      //         //     }}
      //         //   >
      //         //     <img src={Action} alt='' />
      //         //   </IconButton>
      //         //   <Menu
      //         //     id="long-menu"
      //         //     MenuListProps={{
      //         //       'aria-labelledby': 'long-button',
      //         //     }}
      //         //     anchorEl={anchorEl}
      //         //     open={open}
      //         //     onClose={(e) => handleClose(id)}
      //         //     PaperProps={{
      //         //       style: {
      //         //         maxHeight: '150px',
      //         //         width: '20ch',
      //         //       },
      //         //     }}
      //         //   >
      //         //     <MenuItem onClick={(e) => editData(id)}>
      //         //       Edit
      //         //     </MenuItem>
      //         //     <MenuItem onClick={() => {
      //         //       setOpenModal(true)
      //         //       setAnchorEl(null);
      //         //     }
      //         //     }>
      //         //       Delete
      //         //     </MenuItem>
      //         //   </Menu>
      //         // </div >
      //       }
      //       label="Edit"
      //       className="textPrimary"
      //       onClick={() => { }}
      //       color="inherit"
      //     />
      //   ];
      // },
    },
  ];

  useEffect(() => {
    dispatch(getAllEmployeesAction())
  }, [])

  return (
    <div className=''>
      <div className='card_header'>
        <h1 className='card_header_title'>Employes</h1>
        <div className='card_footer'>
          <Button
            onClick={() => navigate('add')}
            variant="outlined"
            buttonClass='contained_button_add'
            child='Add' />
        </div>
      </div>
      <Table
        rows={employeesData?.map((item: any, i: number) => { return { ...item, id: item._id ?? i + 1, index: i + 1 } })}
        columns={columns}
      />
      {openModal && <DeleteConfirmation open={openModal} title='employee' handleClose={(data: any) => setOpenModal(false)} handleConfirm={() => handleDelete()} />}
    </div>
  )
}

export default Index