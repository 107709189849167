import React, { forwardRef, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Input from '../../../components/elements/Input';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addFirmAction, editFirmAction, getByIdFirmAction } from '../../../redux/actions/firmAction';
import Button from '../../../components/elements/Button';
import { ADD_FIRM, EDIT_FIRM, GET_BY_ID_FIRM } from '../../../redux/type';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import countryData from '../../../countriesStatesCities.json';
import { InputLabel } from '@mui/material';
import { Close } from '@mui/icons-material';

interface Props {
    registerValue: any;
}

const Details = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id ?? localStorage.getItem("firmId")
    const [signDocument, setSignDocument] = useState<any>();
    const [signDocumentName, setSignDocumentName] = useState<any>("");
    const [countryOptions, setCountryOptions] = useState<any[]>([])
    const [stateOptions, setStateOptions] = useState<any[]>([])
    const [bankData, setBankData] = useState<any[]>([])
    const [country, setCountry] = useState<any>('')
    const [state, setState] = useState<any>('')

    const getByIdFirmData = useSelector((state: any) => state.getByIdFirm.getByIdFirm?.data);
    const editFirmData = useSelector((state: any) => state.editFirm.editFirm);
    const addFirmData = useSelector((state: any) => state.addFirm.addFirm);

    let firmSchema = yup.object().shape({
        name: yup.string().required('Please enter name'),
        GST: yup.string().matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            "please enter valid GST number"
        ),
        reverseCharge: yup.string(),
        PAN: yup.string(),
        LUTNumber: yup.string(),
        address: yup.string(),
        address2: yup.string(),
        city: yup.string(),
        state: yup.string(),
        country: yup.string(),
        pincode: yup.string(),
        // signDocument: yup.string(),
    });

    const { register, handleSubmit, formState: { errors }, reset, setValue, setError } = useForm({
        resolver: yupResolver(firmSchema),
    });


    useEffect(() => {
        let options = []
        for (let i = 0; i < countryData.length; i++) {
            const element = countryData[i];
            options.push({ label: element.country, value: element.country })
        }
        setCountryOptions(options)
    }, [])

    useEffect(() => {
        if (country) {
            let options = []
            let selectedCountryStates = countryData.find((item: any) => item.country === country)?.states ?? []
            for (let i = 0; i < selectedCountryStates.length; i++) {
                const element = selectedCountryStates[i];
                options.push({ label: element, value: element })
            }
            setStateOptions(options)
        }
    }, [country, countryData])


    useEffect(() => {
        reset(
            {
                name: getByIdFirmData?.name,
                GST: getByIdFirmData?.GST,
                reverseCharge: getByIdFirmData?.reverseCharge,
                PAN: getByIdFirmData?.PAN,
                LUTNumber: getByIdFirmData?.LUTNumber,
                // firmDocument: getByIdFirmData?.firmDocument,
                address: getByIdFirmData?.address,
                address2: getByIdFirmData?.address2,
                address3: getByIdFirmData?.address3,
                // city: getByIdFirmData?.city,
                // state: getByIdFirmData?.state,
                country: getByIdFirmData?.country,
                // pincode: getByIdFirmData?.pincode
            }
        )
        setCountry(getByIdFirmData?.country)
        setBankData(getByIdFirmData?.banks)
        setSignDocumentName(getByIdFirmData?.signDocument?.split("/signDocument/").pop())
        // setState(getByIdFirmData?.state)
    }, [getByIdFirmData])


    useEffect(() => {
        return (() => {
            dispatch({
                type: GET_BY_ID_FIRM,
                payload: '',
            });
        })
    }, [])

    useEffect(() => {
        if (editFirmData) {
            if (editFirmData.status === 200) {
                // setBankData([])
                // navigate('/firm')
                toast.success(editFirmData.message)
            } else {
                toast.error(editFirmData.message)
            }
            dispatch({
                type: EDIT_FIRM,
                payload: '',
            });
        }
    }, [editFirmData])
    useEffect(() => {
        if (id) {
            dispatch(getByIdFirmAction(id))
        }
    }, [id, editFirmData]);

    useEffect(() => {
        if (addFirmData) {
            if (addFirmData.status === 200) {
                // setBankData([])
                navigate(`/firm`)
                // localStorage.setItem("firmId", addFirmData?.data?._id)
                toast.success(addFirmData.message)
            } else {
                toast.error(addFirmData.message)
            }
            dispatch({
                type: ADD_FIRM,
                payload: '',
            });
        }
    }, [addFirmData])

    // useEffect(() => {
    //     // if (getByIdFirmData?.banks)
    //     //     setBankData(getByIdFirmData?.banks)
    //     // else
    //     //     setBankData([])

    //     reset(
    //         {
    //             name: getByIdFirmData?.name,
    //             GST: getByIdFirmData?.GST,
    //             reverseCharge: getByIdFirmData?.reverseCharge,
    //             PAN: getByIdFirmData?.PAN,
    //             LUTNumber: getByIdFirmData?.LUTNumber,
    //             firmDocument: getByIdFirmData?.firmDocument ?? null,
    //             address: getByIdFirmData?.address,
    //             city: getByIdFirmData?.city,
    //             state: getByIdFirmData?.state,
    //             country: getByIdFirmData?.country,
    //             pincode: getByIdFirmData?.pincode
    //         }
    //     )
    // }, [getByIdFirmData])


    const onSave = (data: any) => {
        const formData = new FormData();
        formData.append('signDocument', signDocument)
        delete data.signDocument
        for (const key in data) {
            formData.append(`${key}`, data[key])
        }
        // for (let i = 0; i < bankData.length; i++) {
        //     const element = bankData[i];
        //     delete element._id
        //     for (const key in element) {
        //         formData.append(`banks[${i}][${key}]`, bankData[i][key])
        //     }
        // }
        dispatch(addFirmAction(formData))
    }

    const onEdit = (data: any) => {
        const formData = new FormData();
        console.log('signDocument', signDocument)
        console.log('data', data)

        formData.append('signDocument', signDocument)
        delete data.signDocument

        for (const key in data) {
            formData.append(`${key}`, data[key])
        }
        for (let i = 0; i < bankData.length; i++) {
            const element = bankData[i];
            delete element._id
            for (const key in element) {
                formData.append(`banks[${i}][${key}]`, bankData[i][key])
            }
        }
        dispatch(editFirmAction(id, formData))
    }

    return (
        <>
            <div className='card_footer'>
                <Button
                    variant="outlined"
                    buttonClass='contained_button_cancel'
                    onClick={() => navigate('/firm')}
                    child='Cancel' />
                <Button
                    onClick={handleSubmit((data) => {
                        if (id)
                            onEdit(data)
                        else
                            onSave(data)
                    })}
                    variant="outlined"
                    buttonClass='contained_button_add'
                    child={`${id ? 'Save' : 'Add'}`} />
            </div>
            <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Name'
                        placeholder="Name"
                        type='text'
                        register={register("name")}
                        error={errors?.name?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='GST'
                        placeholder="GST"
                        type='text'
                        register={register("GST")}
                        error={errors?.GST?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Reverse charge'
                        placeholder="Reverse Charge"
                        type='number'
                        register={register("reverseCharge")}
                        error={errors?.reverseCharge?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='PAN'
                        placeholder="PAN"
                        type='text'
                        register={register("PAN")}
                        error={errors?.PAN?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='LUT'
                        placeholder="LUT"
                        type='text'
                        register={register("LUTNumber")}
                        error={errors?.LUTNumber?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Address Line'
                        placeholder="Address"
                        register={register("address")}
                        error={errors?.address?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Address Line 2'
                        placeholder="Address"
                        register={register("address2")}
                        error={errors?.address2?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Address Line 3'
                        placeholder="City, State, Pincode"
                        register={register("address3")}
                        error={errors?.address2?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12} >
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        Country
                    </InputLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={countryOptions || []}
                        value={country}
                        {...register("country")}
                        onChange={(event: any, newValue: any) => {
                            setCountry(newValue?.value);
                            setValue("country", newValue?.value)
                            setStateOptions([])
                            setState('')
                            reset({
                                state: ''
                            })
                        }}
                        style={{ padding: 0 }}
                        renderInput={(params) => <TextField
                            {...params}
                            placeholder="Country" />}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12}>
                    <Input
                        label='Select sign file'
                        placeholder=""
                        type='file'
                        register={register("signDocument", {
                            onChange: (e: any) => {
                                setSignDocument(e.target.files[0])
                            }
                        })}
                        error={errors?.signDocument?.message}
                    />
                    {/* {console.log('signDocument', signDocument)} */}
                    {signDocumentName &&
                        <div className='file_box'>
                            < Close style={{ fontSize: "16px", color: "red", cursor: "pointer", marginRight: "5px" }} onClick={(e) => { setValue("signDocument", ""); setSignDocumentName("") }} />
                            <InputLabel className='file_label' shrink htmlFor="bootstrap-input">
                                {signDocumentName}
                            </InputLabel>
                        </div>
                    }
                </Grid>
                {/* <Grid item xs={12} lg={4} xl={3} md={6} sm={12} >
                    <InputLabel className='input_label' shrink htmlFor="bootstrap-input">
                        State
                    </InputLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={stateOptions}
                        value={state ?? ''}
                        {...register("state")}
                        onChange={(event: any, newValue: any) => {
                            setState(newValue?.value)
                            setValue("state", newValue?.value)
                        }}
                        style={{ padding: 0 }}
                        renderInput={(params) => <TextField
                            {...params}
                            placeholder="State" />
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12} >
                    <Input
                        label='City'
                        placeholder="City"
                        register={register("city")}
                        error={errors?.city?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3} md={6} sm={12} >
                    <Input
                        label='Pin'
                        placeholder="Pin"
                        register={register("pincode")}
                        error={errors?.pincode?.message}
                    />
                </Grid> */}

            </Grid>
        </>
    )
}

export default Details