// const protocol = 'http';
const protocol = process.env.REACT_APP_PROTOCOL;
const host = process.env.REACT_APP_HOST;
// const host = "192.168.1.8";
const port = process.env.REACT_APP_PORT;
// const port = '50';
// const trailUrl = process.env.REACT_APP_TAILURL;

const hostUrl = `${host}/`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}`;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  host: host,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
