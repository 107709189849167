import {
  GET_ASSETS,
  GET_ASSETS_ERROR,
  GET_ASSETS_LOADING,
  GET_BY_ID_ASSETS,
  GET_BY_ID_ASSETS_LOADING,
  GET_BY_ID_ASSETS_ERROR,
  ADD_ASSETS,
  ADD_ASSETS_ERROR,
  ADD_ASSETS_LOADING,
  EDIT_ASSETS,
  EDIT_ASSETS_ERROR,
  EDIT_ASSETS_LOADING,
  DELETE_ASSETS,
  DELETE_ASSETS_ERROR,
  DELETE_ASSETS_LOADING,
  GET_BY_MONTH_ASSETS,
  GET_BY_MONTH_ASSETS_ERROR,
  GET_BY_MONTH_ASSETS_LOADING
} from "../type";

const getAssetsData: any = {
  getAssetsLoading: false,
  getAssets: [],
  getAssetsError: "",
};

const getByIdAssetsData: any = {
  getByIdAssetsLoading: false,
  getByIdAssets: null,
  getByIdAssetsError: "",
};

const getByMonthAssetsData: any = {
  getByMonthAssetsLoading: false,
  getByMonthAssets: [],
  getByMonthAssetsError: "",
};

const addAssetsData : any = {
  addAssetsLoading: false,
  addAssets:null,
  addAssetsError: "",
}

const editAssetsData:any = {
  editAssetsLoading: false,
  editAssets: null,
  editAssetsError: "",
}

const deleteAssetsData : any = {
  deleteAssetsLoading: false,
  deleteAssets: null,
  deleteAssetsError: "",
}

export const getAssetsReducer = (state = getAssetsData, action: any) => {
  switch (action.type) {
    case GET_ASSETS_LOADING:
      return {
        ...state,
        getAssetsLoading: action.payload,
      };
    case GET_ASSETS:
      return {
        ...state,
        getAssets: action.payload,
      };
    case GET_ASSETS_ERROR:
      return {
        ...state,
        getAssetsError: action.payload,
      };
    default:
      return state;
  }
};

export const getByIdAssetsReeducer = (state=getByIdAssetsData,action:any) => {
  switch (action.type) {
    case GET_BY_ID_ASSETS_LOADING:
      return {
        ...state,
        getByIdAssetsLoading: action.payload,
      }
    case GET_BY_ID_ASSETS:
      return {
        ...state,
        getByIdAssets: action.payload,
      }
    case GET_BY_ID_ASSETS_ERROR:
      return {
        ...state,
        getByIdAssetsError: action.payload,
      }
    default:
      return state;
  }
}

export const getBYMonthAssetsReducer = (state=getByMonthAssetsData,action:any) => {
    switch (action.type) {
      case GET_BY_MONTH_ASSETS_LOADING:
        return {
          ...state,
          getByMonthAssetsLoading: action.payload,
        }
      case GET_BY_MONTH_ASSETS:
        return {
          ...state,
          getByMonthAssets: action.payload,
        }
      case GET_BY_MONTH_ASSETS_ERROR:
        return {
          ...state,
          getByMonthAssetsError: action.payload,
        }
      default:
        return state;
    }
}

export const addAssetsReducer = (state=addAssetsData,action:any) => {
  switch (action.type) {
    case ADD_ASSETS_LOADING:
      return {
        ...state,
        addAssetsLoading: action.payload,
      }
    case ADD_ASSETS:
      return {
        ...state,
        addAssets: action.payload,
      }
    case ADD_ASSETS_ERROR:
      return {
        ...state,
        addAssetsError: action.payload,
      }
    default:
      return state;
  }
}
export const editAssetsReducer = (state=editAssetsData,action:any) => {
  switch (action.type) {
    case EDIT_ASSETS_LOADING:
      return {
        ...state,
        editAssetsLoading: action.payload,
      }
    case EDIT_ASSETS:
      return {
        ...state,
        editAssets: action.payload,
      }
    case EDIT_ASSETS_ERROR:
      return {
        ...state,
        editAssetsError: action.payload,
      }
    default:
      return state;
  }
}
export const deleteAssetsReducer = (state=deleteAssetsData,action:any) => {
  switch (action.type) {
    case DELETE_ASSETS_LOADING:
      return {
        ...state,
        deleteAssetsLoading: action.payload,
      }
    case DELETE_ASSETS:
      return {
        ...state,
        deleteAssets: action.payload,
      }
    case DELETE_ASSETS_ERROR:
      return {
        ...state,
        deleteAssetsError: action.payload,
      }
    default:
      return state;
  }

}
