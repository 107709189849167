import { IS_LOADING } from "../type";

const loginData: any = {
    loading: false,
}

export const loadingReducer = (state = loginData, action: any) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state;
    }
}
