import { ApiGet, ApiPost, ApiPostWithFormData } from "../../helper/API/ApiData";
import { ADD_EMPLOYEES, ADD_EMPLOYEES_ERROR, ADD_EMPLOYEES_LOADING, DELETE_EMPLOYEES, DELETE_EMPLOYEES_ERROR, DELETE_EMPLOYEES_LOADING, EDIT_EMPLOYEES, EDIT_EMPLOYEES_ERROR, EDIT_EMPLOYEES_LOADING, GET_ALL_EMPLOYEES, GET_ALL_EMPLOYEES_ERROR, GET_ALL_EMPLOYEES_LOADING, GET_BY_ID_EMPLOYEES, GET_BY_ID_EMPLOYEES_ERROR, GET_BY_ID_EMPLOYEES_LOADING, IS_LOADING } from "../type";


export const getAllEmployeesAction: any =
    (type: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: GET_ALL_EMPLOYEES_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiGet(`employees`)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_EMPLOYEES,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: GET_ALL_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: GET_ALL_EMPLOYEES_ERROR,
                payload: err,
            });

            dispatch({
                type: GET_ALL_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };

export const addEmployeesAction: any =
    (data: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: ADD_EMPLOYEES_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPostWithFormData(`employees/add`, data)
                .then((res) => {
                    dispatch({
                        type: ADD_EMPLOYEES,
                        payload: res,
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: ADD_EMPLOYEES,
                        payload: error,
                    });
                });

            dispatch({
                type: ADD_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: ADD_EMPLOYEES_ERROR,
                payload: err,
            });

            dispatch({
                type: ADD_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };

export const deleteEmployeesAction: any =
    (data: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: DELETE_EMPLOYEES_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiGet(`employees/delete/${data}`)
                .then((res: any) => {
                    dispatch({
                        type: DELETE_EMPLOYEES,
                        payload: res,
                    });
                    if (res.status === 200) {
                        dispatch(getAllEmployeesAction())
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: DELETE_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: DELETE_EMPLOYEES_ERROR,
                payload: err,
            });

            dispatch({
                type: DELETE_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };

export const getByIdEmployeesAction: any =
    (id: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: GET_BY_ID_EMPLOYEES_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiGet(`employees/${id}`)
                .then((res: any) => {
                    dispatch({
                        type: GET_BY_ID_EMPLOYEES,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: GET_BY_ID_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: GET_BY_ID_EMPLOYEES_ERROR,
                payload: err,
            });

            dispatch({
                type: GET_BY_ID_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };

export const editEmployeesAction: any =
    (id: any, body: any) => async (dispatch: any) => {
        try {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            dispatch({
                type: EDIT_EMPLOYEES_LOADING,
                payload: true,
            });

            // await ApiGet(`enquiry?status=${status}`)
            await ApiPost(`employees/edit/${id}`, body)
                .then((res: any) => {
                    dispatch({
                        type: EDIT_EMPLOYEES,
                        payload: res,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            dispatch({
                type: EDIT_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        } catch (err) {
            dispatch({
                type: EDIT_EMPLOYEES_ERROR,
                payload: err,
            });

            dispatch({
                type: EDIT_EMPLOYEES_LOADING,
                payload: false,
            });

            dispatch({
                type: IS_LOADING,
                payload: false,
            });
        }
    };