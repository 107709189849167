import React, { useEffect, useState } from "react";
import Button from "../../components/elements/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/elements/Table";
import { GridColDef } from "@mui/x-data-grid";
import { Delete, Edit } from "@mui/icons-material";
import {
  getExpensesAction,
  deleteExpensesAction,
} from "../../redux/actions/expensesAction";
import DeleteConfirmation from "../../components/modals/DeleteConfirmation";
import ExpensesByMonth from "./ExpensesByMonth";
import moment from "moment";
import { toast } from "react-toastify";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [expensesData, setExpensesData] = useState([]);
  const [expensesBy, setExpensesBy] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [clintId, setClintId] = useState<any>("");

  const getExpensesData = useSelector(
    (state: any) => state.getExpenses.getExpenses
  );

  const deleteExpensesData = useSelector(
    (state: any) => state.deleteExpenses.deleteExpenses
  );

  useEffect(() => {
    dispatch(getExpensesAction());
  }, []);

  useEffect(() => {
    if (deleteExpensesData?.status === 200) {
      toast.success(deleteExpensesData?.message);
      setClintId("");
      setOpenModal(false);
      dispatch({
        type: "DELETE_EXPENSES",
        payload: null,
      });
    } else {
      toast.error(deleteExpensesData?.message);
    }
  }, [deleteExpensesData]);

  useEffect(() => {
    setExpensesData(
      getExpensesData && getExpensesData.data?.length
        ? getExpensesData.data
        : []
    );
  }, [getExpensesData]);

  const handleDelete = () => {
    if (clintId) {
      // console.log('clintId', clintId);
      dispatch(deleteExpensesAction(clintId));
    }
  };

  const handleEdit = (id: any) => {
    // console.log('id', id);
    navigate(`edit/${id}`);
  }

  const columns: GridColDef[] = [
    { field: "index", headerName: "No", width: 100 },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{moment(row.date).format("DD/MM/YYYY")}</>;
      },
    },
    { field: "note", headerName: "note", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "paymentType", headerName: "paymentType", flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: ({ row }) => {
        return (
          <>
            <div className="table_actions">
              <Edit
                fontSize="small"
                style={{ margin: "5px" }}
                onClick={(e) => { handleEdit(row._id) }}
              />
              <Delete
                color="error"
                fontSize="small"
                style={{ margin: "5px" }}
                onClick={() => {
                  setClintId(row._id);
                  setOpenModal(true);
                }}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="">
      <div className="card_header">
        <h1 className="card_header_title">Expenses</h1>
        <div className="card_footer">
          <Button
            onClick={() => navigate("add")}
            variant="outlined"
            buttonClass="contained_button_add"
            child="Add"
          />
          <Button
            onClick={() => setExpensesBy(!expensesBy)}
            variant="outlined"
            buttonClass="contained_button_add"
            child={`${!expensesBy ? "Expenses by month" : "Expenses by list"}`}
          />
        </div>
      </div>
      {expensesBy ? (
        <ExpensesByMonth />
      ) : (
        <Table
          rows={expensesData?.map((item: any, i: number) => {
            return { ...item, id: item._id ?? i + 1, index: i + 1 };
          })}
          columns={columns}
        />
      )}
      {openModal && (
        <DeleteConfirmation
          open={openModal}
          title="assets"
          handleClose={(data: any) => setOpenModal(false)}
          handleConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default Index;
