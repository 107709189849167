import { toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import {
  ADD_CLIENTS,
  ADD_CLIENTS_ERROR,
  ADD_CLIENTS_LOADING,
  DELETE_CLIENTS,
  DELETE_CLIENTS_ERROR,
  DELETE_CLIENTS_LOADING,
  EDIT_CLIENTS,
  EDIT_CLIENTS_ERROR,
  EDIT_CLIENTS_LOADING,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_ERROR,
  GET_ALL_CLIENTS_LOADING,
  GET_BY_ID_CLIENTS,
  GET_BY_ID_CLIENTS_ERROR,
  GET_BY_ID_CLIENTS_LOADING,
  IS_LOADING,
} from "../type";

export const getAllClientsAction: any =
  (type: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: IS_LOADING,
        payload: true,
      });
      dispatch({
        type: GET_ALL_CLIENTS_LOADING,
        payload: true,
      });

      // await ApiGet(`enquiry?status=${status}`)
      await ApiGet(`client`)
        .then((res) => {
          dispatch({
            type: GET_ALL_CLIENTS,
            payload: res,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      dispatch({
        type: GET_ALL_CLIENTS_LOADING,
        payload: false,
      });

      dispatch({
        type: IS_LOADING,
        payload: false,
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_CLIENTS_ERROR,
        payload: err,
      });

      dispatch({
        type: GET_ALL_CLIENTS_LOADING,
        payload: false,
      });

      dispatch({
        type: IS_LOADING,
        payload: false,
      });
    }
  };

export const addClientsAction: any = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: ADD_CLIENTS_LOADING,
      payload: true,
    });

    // await ApiGet(`enquiry?status=${status}`)
    await ApiPost(`client/add`, data)
      .then((res) => {
        dispatch({
          type: ADD_CLIENTS,
          payload: res,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch({
      type: ADD_CLIENTS_LOADING,
      payload: false,
    });

    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: ADD_CLIENTS_ERROR,
      payload: err,
    });

    dispatch({
      type: ADD_CLIENTS_LOADING,
      payload: false,
    });

    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  }
};

export const getByIdClientsAction: any = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: GET_BY_ID_CLIENTS_LOADING,
      payload: true,
    });

    await ApiGet(`client/${id}`)
      .then((res: any) => {
        dispatch({
          type: GET_BY_ID_CLIENTS,
          payload: res,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch({
      type: GET_BY_ID_CLIENTS_LOADING,
      payload: false,
    });

    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: GET_BY_ID_CLIENTS_ERROR,
      payload: err,
    });

    dispatch({
      type: GET_BY_ID_CLIENTS_LOADING,
      payload: false,
    });

    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  }
};

export const editClientsAction: any =
  (id: any, body: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: IS_LOADING,
        payload: true,
      });
      dispatch({
        type: EDIT_CLIENTS_LOADING,
        payload: true,
      });

      // await ApiGet(`enquiry?status=${status}`)
      await ApiPost(`client/edit/${id}`, body)
        .then((res: any) => {
          dispatch({
            type: EDIT_CLIENTS,
            payload: res,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      dispatch({
        type: EDIT_CLIENTS_LOADING,
        payload: false,
      });

      dispatch({
        type: IS_LOADING,
        payload: false,
      });
    } catch (err) {
      dispatch({
        type: EDIT_CLIENTS_ERROR,
        payload: err,
      });

      dispatch({
        type: EDIT_CLIENTS_LOADING,
        payload: false,
      });

      dispatch({
        type: IS_LOADING,
        payload: false,
      });
    }
  };

export const deleteClientsAction: any = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    dispatch({
      type: DELETE_CLIENTS_LOADING,
      payload: true,
    });

    // await ApiGet(`enquiry?status=${status}`)
    await ApiGet(`client/delete/${id}`)
      .then((res: any) => {
        dispatch({
          type: DELETE_CLIENTS,
          payload: res,
        });
        if (res.status === 200) {
          dispatch(getAllClientsAction());
        }
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch({
      type: DELETE_CLIENTS_LOADING,
      payload: false,
    });

    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: DELETE_CLIENTS_ERROR,
      payload: err,
    });

    dispatch({
      type: DELETE_CLIENTS_LOADING,
      payload: false,
    });

    dispatch({
      type: IS_LOADING,
      payload: false,
    });
  }
};
