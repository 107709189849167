import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { addFirmAssetsReducer, addFirmBanksReducer, addFirmReducer, deleteFirmAssetsReducer, deleteFirmBanksReducer, deleteFirmReducer, editFirmAssetsReducer, editFirmBanksReducer, editFirmReducer, getAllFirmReducer, getByIdFirmAssetsReducer, getByIdFirmBanksReducer, getByIdFirmReducer } from "./redusers/firmReducer";
import { loginReducer } from "./redusers/loginReducer";
import { addClientsReducer, deleteClientsReducer, editClientsReducer, getAllClientsReducer, getByIdClientsReducer } from "./redusers/clientsReducer";
import { addEmployeesReducer, deleteEmployeesReducer, editEmployeesReducer, getAllEmployeesReducer, getByIdEmployeesReducer } from "./redusers/employeesReducer";
import { addInvoiceReducer, deleteInvoiceReducer, editInvoiceReducer, getAllInvoiceReducer, getInvoiceByFirmIdReducer, getInvoiceByIdReducer } from "./redusers/invoiceReducer";
import { getAssetsReducer, getBYMonthAssetsReducer , addAssetsReducer , editAssetsReducer , deleteAssetsReducer, getByIdAssetsReeducer } from "./redusers/assetsReducer";
import { getByMonthExpensesReducer, getExpensesReducer , addExpensesReducer , editExpensesReducer , deleteExpensesReducer, getByIdExpensesReducer  } from "./redusers/expensesReducer";
import { loadingReducer } from "./redusers/loadingReducer";

const middleware = [thunk];

const rootReducer = combineReducers({
  loading: loadingReducer,
  login: loginReducer,
  //Firm
  getAllFirm: getAllFirmReducer,
  addFirm: addFirmReducer,
  deleteFirm: deleteFirmReducer,
  getByIdFirm: getByIdFirmReducer,
  editFirm: editFirmReducer,
  getByIdFirmAssets: getByIdFirmAssetsReducer,
  addFirmAssets: addFirmAssetsReducer,
  deleteFirmAssets: deleteFirmAssetsReducer,
  editFirmAssets: editFirmAssetsReducer,
  getByIdFirmBanks: getByIdFirmBanksReducer,
  addFirmBanks: addFirmBanksReducer,
  deleteFirmBanks: deleteFirmBanksReducer,
  editFirmBanks: editFirmBanksReducer,
  // Clients
  getAllClients: getAllClientsReducer,
  addClients: addClientsReducer,
  getByIdClients: getByIdClientsReducer,
  editClients: editClientsReducer,
  deleteClients: deleteClientsReducer,
  //employees
  getAllEmployees: getAllEmployeesReducer,
  addEmployees: addEmployeesReducer,
  deleteEmployees: deleteEmployeesReducer,
  getByIdEmployees: getByIdEmployeesReducer,
  editEmployees: editEmployeesReducer,
  //assets
  getAssets : getAssetsReducer,
  getByIdAssets : getByIdAssetsReeducer,
  getByMonthAssets : getBYMonthAssetsReducer,
  addAssets : addAssetsReducer,
  editAssets : editAssetsReducer,
  deleteAssets : deleteAssetsReducer,
  //Invoice
  getAllInvoice: getAllInvoiceReducer,
  getInvoiceById: getInvoiceByIdReducer,
  getInvoiceByFirmId: getInvoiceByFirmIdReducer,
  addInvoice: addInvoiceReducer,
  editInvoice: editInvoiceReducer,
  deleteInvoice: deleteInvoiceReducer,
  //expanses
  getExpenses : getExpensesReducer,
  getByIdExpenses : getByIdExpensesReducer,
  getByMonthExpenses:getByMonthExpensesReducer,
  addExpenses : addExpensesReducer,
  editExpenses : editExpensesReducer,
  deleteExpenses : deleteExpensesReducer
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
