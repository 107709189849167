import React, { useEffect, useState } from 'react'
import Table from '../../../components/elements/Table'
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Action from '../../../assets/img/table_action.png'
import Button from '../../../components/elements/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFirmAssetsAction, getByIdFirmAssetsAction } from '../../../redux/actions/firmAction';
import AssetsModal from '../../../components/modals/AssetsModal';
import { toast } from 'react-toastify';
import { ADD_FIRM_ASSETS, DELETE_FIRM_ASSETS, EDIT_FIRM_ASSETS } from '../../../redux/type';
import DeleteConfirmation from '../../../components/modals/DeleteConfirmation';
import { Delete, Edit } from '@mui/icons-material';

const Assets = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();

    const id = params.id ?? localStorage.getItem("firmId")
    const firmId = id
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [editAssetsData, setEditAssetsData] = useState<any>({});
    const [assetsDataId, setAssetsDataId] = useState<any>('')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const getByIdFirmAssets = useSelector((state: any) => state.getByIdFirmAssets.getByIdFirmAssets);
    const deleteFirmAssets = useSelector((state: any) => state.deleteFirmAssets.deleteFirmAssets);
    const editFirmAssets = useSelector((state: any) => state.editFirmAssets.editFirmAssets);
    const addFirmAssets = useSelector((state: any) => state.addFirmAssets.addFirmAssets);

    const getAllFirmAssets = () => {
        dispatch(getByIdFirmAssetsAction(id))
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (id: any) => {
        setAnchorEl(null);
    };

    const editAssets = (id: any) => {
        console.log('gid ', id)
        console.log('getByIdFirmAssets?.data?.find((item: any) => item._id === assetsDataId)', getByIdFirmAssets?.data?.find((item: any) => item._id === id))
        setEditAssetsData({ ...getByIdFirmAssets?.data?.find((item: any) => item._id === id), _id: id })
        setOpenModal(true)
        handleClose(id)
    };

    const closeModal = () => {
        setOpenModal(false)
        setEditAssetsData('')
        // getAllFirmAssets()
    }

    const handleDelete = () => {
        const data = {
            id: assetsDataId
        }
        dispatch(deleteFirmAssetsAction(firmId, data))
        setAnchorEl(null);
        setOpenDeleteModal(false)
    }

    useEffect(() => {
        getAllFirmAssets()
    }, [])

    useEffect(() => {
        if (deleteFirmAssets) {
            if (deleteFirmAssets.status === 200) {
                toast.success(deleteFirmAssets.message)
                setOpenModal(false)
            } else {
                toast.error(deleteFirmAssets.message)
            }
            dispatch({
                type: DELETE_FIRM_ASSETS,
                payload: ''
            })
        }
    }, [deleteFirmAssets])

    useEffect(() => {
        if (editFirmAssets) {
            if (editFirmAssets.status === 200) {
                toast.success(editFirmAssets.message)
                setOpenModal(false)
            } else {
                toast.error(editFirmAssets.message)
            }
            dispatch({
                type: EDIT_FIRM_ASSETS,
                payload: ''
            })
        }
    }, [editFirmAssets])

    useEffect(() => {
        if (addFirmAssets) {
            if (addFirmAssets.status === 200) {
                toast.success(addFirmAssets.message)
                setOpenModal(false)
            } else {
                toast.error(addFirmAssets.message)
            }
            dispatch({
                type: ADD_FIRM_ASSETS,
                payload: ''
            })
        }
    }, [addFirmAssets])


    const columns: GridColDef[] = [
        { field: 'index', headerName: 'No', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'firmDocumentName', headerName: 'Document', flex: 1,
            renderCell: ({ row }) => {
                return (
                    <>
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                window.open(row.firmDocument)
                            }}>{row.firmDocumentName}</span>
                    </>
                )
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            renderCell: ({ row }) => {
                return (
                    <>
                        <div className='table_actions' >
                            <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => editAssets(row._id)} />
                            <Delete color='error' fontSize="small" style={{ margin: "5px" }}
                                onClick={() => {
                                    setAssetsDataId(row._id)
                                    setOpenDeleteModal(true)
                                }}
                            />
                        </div>
                    </>
                )
            },
            // getActions: ({ id }) => {
            //     return [
            //         <GridActionsCellItem
            //             icon={
            //                 <div className='table_actions' >
            //                     <Edit fontSize="small" style={{ margin: "5px" }} onClick={(e) => editAssets(id)} />
            //                     <Delete color='error' fontSize="small" style={{ margin: "5px" }}
            //                         onClick={() => {
            //                             setAssetsDataId(id)
            //                             setOpenDeleteModal(true)
            //                         }}
            //                     />
            //                 </div>
            //                 // <div>
            //                 //     <IconButton
            //                 //         aria-label="more"
            //                 //         id="long-button"
            //                 //         aria-controls={open ? 'long-menu' : undefined}
            //                 //         aria-expanded={open ? 'true' : undefined}
            //                 //         aria-haspopup="true"
            //                 //         onClick={(e) => {
            //                 //             setAssetsDataId(id)
            //                 //             handleClick(e)
            //                 //         }}
            //                 //     >
            //                 //         <img src={Action} alt='' />
            //                 //     </IconButton>
            //                 //     <Menu
            //                 //         id="long-menu"
            //                 //         MenuListProps={{
            //                 //             'aria-labelledby': 'long-button',
            //                 //         }}
            //                 //         anchorEl={anchorEl}
            //                 //         open={open}
            //                 //         onClose={handleClose}
            //                 //         PaperProps={{
            //                 //             style: {
            //                 //                 maxHeight: '150px',
            //                 //                 width: '20ch',
            //                 //             },
            //                 //         }}
            //                 //     >
            //                 //         <MenuItem onClick={(e) => editAssets(id)}>
            //                 //             Edit
            //                 //         </MenuItem>
            //                 //         <MenuItem onClick={(e) => {
            //                 //             setOpenDeleteModal(true)
            //                 //             setAnchorEl(null);
            //                 //         }}>
            //                 //             Delete
            //                 //         </MenuItem>
            //                 //     </Menu>
            //                 // </div>
            //             }
            //             label="Edit"
            //             className="textPrimary"
            //             onClick={() => { }}
            //             color="inherit"
            //         />
            //     ];
            // },
        },
    ];

    return (
        <>
            <div className='card_footer'>
                <Button
                    variant="outlined"
                    buttonClass='contained_button_cancel'
                    onClick={() => { localStorage.removeItem("firmId"); navigate('/firm') }}
                    child='Cancel' />
                <Button
                    onClick={() => setOpenModal(true)}
                    variant="outlined"
                    buttonClass='contained_button_add'
                    child={'Add'} />
            </div>
            <Table rows={getByIdFirmAssets?.data?.map((item: any, i: number) => { return { ...item, id: item._id ?? i + 1, index: i + 1, firmDocumentName: item.firmDocument?.split("/firmDocument/").pop() } }) ?? []} columns={columns} />
            {
                openModal && <AssetsModal open={openModal} handleClose={() => closeModal()} setBankData={(e: any) => { }} assetsData={editAssetsData} />
            }
            {openDeleteModal && <DeleteConfirmation open={openDeleteModal} title='assets' handleClose={(data: any) => setOpenDeleteModal(false)} handleConfirm={() => handleDelete()} />}
        </>
    )
}

export default Assets