import React, { useEffect, useState } from 'react';
import Button from '../../components/elements/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { getAllFirmAction } from '../../redux/actions/firmAction';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFirmAction } from '../../redux/actions/firmAction';
import { toast } from 'react-toastify';
import DeleteConfirmation from '../../components/modals/DeleteConfirmation';
import { DELETE_FIRM } from '../../redux/type';
import recycle_bin from '../../assets/img/recycle-bin.png'
import NoDataElement from '../../components/elements/NoDataElement';

const options = [
    'Edit',
    'Delete',
];

const Index = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false);
    const [deleteFirmId, setDeleteFirmId] = useState<string>();
    const getFirmData = useSelector((state: any) => state.getAllFirm.getAllFirm.data)
    const deleteFirmData = useSelector((state: any) => state.deleteFirm.deleteFirm)

    useEffect(() => {
        if (deleteFirmData) {
            if (deleteFirmData?.status === 200) {
                toast.success(deleteFirmData?.message)
                setDeleteFirmId("")
                setOpenModal(false)
                dispatch({
                    type: DELETE_FIRM,
                    payload: null,
                });
            } else {
                toast.error(deleteFirmData?.message)
            }
        }
    }, [deleteFirmData])

    const handleDelete = () => {
        if (deleteFirmId) {
            dispatch(deleteFirmAction(deleteFirmId))
        }
    };

    const handleEdit = (cardId: string) => {
        if (cardId)
            navigate(`edit/${cardId}`, { state: { firmId: cardId } })
    };
    useEffect(() => {
        dispatch(getAllFirmAction())
    }, [])

    return (
        <div className=''>
            <div className='card_header'>
                <h1 className='card_header_title'>Firm</h1>
                <div className='card_footer'>
                    <Button
                        onClick={() => navigate('add')}
                        variant="outlined"
                        buttonClass='contained_button_add'
                        child='Add' />
                </div>
            </div>
            <Grid container spacing={2}>
                {
                    getFirmData?.length > 0 ?
                        getFirmData?.map((firmData: any) => (
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={firmData._id} style={{ position: 'relative' }} >
                                <div className="firm_card_action">
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                            setOpenModal(true)
                                            setDeleteFirmId(firmData._id)
                                        }
                                        }
                                    >
                                        {/* <img src={recycle_bin} alt='' width='20px' /> */}
                                        <Delete color='error' />
                                    </IconButton>
                                </div>
                                <div className='firm_card' onClick={() => handleEdit(firmData._id)}>
                                    <div className='firm_card_detail'>
                                        <h4 className='firm_card_label'> Name:</h4>
                                        <h4 className='firm_card_value'> {firmData.name} </h4>
                                    </div>
                                    <div className='firm_card_detail'>
                                        <h4 className='firm_card_label'> GST:</h4>
                                        <h4 className='firm_card_value'> {firmData.GST} </h4>
                                    </div>
                                    <div className='firm_card_detail'>
                                        <h4 className='firm_card_label'> PAN:</h4>
                                        <h4 className='firm_card_value'> {firmData.PAN} </h4>
                                    </div>
                                    {/* <div className='firm_card_detail'>
                                        <h4 className='firm_card_label'> LUT:</h4>
                                        <h4 className='firm_card_value'> {firmData.LUTNumber} </h4>
                                    </div> */}
                                </div>
                            </Grid>
                        ))
                        : <NoDataElement />
                }
            </Grid>
            {openModal && <DeleteConfirmation open={openModal} title='firm' handleClose={(data: any) => setOpenModal(false)} handleConfirm={() => handleDelete()} />}
        </div>
    )
}

export default Index